import styled from 'styled-components'

import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import { FlexStub } from './FlexStub'

import { useCustomPopover } from '../../hooks/common/useCustomPopover'
import COLORS from './Theme/colors'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { RowContainer } from './RowContainer'

const ContentPadding = 20

const ContentBorderWidth = 0.5

const Container = styled.div`
  position: relative;
  z-index: ${props => props.$zIndex};
`

const ContentHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0 10px;
`

const ContentContainer = styled.div`
  width: 900px;
  background: white;
  position: absolute;
  top: -${ContentPadding + ContentBorderWidth * 2}px;
  left: -${ContentPadding + ContentBorderWidth * 2}px;
  border-width: ${ContentBorderWidth}px;
  border-style: solid;
  flex-shrink: 0;
  z-index: 1000;
  border-color: ${COLORS.gray_2};
  border-radius: 6px;
  padding: ${ContentPadding}px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`

const TriggerContainer = styled.div`
  width: 100%;
`

export const _CustomPopover = ({ triggerElement, persistElement, style = {}, triggerStyle = {}, icon, content, hasSelected = false, onOpenChange = () => {} }, ref) => {
  const {
    open,
    triggerElemId,
    contentElemId,
    closePopover
  } = useCustomPopover()

  useImperativeHandle(ref, () => ({
    closePopover
  }))

  useEffect(() => {
    onOpenChange(open)
  }, [open])
  return (
    <Container $zIndex={open ? 999 : 1} style={triggerStyle} id={triggerElemId}>
      <RowContainer style={{ gap: 3 }}>
        {icon}
        {!hasSelected && (
          <TriggerContainer>
            {triggerElement}
          </TriggerContainer>
        )}
      </RowContainer>
      {open && (
        <ContentContainer id={contentElemId} $padding={ContentPadding} style={style}>
          <ContentHeaderContainer>
            <RowContainer style={{ gap: 4, color: `$${COLORS.black}` }}>
              {icon}
              {persistElement}
            </RowContainer>
            <Button icon={<CloseOutlined style={{ fontSize: '20px' }} />} type='text' onClick={closePopover} />
          </ContentHeaderContainer>
          <FlexStub $height={10} />
          {content}
        </ContentContainer>
      )}
    </Container>
  )
}

export const CustomPopover = forwardRef(_CustomPopover)
