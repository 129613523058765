import { useNavigate } from 'react-router-dom'

import { ViewMode } from '../common/ViewMode'
import MultipleSelect from '../common/MultipleSelect'
import { CustomPopover } from '../common/CustomPopover'
import { BecomesTagOnSelect } from '../common/BecomesTagOnSelect'
import { HeaderContainer as _HeaderContainer, HeaderGroup, Spacer } from '../common/HeaderGroup'
import { DateRangeFilter } from '../filters/DateRangeFilter'
import { SearchParamsFilter } from '../filters/SearchParamsFilter'
import { useCaseUserFilter } from '../../hooks/filter/useCaseUserFilter'
import { navigateToTimesheetForm } from '../../cacheAndNavigation/timesheetForm/navigation'

import styled from 'styled-components'
import COLORS from '../common/Theme/colors'
import { FiPlus } from 'react-icons/fi'
import { Button, Typography } from 'antd'
import { CloseCircleFilled, FilterOutlined } from '@ant-design/icons'
import _ from 'lodash'

const checkedProps = { color: COLORS.primaryColor, borderColor: COLORS.primaryColor }

const UserTagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid ${COLORS.primaryColor};
  border-radius: 32px;
  padding: 5px 10px;
  gap: 10px;
  white-space: nowrap;
`
const TextContainer = styled.div`
  white-space: nowrap;
`
const RowGapWrapper = styled.div`
  display: flex;
  gap: 5px;
`
const RowContainer = styled.div`
  display: flex;
  gap: 5px;
  white-space: nowrap;
`
const HeaderContainer = styled(_HeaderContainer)`
  /* justify-content: flex-end; */
`

const BaseText = ({ text }) => {
  return <Typography.Text style={{ cursor: 'pointer', fontWeight: '300' }}>{text}</Typography.Text>
}
const UserTag = ({ label, onRemove }) => {
  return (
    <UserTagContainer
      onClick={e => e.preventDefault()}
      style={{
        ...checkedProps
      }}
      shape='round'
    >
      {label}
      <CloseCircleFilled style={{ cursor: 'pointer', color: `${COLORS.gray_2}` }} onClick={onRemove} />
    </UserTagContainer>
  )
}
export const TimesheetHeader = ({ viewMode, onChangeViewMode, hiddenKeys, onSelectFilter, showCreateTimesheetButton, showViewMyCaseButton }) => {
  const {
    filters,
    meta,
    openStatusByKey,
    departments
  } = useCaseUserFilter(undefined, onSelectFilter)
  const navigate = useNavigate()
  return (
    <HeaderContainer>
      <HeaderGroup>
        <RowGapWrapper>
          <FilterOutlined />
          <TextContainer>필터</TextContainer>
        </RowGapWrapper>
        {viewMode === 'calendar'
          ? null
          : <DateRangeFilter
              value={meta.filter.datetimeRange}
              onCalendarChange={v => onSelectFilter('datetimeRange', v)}
            />}
      </HeaderGroup>
      <Spacer $flex={0.1} />
      <HeaderGroup>
        {// 사건번호에 따라 작성자 목록이 필터됨
          _.filter(filters, ({ key }) => key !== 'caseTypeIds').map(({ value, onOpenChange = () => {}, selectedOptions, icon, key, selectedItems = [], options = [], starredOptions = [], recentSearchListHidden, onChangeFilter, onChangeDepartmentFilter = () => {}, unit, viewMyCase = false, selectedDepartmentOptions = [], ...props }, index) => {
            const firstSelectedItem = _.find(selectedOptions, ({ value }) => value === _.get(selectedItems, [0]))
            const selectionDescription = `${firstSelectedItem?.name}${selectedItems?.length > 1 ? ` 외 ${selectedItems?.length - 1}${unit}` : ''}`
            const popoverWidth = value === '사건번호' ? 1100 : value === '작성자' ? 700 : 900

            if (key === 'viewMyCase') {
              if (showViewMyCaseButton) {
                return (
                  <BecomesTagOnSelect
                    key={value}
                    name={value}
                    tagged={viewMyCase}
                    onSelect={() => onChangeFilter(true)}
                    onClose={() => onChangeFilter(false)}
                  >
                    <BaseText text={value} />
                  </BecomesTagOnSelect>
                )
              } else return null
            }
            return (
              (key === 'categories' || key === 'caseStatus')
                ? (
                  <MultipleSelect
                    key={key}
                    style={{ minWidth: 120 }}
                    selectedValues={selectedItems}
                    options={options}
                    placeholder={value}
                    onSelectChange={v => onChangeFilter(v)}
                  />
                  )
                : (
                  <RowContainer key={`CustomPopover_${index}`} style={{ alignItems: 'center', marginRight: '5px' }}>
                    <CustomPopover
                      icon={icon}
                      onOpenChange={onOpenChange}
                      style={{ width: popoverWidth }}
                      persistElement={<BaseText text={value} />}
                      triggerElement={<BaseText text={value} />}
                      content={
                        <SearchParamsFilter
                          selectedItems={selectedItems}
                          options={options}
                          starredOptions={starredOptions}
                          persistElement={<BaseText text={value} />}
                          onChange={e => onChangeFilter(e)}
                          onChangeDepartmentFilter={e => onChangeDepartmentFilter(e)}
                          selectedOptions={selectedOptions}
                          selectedDepartmentOptions={selectedDepartmentOptions}
                          openStatusByKey={openStatusByKey}
                          departments={departments}
                          {...props}
                        />
                      }
                    />
                    {firstSelectedItem ? <UserTag label={selectionDescription} onRemove={() => onChangeFilter([])} /> : null}
                  </RowContainer>
                  )
            )
          })
        }
      </HeaderGroup>
      <Spacer />
      <HeaderGroup>
        {showCreateTimesheetButton
          ? (
            <Button
              type='primary'
              style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              onClick={() => navigateToTimesheetForm(navigate, 'creating', {})}
            >
              <FiPlus />
              타임시트 작성하기
            </Button>
            )
          : null}
        <ViewMode
          viewMode={viewMode}
          hiddenKeys={hiddenKeys}
          onChangeViewMode={onChangeViewMode}
        />
      </HeaderGroup>
    </HeaderContainer>
  )
}
