import { useNavigate } from 'react-router-dom'
import { navigateToStatWithParams } from '../../../cacheAndNavigation/stat/navigation'
import { useCaseList } from '../useCaseList'
import { useConfirmModal } from '../../manageCase/table/useConfirmModal'
import { useState } from 'react'
import { useButtonLoading } from '../../common/useButtonLoading'
import { message } from 'antd'
import { getTemplateProjectList, getTemplateUserList, serializeCaseList } from '../../../fastapi/fetchers'
import { downloadExcel } from '../../../util'
import _ from 'lodash'

export const useTableActions = ({ selectedRowKeys }) => {
  const navigate = useNavigate()
  const { showLoader, spinning, percent } = useButtonLoading()
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false)
  const [clicked, setClicked] = useState(true)
  const {
    excelDownloadVariables
  } = useCaseList({
    caseTableSelectedRowKeys: selectedRowKeys,
    skip: clicked
  })
  const {
    onOpenModal
  } = useConfirmModal()

  const onClickTableActions = (key, dataCategory) => {
    if (key === 'closed') {
      onOpenModal(selectedRowKeys, 'close')
    } else if (key === 'deleteCases') {
      onOpenModal(selectedRowKeys, 'delete')
    } else if (key === 'stat') {
      navigateToStatWithParams(navigate, selectedRowKeys)
    } else if (key === 'excelDownload') {
      setClicked(false)
      setFileDownloadLoading(true)
      showLoader()
      message.loading('파일 다운로드 중')
      serializeCaseList(excelDownloadVariables).then((res) => {
        downloadExcel(_.get(res, ['data']), '사건목록')
        setFileDownloadLoading(false)
        message.destroy()
      }).catch(e => {
        message.destroy()
        message.error('다운로드 실패')
        setFileDownloadLoading(false)
      })
    } else if (key === 'templateDownload') {
      setFileDownloadLoading(true)
      showLoader()
      if (dataCategory === 'case') {
        getTemplateProjectList().then(res => {
          downloadExcel(_.get(res, ['data']), '사건등록 템플릿')
          setFileDownloadLoading(false)
          message.destroy()
        }).catch(e => {
          setFileDownloadLoading(false)
          message.destroy()
        })
      } else if (dataCategory === 'employee') {
        setFileDownloadLoading(true)
        getTemplateUserList().then(res => {
          downloadExcel(_.get(res, ['data']), '직원등록 템플릿')
          setFileDownloadLoading(false)
          message.destroy()
        }).catch(e => {
          setFileDownloadLoading(false)
          message.destroy()
        })
      }
    }
  }
  return {
    onClickTableActions,
    fileDownloadLoading,
    spinning,
    percent
  }
}
