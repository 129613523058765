import { useEffect, useState } from 'react'

import { uuidv4 } from '../../util'

export const useCustomPopover = () => {
  const [meta, setMeta] = useState({
    triggerElemId: uuidv4(),
    contentElemId: uuidv4(),
    open: null
  })
  const openPopover = () => {
    setMeta(meta => ({
      ...meta,
      open: true
    }))
  }

  const closePopover = () => {
    setMeta(meta => ({
      ...meta,
      open: false
    }))
  }

  const popoverOpenHandler = e => {
    openPopover()
  }
  useEffect(() => {
    const triggerElem = document.getElementById(meta.triggerElemId)
    triggerElem && triggerElem.addEventListener('click', popoverOpenHandler)
    return () => {
      triggerElem && triggerElem.removeEventListener('click', popoverOpenHandler)
    }
  }, [])

  const popoverCloseHandler = e => {
    if (meta.open) {
      const triggerElem = document.getElementById(meta.triggerElemId)
      const contentElem = document.getElementById(meta.contentElemId)

      if ((triggerElem && triggerElem.contains(e.target)) || (contentElem && contentElem.contains(e.target))) return
      if (!document.contains(e.target)) return // for components which are destroyed when clicked
      closePopover()
    }
  }
  useEffect(() => {
    document.addEventListener('click', popoverCloseHandler)

    return () => {
      document.removeEventListener('click', popoverCloseHandler)
    }
  }, [meta.open])

  return {
    ...meta,
    openPopover,
    closePopover
  }
}
