import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { navigateToHome } from '../../cacheAndNavigation/common'

export const useInitialization = (loggedIn, userId, permissions) => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (loggedIn && userId) {
      if (location.pathname === '/') {
        navigateToHome(navigate, permissions, userId)
      }
    }
  }, [loggedIn, userId])
}
