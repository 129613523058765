import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useCategories } from '../../../hooks/apollo/useCategories'
import { useProjectTypeAddEdit } from '../../../hooks/settings/useProjectTypeAddEdit'
import { HeaderContentFooter } from '../../../components/common/HeaderContentFooter'
import { navigateToSettingDetail } from '../../../cacheAndNavigation/setting/navigation'
import ProjectTypeAdder from '../../../components/settings/projectType/ProjectTypeAdder'
import { HeaderContainer, HeaderTitle, Spacer } from '../../../components/common/HeaderGroup'

import styled from 'styled-components'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import _ from 'lodash'

const Container = styled.div`
  width: 100%;
`
const ManageProjectTypeContainer = () => {
  const navigate = useNavigate()
  const onNavToAddProjectType = () => {
    navigateToSettingDetail(navigate, 'creating', 'addProjectType', 'addProjectType')
  }
  const { categories, loading } = useCategories()
  const {
    onEditProjectType
  } = useProjectTypeAddEdit()

  return (
    <HeaderContentFooter
      header={(
        <HeaderContainer style={{ justifyContent: 'right' }}>
          <HeaderTitle title='사건분류 관리' />
          <Spacer $flex={5} />
          <Button style={{ display: 'flex', alignItems: 'center' }} type='primary' onClick={onNavToAddProjectType}>
            <PlusOutlined />
            사건분류 추가하기
          </Button>
        </HeaderContainer>
      )}
      content={(
        <Container>
          {loading
            ? null
            : <ProjectTypeAdder
                initDataSource={_.map(categories, ({ ...v }) => ({ key: v.id, ...v }))}
                onEditProjectType={onEditProjectType}
              />}
        </Container>
      )}
    />
  )
}

export default ManageProjectTypeContainer
