import { useMutation } from '@apollo/client'
import { addProjectSubType, editProjectSubType, removeProjectSubType } from '../../apollo/mutations'
import _ from 'lodash'
import { useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../config'

export const useProjectSubTypeAddEditDelete = (onFinish = () => {}, onError = () => {}) => {
  const { messageApi } = useOutletContext()
  const [handleAddProjectSubType] = useMutation(addProjectSubType)
  const [handleEditProjectSubType] = useMutation(editProjectSubType)
  const [handleRemoveProjectSubType] = useMutation(removeProjectSubType)
  const onAddProjectSubType = (name, order, projectTypeId) => {
    handleAddProjectSubType({
      variables: {
        type: {
          type: projectTypeId,
          name,
          order
        }
      }
    }).then(v => {
      if (!_.get(v, ['data', 'AddProjectSubType', 'ok'])) {
        messageApi.open(MESSAGES.projectSubTypeAddError)
        onError()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.projectSubTypeAddError)
      onError()
    })
  }

  const onEditProjectSubType = (editProjectSubTypeId, name, order) => {
    handleEditProjectSubType({
      variables: {
        editProjectSubTypeId,
        input: {
          name,
          order
        }
      }
    }).then(v => {
      if (!_.get(v, ['data', 'EditProjectSubType', 'ok'])) {
        messageApi.open(MESSAGES.projectSubTypeEditError)
        onError()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.projectSubTypeEditError)
      onError()
    })
  }

  const onRemoveProjectSubType = (removeProjectSubTypeId) => {
    handleRemoveProjectSubType({
      variables: {
        removeProjectSubTypeId
      }
    }).then(v => {
      if (!_.get(v, ['data', 'RemoveProjectSubType', 'ok'])) {
        messageApi.open(MESSAGES.projectSubTypeDeleteError)
        onError()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.projectSubTypeDeleteError)
      onError()
    })
  }

  return {
    onAddProjectSubType,
    onEditProjectSubType,
    onRemoveProjectSubType
  }
}
