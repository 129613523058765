import React from 'react'
import { Typography } from 'antd'

import styled from 'styled-components'

import { parseMinutesToHourMin } from '../../../../util'
import COLORS from '../../../common/Theme/colors'

const FileWidth = 65

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 10px;
  min-width: 120px;
  flex-direction: column;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
`
const EditTimeContainer = styled.div`
  background-color: ${COLORS.ornage_4};
  color: ${COLORS.orange};
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 5px;
`

const File = ({ title, minutes, onDoubleClickFile = () => {}, date }) => {
  return (
    <Container onDoubleClick={() => onDoubleClickFile()}>
      <img
        alt='example'
        width={FileWidth}
        src='/timesheet.png'
        autoFocus={false}
      />

      <ContentContainer>
        <Typography.Text
          style={{ maxWidth: '130px' }}
          ellipsis={{
            tooltip: () => <>{title}</>
          }}
        >
          {title}
        </Typography.Text>
        {date || '??'}
        <EditTimeContainer>
          {parseMinutesToHourMin(minutes)}
        </EditTimeContainer>
      </ContentContainer>

    </Container>
  )
}

export default File
