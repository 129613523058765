import { Typography } from 'antd'
import styled from 'styled-components'

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 16px 0px;
  gap: ${props => props.$gap || 0}px;
  border-width: 0.5px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 6px;
  flex: ${props => props.$flex || undefined};
`

export const StatGroupBox = ({ title, children, flex, minWidth, gap }) => {
  return (
    <BoxContainer $flex={flex} minWidth={minWidth} $gap={gap}>
      {title && (<Typography.Title level={5} style={{ margin: 0, paddingBottom: '30px' }}>{title}</Typography.Title>)}
      {children}
    </BoxContainer>
  )
}
