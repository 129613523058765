import { useNavigate, useOutletContext } from 'react-router-dom'
import { addUsers, removeUsers, unremoveUsers, updateUser } from '../../../apollo/mutations'
import { MESSAGES } from '../../../config'
import { useMutation } from '@apollo/client'
import _ from 'lodash'

export const useAddUpdateRemoveUsers = (onFinish = () => {}, onError = () => {}) => {
  const { messageApi } = useOutletContext()
  const navigate = useNavigate()
  const [addUsersFn] = useMutation(addUsers)
  const [updateUserFn] = useMutation(updateUser)
  const [removeUserFn] = useMutation(removeUsers)
  const [unremoveUserFn] = useMutation(unremoveUsers)
  const onAddUser = async (
    departments,
    email,
    group,
    name,
    rank,
    permissionsAdd = [],
    permissionsRemove = []
  ) => {
    return addUsersFn({
      variables: {
        users: [
          {
            departments,
            email,
            group,
            name,
            rank,
            permissionsAdd,
            permissionsRemove
          }
        ]
      }
    }).then(v => {
      if (_.get(v, ['data', 'AddUsers', 'ok'])) onFinish()
      else {
        messageApi.open(MESSAGES.employeeSheetFormError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.employeeSheetFormError)
      onError()
    })
  }
  const onUpdateUser = async (
    updateUserId,
    departments,
    email,
    group,
    name
  ) => {
    return updateUserFn({
      variables: {
        updateUserId,
        input: {
          departments,
          email,
          group,
          name
        }
      }
    }).then(v => {
      if (_.get(v, ['data', 'UpdateUser', 'ok'], false)) onFinish()
      else {
        messageApi.open(MESSAGES.updateEmployeeError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.updateEmployeeError)
      onError()
    })
  }
  const onRemoveUser = async (
    ids,
    forceDelete
  ) => {
    return removeUserFn({
      variables: {
        ids,
        forceDelete
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveUsers', 'ok'])) {
        navigate(-1)
        onFinish()
      } else {
        if (forceDelete) {
          messageApi.open(MESSAGES.employeeDeleteError)
        } else {
          messageApi.open(MESSAGES.employeeRemoveError)
        }
        onError()
      }
    }).catch(() => {
      if (forceDelete) {
        messageApi.open(MESSAGES.employeeDeleteError)
      } else {
        messageApi.open(MESSAGES.employeeRemoveError)
      }
      onError()
    })
  }

  const onUnremoveUsers = async (
    ids
  ) => {
    return unremoveUserFn({
      variables: {
        ids
      }
    }).then(v => {
      if (_.get(v, ['data', 'UnremoveUsers', 'ok'])) {
        navigate(-1)
        onFinish()
      } else {
        messageApi.open(MESSAGES.employeeUnremoveError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.employeeUnremoveError)
      onError()
    })
  }
  return {
    onAddUser,
    onUpdateUser,
    onRemoveUser,
    onUnremoveUsers
  }
}
