import React, { useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  },
  style: {
    width: '400px',
    marginRight: 84
  }
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16
  }
}

const LoginForm = ({ loggedIn, onLogin, onChangePassword, loading, authenticationStatus }) => {
  const [spinning, setSpinning] = useState(false)
  const [percent, setPercent] = useState(0)
  const showLoader = () => {
    if (authenticationStatus === '인증실패') {
      return
    }
    setSpinning(true)
    let count = -10

    const interval = setInterval(() => {
      count += 10
      setPercent(count)

      if (count > 100 && (loading === false)) {
        clearInterval(interval)
        setSpinning(false)
        setPercent(0)
      }
    }, 100)
  }
  if (loggedIn) {
    return (
      <Form
        {...layout}
        name='change_password'
        className='login-form'
        initialValues={{
          remember: true
        }}
        onFinish={onChangePassword}
      >
        <Form.Item
          label='새 비밀번호 입력'
          name='newPassword'
          rules={[
            {
              required: true,
              message: '비밀번호를 입력하세요!'
            }
          ]}
        >
          <Input
            type='password'
          />
        </Form.Item>
        <Form.Item
          label='새 비밀번호 확인'
          name='confirmPassword'
          rules={[
            {
              required: true,
              message: '비밀번호를 입력하세요!'
            }
          ]}
        >
          <Input
            type='password'
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button style={{ width: '100%' }} type='primary' htmlType='submit' className='login-form-button'>
            비밀번호 변경
          </Button>
        </Form.Item>
      </Form>
    )
  }
  return (
    <>
      <Form
        {...layout}
        name='normal_login'
        className='login-form'
        initialValues={{
          remember: true
        }}
        onFinish={onLogin}
      >
        <Form.Item
          label='아이디'
          name='username'
          rules={[
            {
              required: true,
              message: '아이디를 입력하세요!'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='비밀번호'
          name='password'
          rules={[
            {
              required: true,
              message: '비밀번호를 입력하세요!'
            }
          ]}
        >
          <Input type='password' />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button onClick={showLoader} style={{ width: '100%' }} type='primary' htmlType='submit' className='login-form-button'>
            로그인
          </Button>
        </Form.Item>
      </Form>
      <Spin spinning={spinning} percent={percent} fullscreen />
    </>
  )
}
export default LoginForm
