import { useParams, useNavigate } from 'react-router-dom'

import AlignedForm from '../../components/common/AlignedForm'
import CaseCategoryTag from '../../components/common/Tags/CaseCategoryTag'
import { RowContainer } from '../../components/common/RowContainer'
import { ConfirmModal } from '../../components/common/ConfirmModal'
import { CustomReadOnlyInput } from '../../components/common/CustomReadOnlyInput'
import { HeaderContentFooter } from '../../components/common/HeaderContentFooter'
import { HeaderContainer, HeaderTitle, Spacer } from '../../components/common/HeaderGroup'

import { useUserList } from '../../hooks/apollo/SettingPage/useUserList'
import { useConfirmModal } from '../../hooks/manageCase/table/useConfirmModal'
import { useAddUpdateRemoveUsers } from '../../hooks/apollo/mutations/useAddUpdateRemoveUsers'
import { navigateToSettingDetail } from '../../cacheAndNavigation/setting/navigation'
import { navigateToTimesheetDetail } from '../../cacheAndNavigation/timesheetDetail/navigation'

import styled from 'styled-components'
import { Tabs, Typography, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import COLORS from '../../components/common/Theme/colors'
import { MAX_CONTENT_WIDTH } from '../../config'
import { parseDateObj, parseMinutesToHourMin } from '../../util'
import _ from 'lodash'

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  > * {
    max-width: ${MAX_CONTENT_WIDTH}px;
  }
`
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
`
const CaseListContainer = styled.div`
  /* max-width: 800px; */
`
const ListTitleContainer = styled.div`
  display: flex;
  justify-content: left;
  gap: 10px;
  flex-direction: row;
  padding: 20px 20px;
  margin: 20px 0 10px;
  background-color: aliceblue;
  font-size: 15px;
  border-top: 1px solid ${COLORS.primaryColor50};
`
const SubListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 20px 15px 0px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${COLORS.primaryColor}
  }
`
const NoTimeSheetContainer = styled.div`
  padding-left: 40%;
  padding-top: 100px;
  color: ${COLORS.gray_3};
  white-space: nowrap;
`
const NoProjectContainer = styled.div`
  display: flex;
  justify-content:center;
  width: 600px;
  padding-top: 100px;
  color: ${COLORS.gray_3};
  white-space: nowrap;
`
const ColumnLine = styled.div`
  height: 400px;
  border: 0.1px solid ${COLORS.white};
`

export const EmployeeDetailContainer = () => {
  const navigate = useNavigate()
  const { employeeId } = useParams()
  const {
    loading,
    users
  } = useUserList(false, null, null, null, null, true, employeeId)
  const {
    onRemoveUser,
    onUnremoveUsers
  } = useAddUpdateRemoveUsers()
  const employee = _.find(users, { id: employeeId })
  const getProjectList = _.get(employee, ['projects'], [])
  const projectCount = _.get(employee, ['projectCount'], [])
  const {
    title: confirmModalTitle,
    subTitle: confirmModalSubtitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal,
    onConfirmModal
  } = useConfirmModal(
    null,
    null,
    null,
    null,
    null,
    null,
    onRemoveUser
  )

  const caseTableCommonProps = { wordWrap: 'break-word', wordBreak: 'break-word', fontSize: 14 }
  if (loading) return null
  const formatTime = (time) => {
    return (
      <Typography.Text ellipsis style={caseTableCommonProps}>
        {(time !== null) ? parseDateObj(time, 'YYYY년 MM월 DD일') : ''}
      </Typography.Text>
    )
  }
  const onClickTimesheet = (timesheetMeta) => {
    navigateToTimesheetDetail(navigate, timesheetMeta.id, timesheetMeta)
  }
  const parseEmployeeData = (data) => {
    return {
      ...data,
      id: data?.id,
      key: data?.id,
      ranks2: data.ranks,
      departments: _.map(data?.departments || [], 'name')?.join(', '),
      departments2: data.departments
    }
  }

  // 직원 삭제
  const onClickRemoveEmployee = (e) => {
    onOpenModal([employee?.id], 'removeUser', true)
  }
  // 직원 퇴사
  const onClickDeleteEmployee = (e) => {
    if (employee?.status === 'DELETED') {
      onUnremoveUsers(employee?.id)
    } else {
      onRemoveUser(employee?.id, false)
    }
  }

  const filterEmployeeTimesheet = (timesheetMeta) => {
    return (
      timesheetMeta.length !== 0
        ? (
            _.map(timesheetMeta, v => {
              return (
                <SubListContainer
                  key={v.id}
                  onClick={() => onClickTimesheet(v)}
                >
                  <div>{v.title}</div>
                  <div>{parseMinutesToHourMin(v.minutes)}</div>
                </SubListContainer>
              )
            })
          )
        : (
          <NoTimeSheetContainer>등록된 타임시트가 없습니다.</NoTimeSheetContainer>
          )
    )
  }

  const formItems = [
    {
      key: 'USER_NAME',
      label: '이름',
      component: <CustomReadOnlyInput value={employee?.name} />
    },
    {
      key: 'email',
      label: '이메일',
      component: <CustomReadOnlyInput value={employee?.email} />
    },
    {
      key: 'ranks',
      label: '직급',
      component: (
        <CustomReadOnlyInput
          value={(employee?.ranks[0]?.name) !== null
            ? (
              <RowContainer
                onClick={() => navigateToSettingDetail(navigate, 'editing', 'setting', 'assignRank', employee?.id, parseEmployeeData(employee))}
                style={{ gap: '10px' }}
              >
                {employee?.ranks[0]?.name}
                <EditOutlined style={{ color: `${COLORS.primaryColor}`, cursor: 'pointer' }} />
              </RowContainer>
              )
            : null}
        />
      )
    },
    {
      key: 'roles',
      label: '권한그룹',
      component: (
        <CustomReadOnlyInput
          value={(employee?.group?.name) !== null
            ? (
              <RowContainer
                onClick={() => navigateToSettingDetail(navigate, 'editing', 'setting', 'employeeDelegate', employee?.id, employee)}
                style={{ gap: '10px' }}
              >
                {employee?.group?.name}
                <EditOutlined style={{ color: `${COLORS.primaryColor}`, cursor: 'pointer' }} />
              </RowContainer>
              )
            : null}
        />
      )
    },
    {
      key: 'departments',
      label: '부서',
      component: (
        <CustomReadOnlyInput
          value={(employee?.departments[0]?.name) !== null
            ? (
              <RowContainer
                onClick={() => navigateToSettingDetail(navigate, 'editing', 'setting', 'assignDepartment', employee?.id, parseEmployeeData(employee))}
                style={{ gap: '10px' }}
              >
                {employee?.departments[0]?.name}
                <EditOutlined style={{ color: `${COLORS.primaryColor}`, cursor: 'pointer' }} />
              </RowContainer>
              )
            : null}
        />
      )
    },
    {
      key: 'status',
      label: '상태',
      component: <CustomReadOnlyInput value={employee?.status === 'NORMAL' ? '재직' : employee?.status === 'PASSWORD_RESET' ? '비밀번호 재설정 하기 전' : employee?.status === 'DELETED' ? '퇴사' : '삭제'} />
    },
    {
      key: 'totalMinutes',
      label: '총 시간',
      component: <CustomReadOnlyInput value={(employee?.totalMinutes !== null) ? parseMinutesToHourMin(employee?.totalMinutes) : null} />
    },
    {
      key: 'lastLoggedIn',
      label: '마지막 로그인',
      component: <CustomReadOnlyInput value={(employee?.lastLoggedIn !== null) ? formatTime(employee?.lastLoggedIn) : '로그인 정보 없음'} />
    },
    {
      key: 'passwordUpdated',
      label: '비밀번호 변경 날짜',
      component: (
        <CustomReadOnlyInput value={(employee?.passwordUpdated !== null)
          ? (
            <RowContainer style={{ gap: '10px' }}>
              {parseDateObj(employee?.passwordUpdated)}
              <Button
                danger
                size='small'
                onClick={() => navigateToSettingDetail(navigate, 'editing', 'setting', 'resetPassword', employee?.id, parseEmployeeData(employee))}
                style={{ fontSize: '12px' }}
              >
                비밀번호 재설정
              </Button>
            </RowContainer>
            )
          : null}
        />
      )
    }
  ]
  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        subTitle={confirmModalSubtitle}
        onOk={() => onConfirmModal()}
        onCancel={onCloseModal}
      />
      <HeaderContentFooter
        header={
          <HeaderContainer>
            <HeaderTitle title='직원 세부 정보' />
            <Spacer />
            {/* 직원 삭제 기능 구현: 퇴사 처리 취소 추가하기 */}
            <Button onClick={(e) => { onClickDeleteEmployee(e) }}> {employee?.status === 'DELETED' ? '퇴사처리 취소 ' : '퇴사처리'} </Button>
            <Button onClick={(e) => { onClickRemoveEmployee(e) }} danger> 직원 삭제 </Button>
          </HeaderContainer>
        }
        content={
          <ContentContainer>
            <FormContainer>
              <AlignedForm
                style={{ width: '100%', minWidth: 1000 }}
                items={formItems}
                initMeta={employee}
              />
              <CaseListContainer>
                <RowContainer>
                  <ListTitleContainer style={{ minWidth: '600px' }}>
                    <div>담당사건 목록:</div>
                    <div level={5}>{projectCount}</div>
                  </ListTitleContainer>
                  <ListTitleContainer style={{ minWidth: '400px', width: '100%' }}>
                    <div>타임시트 제목</div>
                  </ListTitleContainer>
                </RowContainer>
                {employee?.projects?.length === 0
                  ? (
                    <RowContainer>
                      <NoProjectContainer>등록된 사건이 없습니다.</NoProjectContainer>
                      <ColumnLine />
                      <NoProjectContainer>등록된 타임시트가 없습니다.</NoProjectContainer>
                    </RowContainer>
                    )
                  : (
                    <Tabs
                      defaultActiveKey='1'
                      tabPosition='left'
                      style={{
                        minHeight: 200,
                        maxHeight: 600,
                        height: '100%'
                      }}
                      tabBarStyle={{ width: '600px' }}
                      items={getProjectList.map((item, i) => {
                        const timesheetMeta = _.filter(item.timesheets, v => _.includes(employee?.id, _.get(v, ['createdBy', 'id'])))
                        return {
                          label: (
                            <RowContainer>
                              {item?.name}
                              <CaseCategoryTag category={item.category} />
                            </RowContainer>
                          ),
                          key: i,
                          id: item.id,
                          type: item.type?.name,
                          children: (
                            <div style={{ maxHeight: 600, minWidth: `${timesheetMeta.length === 0 ? '300px' : 'none'}`, overflowY: 'auto' }}>
                              {filterEmployeeTimesheet(timesheetMeta)}
                            </div>
                          )
                        }
                      })}
                    />
                    )}
              </CaseListContainer>
            </FormContainer>
          </ContentContainer>
        }
      />
    </>
  )
}
