import { getWindowHistoryMetaByKey } from '../common'

const getUserMeta = () => {
  return getWindowHistoryMetaByKey('userMeta')
}

const getDepartmentMeta = () => {
  return getWindowHistoryMetaByKey('departmentMeta')
}

const getEmployeeAuthorityStatus = () => {
  return getWindowHistoryMetaByKey('employeeAuthorityStatus', true)
}

const getDepartmentFormStatus = () => {
  return getWindowHistoryMetaByKey('departmentFormStatus', true)
}

const getUserDepartmentMeta = () => {
  return getWindowHistoryMetaByKey('userDepartmentMeta')
}

const getUserDepartmentFormStatus = () => {
  return getWindowHistoryMetaByKey('userDepartmentFormStatus', true)
}
const getUserRankFormStatus = () => {
  return getWindowHistoryMetaByKey('userRankFormStatus', true)
}
const getUserRankMeta = () => {
  return getWindowHistoryMetaByKey('userRankMeta')
}

const getProjectTypeMeta = () => {
  return getWindowHistoryMetaByKey('projectTypeMeta')
}
const getProjectTypeFormStatus = () => {
  return getWindowHistoryMetaByKey('projectTypeFormStatus', true)
}

export {
  getUserMeta,
  getDepartmentMeta,
  getEmployeeAuthorityStatus,
  getDepartmentFormStatus,
  getUserDepartmentMeta,
  getUserDepartmentFormStatus,
  getUserRankFormStatus,
  getUserRankMeta,
  getProjectTypeMeta,
  getProjectTypeFormStatus
}
