import { useMutation, useQuery } from '@apollo/client'
import { getPushNotifications } from '../../apollo/queries'
import _ from 'lodash'
import { parseDateObj } from '../../util'
import { useEffect } from 'react'
import { navigateToCaseDetail } from '../../cacheAndNavigation/caseDetail/navigation'
import { useNavigate } from 'react-router-dom'
import { markAllPushNotificationRead, markPushNotificationRead } from '../../apollo/mutations'
import { korNameByCategory } from '../../config'

const messageByType = (caseNumber, category) => ({
  PROJECT_ALLOCATED: `새로운 ${category} ${caseNumber}이(가) 사용자에게 배당되었습니다.`,
  PROJECT_DELEGATED: `새로운 ${category} ${caseNumber}이(가) 사용자에게 배당 위임되었습니다.`,
  PROJECT_CLOSED: `${category} ${caseNumber}이(가) 종결되었습니다.`,
  PROJECT_REOPENED: `${category} ${caseNumber}이(가) 종결취소되었습니다.`,
  PROJECT_RESTORED: `${category} ${caseNumber}이(가) 복구되었습니다.`,
  PROJECT_REMOVED: `사용자에게 할당되었던 ${category} ${caseNumber}이(가) 삭제되었습니다.`,
  PASSWORD_RESET: '비밀번호 리셋이 요청되었습니다.',
  PROJECT_CREATED: `새로운 ${category} ${caseNumber}이(가) 생성되었습니다.`
})

const getTimeMessage = (date1, date2) => {
  let timeMessage = ''
  const hoursDiff = Math.abs(date1 - date2) / 36e5
  if (hoursDiff < 24) {
    if (Math.floor(hoursDiff) === 0) {
      if (Math.floor(hoursDiff * 60) === 0) {
        timeMessage = `${Math.floor(hoursDiff * 60 * 60)}초 전`
      } else {
        timeMessage = `${Math.floor(hoursDiff * 60)}분 전`
      }
    } else {
      timeMessage = `${Math.floor(hoursDiff)}시간 전`
    }
  } else {
    timeMessage = parseDateObj(date2, 'M월 D일')
  }
  return timeMessage
}
export const usePushNotification = (closePushNotificationsPopover = () => {}) => {
  const navigate = useNavigate()
  const [markAllRead] = useMutation(markAllPushNotificationRead)
  const [markPushNotificationReadById] = useMutation(markPushNotificationRead)

  const { data, refetch } = useQuery(getPushNotifications, {
    fetchPolicy: 'no-cache'
  })
  const gqlNotifications = _.get(data, ['GetPushNotifications'], [])
  const date1 = new Date()
  useEffect(() => {
    const intervalId = setInterval(() => refetch(), 60000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const pushNotifications = _.map(gqlNotifications, v => {
    const message = _.get(
      messageByType(
        _.get(v, ['data', 'project', 'name']),
        _.get(korNameByCategory, _.get(v, ['data', 'project', 'category']))
      ),
      [_.get(v, ['type'])],
      '_ERROR_'
    )

    const date2 = new Date(_.get(v, ['created']))
    const timeMessage = getTimeMessage(date1, date2)
    const projectId = _.get(v, ['data', 'project', 'id'])
    return {
      text: message,
      onClick: () => {
        closePushNotificationsPopover()
        if (!_.get(v, ['read'])) {
          markPushNotificationReadById({
            variables: {
              id: _.get(v, ['id'])
            }
          }).then(v => {
            refetch()
            if (!_.get(v, ['data', 'MarkPushNotificationRead', 'ok'])) console.log('MarkPushNotificationRead Error')
          }).catch(e => {
            console.log('MarkPushNotificationRead Error')
          })
        }
        navigateToCaseDetail(navigate, { id: projectId, caseId: projectId })
      },
      date: timeMessage,
      type: _.get(v, ['type']),
      reviewed: !!_.get(v, ['read']),
      ...v
    }
  })

  const onMarkAllPushNotificationRead = () => {
    markAllRead().then(v => {
      refetch()
      if (!_.get(v, ['data', 'MarkAllPushNotificationRead', 'ok'])) console.log('MarkAllPushNotificationRead Error')
    }).catch(e => {
      console.log('MarkAllPushNotificationRead Error')
    })
  }

  return {
    pushNotifications,
    onMarkAllPushNotificationRead,
    unreadCount: _.filter(pushNotifications, v => !v.reviewed)?.length
  }
}
