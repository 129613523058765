import _ from 'lodash'

const navigateToCaseForm = (navigate, caseCategory = 'CASE', formStatus = 'creating', caseMeta = {}) => {
  const index = {
    CONSULT: 'createConsultation',
    CASE: 'createCase',
    ETC: 'createEtc'
  }
  navigate(`/manageCase/${_.get(index, [caseCategory])}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      caseFormStatus: formStatus,
      caseMeta: JSON.stringify(_.pick(caseMeta, ['caseNumber', 'caseTag', 'content', 'caseId', 'attachments']))
    }
  })
}

export {
  navigateToCaseForm
}
