import React from 'react'
import { HeaderContentFooter } from '../../components/common/HeaderContentFooter'
import { HeaderContainer, HeaderTitle } from '../../components/common/HeaderGroup'
import AddEmployeeForm from '../../components/form/AddEmployeeForm'
import { BlockModal } from '../../components/common/BlockModal'
import { useBlockModal } from '../../hooks/common/useBlockModal'
import { useSavingEmployeeForm } from '../../hooks/settings/users/useSavingEmployeeForm'
import { useDepartmentList } from '../../hooks/apollo/useDepartmentList'
import { useUserGroupList } from '../../hooks/apollo/useUserGroupList'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useRankList } from '../../hooks/apollo/useRankList'

const parseMetaToSelectOptions = v => ({ value: _.get(v, ['id']), label: _.get(v, ['name']) })

const AddEmployeeContainer = () => {
  const navigate = useNavigate()
  const {
    blockModalOpen,
    onFetchAndNav,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()

  const {
    departments
  } = useDepartmentList()
  const {
    userGroups
  } = useUserGroupList()
  const {
    ranks
  } = useRankList()
  const { onSave } = useSavingEmployeeForm(onFetchAndNav)

  const onCancel = () => navigate(-1)

  return (
    <>
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title='직원 등록' />
          </HeaderContainer>
        )}
        content={(
          <div style={{ display: 'flex', padding: '100px', justifyContent: 'center' }}>
            <AddEmployeeForm
              departments={_.map(departments, parseMetaToSelectOptions)}
              userGroups={_.map(userGroups, parseMetaToSelectOptions)}
              rank={_.map(ranks, parseMetaToSelectOptions)}
              onSave={onSave}
              onCancel={onCancel}
            />
          </div>
        )}
      />
      <BlockModal
        title='직원등록을 취소하시겠습니까?'
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}

export default AddEmployeeContainer
