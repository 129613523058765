import { Table, Typography } from 'antd'
import styled from 'styled-components'
import { departmentColumns } from '../../../config'

const Container = styled.div`
  display: flex;
  width: 240px;
  flex-direction: column;
  margin-right: 10px;
  padding: 0 10px 10px 10px;
  background-color: aliceblue;
`
const FilterSelectContainer = styled.div`
  width: 210px;
`

const FilterSelect = ({ departments, selectedEmployees = [], employees = [], selectedDepartmentIds = [], onChangeSelection, onChangeEmployeesSelection, scroll = {} }) => {
  const onSelectChange = (newSelectedRowKeys) => {
    onChangeSelection(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys: selectedDepartmentIds,
    onChange: onSelectChange
  }
  return (
    <FilterSelectContainer>
      <Table
        rowSelection={rowSelection}
        dataSource={departments}
        columns={departmentColumns}
        pagination={false}
        scroll={scroll}
      />
    </FilterSelectContainer>
  )
}
export const UserAllocationFilter = ({ // 필터 목록 출력
  scroll,
  departments,
  employees,
  onChangeEmployeesSelection,
  selectedDepartmentIds,
  selectedEmployees,
  onChangeSelection
}) => {
  return (
    <Container>
      <Typography.Title level={4} style={{ padding: '0 10px' }}>필터</Typography.Title>
      <FilterSelect
        scroll={scroll}
        departments={departments}
        employees={employees}
        selectedDepartmentIds={selectedDepartmentIds}
        selectedEmployees={selectedEmployees}
        onChangeSelection={onChangeSelection}
        onChangeEmployeesSelection={onChangeEmployeesSelection}
      />
    </Container>
  )
}
