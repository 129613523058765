import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import AlignedForm from '../../components/common/AlignedForm'
import { BlockModal } from '../../components/common/BlockModal'
import { Attatchments } from '../../components/common/Attatchments'
import { HeaderContentFooter } from '../../components/common/HeaderContentFooter'
import { FormContainerFooter } from '../../components/manageCase/FormContainerFooter'
import { HeaderContainer as _HeaderContainer, HeaderTitle, Spacer } from '../../components/common/HeaderGroup'

import { useBlockModal } from '../../hooks/common/useBlockModal'
import { useAddingCase } from '../../hooks/manageCase/case/useAddingCase'
import { useCreateCaseForm } from '../../hooks/manageCase/case/useCreateCaseForm'
import { useFormAttatchments } from '../../hooks/timesheetForm/useFormAttatchments'

import { getCaseMeta } from '../../cacheAndNavigation/caseDetail/cache'
import { getCaseFormStatus } from '../../cacheAndNavigation/caseForm/cache'

import styled from 'styled-components'
import COLORS from '../../components/common/Theme/colors'
import _ from 'lodash'

const ContentContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  min-width: 500px;
  max-width: 800px;
`
const HeaderContainer = styled(_HeaderContainer)`
  justify-content: flex-end;
`
const AttatchmentContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: ${COLORS.black_1};
  > * {
    max-width: 800px;
  }
`
const FooterHeight = 300

export const CreateCaseContainer = ({ type = 'case' }) => {
  const caseFormStatus = getCaseFormStatus()
  const prevCaseFormStatus = useRef(caseFormStatus)
  const {
    fetching,
    onFetchAndNav,
    blockModalOpen,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()
  useEffect(() => {
    if (!blockModalOpen) prevCaseFormStatus.current = caseFormStatus
  }, [blockModalOpen])
  const initMeta = getCaseMeta()
  const {
    files,
    fileIds,
    onDeleteFileByKey,
    dragDropProps,
    ...attachmentsProps
  } = useFormAttatchments(_.get(initMeta, ['attachments'], undefined) || [])

  const {
    formItems,
    caseNumber,
    caseTag,
    content,
    showAutoCreateTag,
    caseTags
  } = useCreateCaseForm(false, initMeta, type, caseFormStatus, false)

  const {
    onSave,
    onCancel
  } = useAddingCase(
    fileIds,
    caseNumber,
    caseTag,
    content,
    onFetchAndNav,
    type,
    caseFormStatus,
    _.get(initMeta, ['caseId']),
    _.get(initMeta, ['status']),
    showAutoCreateTag,
    caseTags
  )
  const status = getCaseFormStatus()
  const location = useLocation()
  const readOnly = _.split(location.pathname, ['/'])[1] === 'createCase'
  const typeToCreate = type === 'case' ? '사건' : type === 'consultation' ? '자문' : type === 'etc' ? '기타' : 'Error'
  return (
    <>
      <HeaderContentFooter
        header={
          <HeaderContainer>
            <HeaderTitle
              title={
                status === 'editing' ? `${typeToCreate} 수정` : `${typeToCreate} 생성`
              }
            />
            <Spacer />
            <FormContainerFooter fetching={fetching} onSave={onSave} onCancel={onCancel} isEditing={prevCaseFormStatus.current === 'editing' || prevCaseFormStatus.current === 'creating'} />
          </HeaderContainer>
        }
        content={
          <ContentContainer>
            <AlignedForm
              items={formItems}
            />
          </ContentContainer>
        }
        footer={(
          <AttatchmentContainer>
            <Attatchments
              onDeleteFileByKey={onDeleteFileByKey}
              dragDropProps={dragDropProps}
              readOnly={readOnly}
              onDownloadAll={() => {}}
              files={files}
              {...attachmentsProps}
            />
          </AttatchmentContainer>
        )}
        footerHeight={FooterHeight}
      />
      <BlockModal
        title={`${typeToCreate} ${prevCaseFormStatus.current === 'creating' ? '생성' : '수정'}을 취소하시겠습니까?`}
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}
