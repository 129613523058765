import { FiPlus } from 'react-icons/fi'
import styled from 'styled-components'
import COLORS from './common/Theme/colors'

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${COLORS.primaryColor};
  height: 20px;
  width: 20px;
  overflow: hidden;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 16px;
  line-height: 0;
  transition: 0.4s;
  right: 0;
  &:hover {
    width: ${props => props.$hoverWidth}px;
    height: 40px;
    /* 파란색 면적이 많이 퍼지게하기 위해 필요 */
    position: absolute;
    /* 왼쪽 파란색 커퍼 할 길이 */
    right: -10px;
    border-radius:6px;
  }

  &:hover .text{
    color: ${COLORS.white};
    visibility: visible;
    opacity: 1;
    transition-delay: 1s;
    transform: translateX(-20px);
    transition: 0.7s;
  }

  &:hover .beforeIcon{
    color: ${COLORS.white};
    visibility: visible;
    opacity: 1;
    transition-delay: 0.1s;
    transform: translateX(-30px);
    left: 10px;
  }

  &:hover .icon{
    visibility: hidden;
    opacity: 0;
    white-space: nowrap;
    transition: 0.2s;

  }
`
const Icon = styled.div`
  position: absolute;
  right: 6px;
  height: 14px;
  width: 14px;
  padding-left: 3px;
  transition: 0.3s;
  transition-delay: 0.4s;
`

const ButtonText = styled.div`
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
  transform: translate(-30px);
`

const BeforeIcon = styled.div`
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
  transition: 0.1s;
  transform: translate(-30px);
`

export const AddHoverButton = ({ onClick, hoverWidth = 210, text }) => {
  return (
    <Button
      $hoverWidth={hoverWidth}
      onClick={onClick}
    >
      <BeforeIcon className='beforeIcon'><FiPlus style={{ color: COLORS.white }} /></BeforeIcon>
      <ButtonText className='text'>{text}</ButtonText>
      <Icon className='icon'><FiPlus style={{ color: COLORS.white }} /></Icon>
    </Button>
  )
}
