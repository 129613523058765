import { useOutletContext } from 'react-router-dom'

import { CustomTable } from '../components/common/CustomTable'
import { ConfirmModal } from '../components/common/ConfirmModal'
import { HeaderContentFooter } from '../components/common/HeaderContentFooter'
import { ManageDeletedTableContainerHeader } from '../components/manageCase/table/ManageDeletedTableContainerHeader'
import { useManageCaseTableFilter } from '../hooks/manageCase/table/useManageCaseTableFilter'
import { useManageDeletedCaseTable } from '../hooks/manageCase/table/useManageDeletedCaseTable'
import { MAX_CONTENT_WIDTH } from '../config'

import styled from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  min-width: 500px;
  max-width: ${MAX_CONTENT_WIDTH}px;
`

export const TrashContainer = () => {
  const userListSkip = true
  const {
    filters,
    categories,
    closed
  } = useManageCaseTableFilter({
    userListSkip
  })
  const {
    total,
    columns,
    allowedToCreateCase,
    hiddenTableActionByKey,
    allocatedUsers,
    delegatedUsers,
    onCloseModal,
    onConfirmModal,
    confirmModalMeta,
    confirmModalTitle,
    ...props
  } = useManageDeletedCaseTable(categories, closed)

  const { permissions } = useOutletContext()
  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onCancel={onCloseModal}
        onOk={onConfirmModal}
      />
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <ManageDeletedTableContainerHeader
            filters={filters}
            showCreateButton={allowedToCreateCase}
          />
        )}
        content={(
          <Container>
            <CustomTable
              total={total}
              columns={columns}
              rowSelection={null}
              hiddenTableActionByKey={{
                stat: true,
                excelUpload: true,
                excelDownload: true,
                templateDownload: true,
                tossCase: true
              }}
              permissions={permissions}
              {...props}
            />
          </Container>
        )}
      />
    </>
  )
}
