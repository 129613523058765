import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Buffer } from 'buffer'
import { fastapiUrl } from './config'
import 'dayjs/locale/ko'

dayjs.extend(customParseFormat)
dayjs.locale('ko')

const parseMinutesToHourMin = (totalMinutes = 0, hourUnit = '시간', minUnit = '분', dayUnit = '일') => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return `${hours <= 0 ? '' : `${hours}${hourUnit} `}${minutes === 0 ? (hours === 0) ? `0${minUnit}` : '' : `${minutes}${minUnit} `}`
}
const getHoursMinsFromMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return {
    hours,
    minutes
  }
}
const convertMomentToMinutes = (_moment) => {
  return _moment.hour() * 60 + _moment.minute()
}

const parseDateObj = (date, format = 'YYYY-MM-DD') => {
  if (!date) return null
  return dayjs(date).format(format)
}

const convertToDayjs = (date) => {
  return dayjs(date)
}

function uuidv4 () {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

function addMonthToDate (year, month) {
  return new Date(year, month + 1, 1)
}

const getOffsetFromPageAndLimit = (page, limit) => {
  return (page - 1) * limit
}

const downloadExcel = (data, name = '목록') => {
  const link = document.createElement('a')
  link.setAttribute('download', `${name}.xlsx`)
  const buffer = new Buffer.from(data, 'base64')
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  const blobUrl = window.URL.createObjectURL(blob)
  link.href = blobUrl
  link.click()
  URL.revokeObjectURL(blobUrl)
}

function sleep (ms) {
  return new Promise((r) => setTimeout(r, ms))
}

const downloadFile = async ({ id, originalFilename }) => {
  const downloadUrl = fastapiUrl + `/attachments/${id}`
  const link = document.createElement('a')
  link.href = downloadUrl
  link.target = '_blank'
  link.setAttribute('download', '')
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

export {
  sleep,
  uuidv4,
  downloadExcel,
  parseDateObj,
  getHoursMinsFromMinutes,
  addMonthToDate,
  convertToDayjs,
  parseMinutesToHourMin,
  convertMomentToMinutes,
  getOffsetFromPageAndLimit,
  downloadFile
}
