import { useMutation } from '@apollo/client'
import { addDepartment, editDepartment } from '../../../apollo/mutations'
import { useOutletContext } from 'react-router-dom'
import _ from 'lodash'
import { MESSAGES } from '../../../config'

export const useAddUpdateDepartment = (onFinish = () => {}, onError = () => {}) => {
  const { messageApi } = useOutletContext()
  const [addDepartmentFn] = useMutation(addDepartment)
  const [updateDepartmentFn] = useMutation(editDepartment)

  const onAddDepartment = async (
    name
  ) => {
    return addDepartmentFn({
      variables: {
        input:
          {
            name
          }
      }
    }).then(v => {
      if (_.get(v, ['data', 'AddDepartment', 'ok'])) onFinish()
      else {
        messageApi.open(MESSAGES.departmentCreationError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.departmentCreationError)
      onError()
    })
  }

  const onUpdateDepartment = async (
    departmentId,
    leaders,
    name,
    order
  ) => {
    return updateDepartmentFn({
      variables: {
        editDepartmentId: departmentId,
        input: {
          leaders,
          name,
          order
        }
      }
    }).then(v => {
      if (_.get(v, ['data', 'EditDepartment', 'ok'])) onFinish()
      else {
        messageApi.open(MESSAGES.updateDepartmentError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.updateDepartmentError)
      onError()
    })
  }
  return {
    onAddDepartment,
    onUpdateDepartment
  }
}
