import React, { useEffect, useRef } from 'react'
import { HeaderContentFooter } from '../../../components/common/HeaderContentFooter'
import { HeaderContainer, HeaderTitle } from '../../../components/common/HeaderGroup'
import { BlockModal } from '../../../components/common/BlockModal'
import { useBlockModal } from '../../../hooks/common/useBlockModal'
import _ from 'lodash'
import ProjectTypeForm from '../../../components/form/ProjectTypeForm'
import { getProjectTypeFormStatus, getProjectTypeMeta } from '../../../cacheAndNavigation/setting/cache'
import { useNavigate, useParams } from 'react-router-dom'
import { useEditingProjectTypeForm } from '../../../hooks/settings/projectType/useEditingProjectTypeForm'

const EditProjectTypeContainer = () => {
  const navigate = useNavigate()
  const { projectTypeId } = useParams()
  const projectTypeFormStatus = getProjectTypeFormStatus()
  const prevProjectTypeFormStatus = useRef(projectTypeFormStatus)
  const {
    blockModalOpen,
    onFetchAndNav,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()
  useEffect(() => {
    if (!blockModalOpen) prevProjectTypeFormStatus.current = projectTypeFormStatus
  }, [blockModalOpen])
  const initMeta = getProjectTypeMeta()
  const { onEdit } = useEditingProjectTypeForm(onFetchAndNav, projectTypeId, _.get(initMeta, ['order']))

  return (
    <>
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title='사건분류 수정' />
          </HeaderContainer>
        )}
        content={(
          <div style={{ display: 'flex', padding: '100px', justifyContent: 'center' }}>
            <ProjectTypeForm
              initialValues={{
                id: _.get(initMeta, ['id']),
                color: _.get(initMeta, ['color']),
                name: _.get(initMeta, ['name']),
                order: _.get(initMeta, ['order'])
              }}
              onSave={onEdit}
              editing
              onCancel={() => navigate(-1)}
            />
          </div>
        )}
      />
      <BlockModal
        title='등록을 취소하시겠습니까?'
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}

export default EditProjectTypeContainer
