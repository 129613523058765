import _ from 'lodash'
import { useNavigate, useOutletContext } from 'react-router-dom'
import COLORS from '../../components/common/Theme/colors'
import { navigateToCaseForm } from '../../cacheAndNavigation/caseForm/navigation'

export const useCreateNavButtons = () => {
  const { permissions } = useOutletContext()
  const navigate = useNavigate()
  const linkButtons = [
    {
      key: 'case',
      title: '사건',
      subtitle: '',
      color: `${COLORS.pink_3}`,
      shadowColor: `${COLORS.red_1_25}`,
      onClickCreateLink: () => navigateToCaseForm(navigate, 'CASE')
    },
    {
      key: 'advice',
      title: '자문',
      subtitle: '',
      color: `${COLORS.blue_1}`,
      shadowColor: `${COLORS.blue_2}`,
      onClickCreateLink: () => navigateToCaseForm(navigate, 'CONSULT')
    },
    {
      key: 'extra',
      title: '기타',
      subtitle: '(고객서비스관리)',
      color: `${COLORS.orange_3}`,
      shadowColor: `${COLORS.orange_5}`,
      onClickCreateLink: () => navigateToCaseForm(navigate, 'ETC')
    }
  ].filter(({ key }) => {
    if (key === 'case') {
      return _.includes(permissions, 'CREATE_PROJECT')
    } else if (key === 'advice') {
      return _.includes(permissions, 'CREATE_CONSULTATION')
    } else if (key === 'extra') {
      return _.includes(permissions, 'CREATE_ETC')
    } else return true
  })

  return {
    linkButtons
  }
}
