const COLORS = {
  primaryColor10: 'rgba(68, 143, 255, 0.1)',
  primaryColor30: 'rgba(68, 143, 255, 0.3)',
  primaryColor50: 'rgba(68, 143, 255, 0.5)',
  primaryColor: 'rgb(68, 143, 255)',

  blue_1: 'rgb(177, 218, 255)',
  blue_1_20: 'rgba(177, 218, 255, 0.2)',
  blue_2: 'rgba(0, 90, 172, 0.25)',
  blue_3: 'rgb(16,34,106)',
  blue_4: '#05B1C8',
  blue_5: '#647DFF',
  blue_6: '#4cdbff',
  blue_7: '#6298b9',

  orange_1: 'rgb(255, 229, 191)',
  orange_1_30: 'rgba(255, 229, 191,0.3)',
  orange_1_50: 'rgba(255, 229, 191,0.5)',
  orange_2_20: 'rgba(255, 210, 169, 0.2)',
  orange_3: '#FFD2A9',
  ornage_4: 'rgba(255, 159, 71, 0.2)',
  orange_5: 'rgba(255, 122, 0, 0.25)',
  orange_6: '#FFB178',
  orange: 'rgb(255, 159, 71)',

  pink_1: '#FF8F8F',
  pink_2_10: 'rgba(250, 90, 141, 0.1)',
  pink: '#EC407A',
  pink_3: '#fcbaba',

  red_1_25: 'rgba(215, 0, 0, 0.25)',
  red_1_35: 'rgba(215, 0, 0, 0.35)',
  red: '#FF4444',

  purple_1: '#B478FF',
  purple: '#8C31FF',

  green: '#009329',

  white_1: '#F9FAFB',
  white_2: '#FAFAFA',
  white_3: 'rgb(250, 253, 255)',
  white: '#fff',

  gray_1: 'rgb(233,233,233)',
  gray_1_50: 'rgba(233,233,233,0.5)',
  gray_2: '#aaa',
  gray_3: '#858585',
  gray_4: '#d9d9d9',

  black_1: 'rgba( 98, 108, 131, 0.1)',
  black_2: '#4B4B4B',
  black_45: 'rgba(0, 0, 0, 0.45)',
  black_48: 'rgba(0, 0, 0, 0.48)',
  black_65: 'rgba(0, 0, 0, 0.65)',
  black_88: 'rgba(0, 0, 0, 0.88)',
  black: 'rgb(0, 0, 0)'
}

export default COLORS
