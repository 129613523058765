import { useEffect, useRef } from 'react'

import { useTimeGroupList } from '../../apollo/useTimeGroupList'
import { useCustomTable } from '../../common/useCustomTable'
import { useColumns } from './useColumns'
import { downloadExcel, getOffsetFromPageAndLimit, parseDateObj, parseMinutesToHourMin } from '../../../util'

import { useTimesheetList } from '../../apollo/StatisticPage/useTimesheetList'
import { korNameByCategory } from '../../../config'
import { serializeTimesheetList } from '../../../fastapi/fetchers'
import { message } from 'antd'
import _ from 'lodash'

export const useStatTable = (meta) => {
  const userColumnCell = {
    key: 'creator',
    dataIndex: 'creator',
    title: '작성자'
  }
  const caseNumberColumnCell = {
    key: 'caseNumber',
    dataIndex: 'caseNumber',
    title: '사건번호'
  }
  const categoryColumnCell = {
    key: 'category',
    dataIndex: 'category',
    title: '카테고리'
  }
  const { columns } = useColumns(
    userColumnCell,
    caseNumberColumnCell,
    categoryColumnCell,
    meta.group.time,
    meta.group.item
  )
  const props = useCustomTable()
  const start = _.get(meta.filter.datetimeRange, [0])
  const end = _.get(meta.filter.datetimeRange, [1])
  const { page, limit, sortKey, sortAscending, onChangePage } = props
  const {
    loading: totalLoading,
    timeGroups,
    totalCount,
    totalMinutes
    // variables
  } = useTimeGroupList(
    meta.group.item,
    meta.group.time,
    meta.filter.caseIds,
    meta.filter.userIds,
    meta.filter.caseTypeIds,
    start,
    end,
    getOffsetFromPageAndLimit(page, limit),
    limit,
    sortKey,
    sortAscending,
    meta.group.time === 'all',
    meta.filter.categories
  )
  const prevMeta = useRef(meta)
  useEffect(() => {
    if (_.isEqual(meta, prevMeta.current)) return
    prevMeta.current = meta
    onChangePage(1)
  }, [meta])
  const {
    variables: timesheetVariables,
    loading: totalTimesheetLoading,
    timesheets,
    totalCount: totalTimesheetCount,
    totalMinutes: totalTimesheetMinutes
  } = useTimesheetList({
    projectIds: meta.filter.caseIds,
    userIds: meta.filter.userIds,
    from: start,
    to: end,
    offset: getOffsetFromPageAndLimit(page, limit),
    limit,
    sortKey,
    sortAscending,
    skip: meta.group.time !== 'all',
    projectCategories: meta.filter.categories
  })
  const data = meta.group.time === 'all'
    ? _.map(timesheets, v => {
      return {
        ...v,
        minutes: parseMinutesToHourMin(_.get(v, ['minutes'])),
        creator: _.get(v, ['user', 'name']),
        caseNumber: _.get(v, ['case', 'caseNumber']),
        updated: parseDateObj(_.get(v, ['date'], null), 'YYYY년 M월 D일'),
        key: _.get(v, ['id'])
      }
    })
    : _.map(timeGroups, v => {
      const updated = _.get(v, ['primaryKey'], null) ? new Date(_.get(v, ['primaryKey'], null)) : null
      return {
        ...v,
        totalMinutes: parseMinutesToHourMin(_.get(v, ['totalMinutes'])),
        caseNumber: _.get(v, ['secondaryKey', 'name']),
        creator: _.get(v, ['secondaryKey', 'name']),
        category: _.get(korNameByCategory, _.get(v, ['secondaryKey', 'category'])),
        updated: meta.group.time === 'daily'
          ? parseDateObj(updated, 'YYYY년 M월 D일')
          : meta.group.time === 'weekly' && updated
            ? `${parseDateObj(updated, 'YYYY년 M월 D일')} ~ ${parseDateObj(new Date(updated).setDate(new Date(updated).getDate() + 6), 'YYYY년 M월 D일')}`
            : meta.group.time === 'monthly'
              ? parseDateObj(updated, 'YYYY년 M월')
              : parseDateObj(updated, 'YYYY년 M월 D일')
      }
    })
  const total = meta.group.time === 'all' ? totalTimesheetCount : totalCount
  const onClickTableActions = key => {
    if (key === 'excelDownload') {
      serializeTimesheetList(timesheetVariables).then(res => {
        downloadExcel(_.get(res, ['data']), '타임시트 통계')
        message.destroy()
      }).catch(e => {
        message.destroy()
        message.error('다운로드 실패')
      })
    }
  }
  return {
    loading: meta.group.time === 'all' ? totalTimesheetLoading : totalLoading,
    totalMinutes: meta.group.time === 'all' ? totalTimesheetMinutes : totalMinutes,
    // extraHeaderItem: <>총 <ItemNumber>{total}</ItemNumber>건</>,
    hiddenTableActionByKey: {
      stat: true,
      excelUpload: true,
      excelDownload: true,
      templateDownload: true
    },
    onClickTableActions,
    unit: '개',
    data,
    columns,
    total,
    ...props
  }
}
