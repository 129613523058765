import { useEffect } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import { downloadExcel } from '../../../util'
import { useCustomTable } from '../../common/useCustomTable'
import { useDeltaminutesFinder } from '../useDeltaminutesFinder'
import { useTimesheetList } from '../../apollo/useTimesheetList'
import { useCaseUserFilterSearchParams } from '../../filter/params/useCaseUserFilterSearchParams'

import { serializeTimesheetList } from '../../../fastapi/fetchers'
import { getTimesheetTableColumns } from './getTimesheetTableColumns'
import { parseClosedFromArrOfCaseStatusFilter } from '../../../apollo/parser'
import { navigateToCaseList } from '../../../cacheAndNavigation/timesheet/navigation'
import { navigateToTimesheetDetail } from '../../../cacheAndNavigation/timesheetDetail/navigation'
import { TimehseetTableTotalHourMin } from '../../../components/timesheet/CaseAndTimesheetTable/TimesheetTableTotalHourMin'
import { message } from 'antd'
import _ from 'lodash'

export const useTimesheetTable = () => {
  const navigate = useNavigate()
  const { permissions } = useOutletContext()
  const { caseStatus } = useParams()
  const {
    meta: {
      filter: {
        caseIds,
        userIds,
        datetimeRange,
        caseStatus: _caseStatus,
        categories,
        viewMyCase
      }
    },
    searchParams
  } = useCaseUserFilterSearchParams()
  const tableProps = useCustomTable()
  const {
    limit,
    page,
    sortAscending,
    sortKey,
    selectedRowKeys
  } = tableProps
  useEffect(() => {
    if (_.isEmpty(caseIds)) {
      navigateToCaseList(navigate, caseStatus, undefined, 'table', searchParams.toString(), true)
    }
  }, [_.isEmpty(caseIds)])

  // gql
  const from = _.get(datetimeRange, [0])
  const to = _.get(datetimeRange, [1])
  const closed = parseClosedFromArrOfCaseStatusFilter(_caseStatus)
  const { timesheets, loading, totalMinutes, totalCount, variables } = useTimesheetList({
    projectIds: caseIds,
    userIds,
    from,
    to,
    offset: (page - 1) * limit,
    limit,
    sortKey,
    sortAscending,
    skip: false,
    projectCategories: categories,
    closed,
    viewMyCase
  })
  const total = totalCount

  // 조정 시간
  const tableData = _.map(timesheets, ({ id, case: _case, user, ...v }) => {
    return {
      key: id,
      ...v,
      creator: user.name,
      caseId: _.get(_case, ['id']),
      caseNumber: _.get(_case, ['caseNumber'])
    }
  })
  const {
    cases
  } = useDeltaminutesFinder()
  // 조정 시간
  const totalDeltaMinutes = _.sumBy(_.flatMap(cases, c => _.filter(c.users, user => _.includes(userIds, user.id))), 'deltaMinutes')

  const onClickTableActions = (key) => {
    if (key === 'stat') {
      navigate(`/stat?${searchParams.toString()}`)
    } else if (key === 'excelDownload') {
      message.loading('파일 다운로드 중')
      serializeTimesheetList(variables).then((res) => {
        downloadExcel(_.get(res, ['data']), '타임시트 목록')
        message.destroy()
      }).catch(e => {
        message.destroy()
        message.error('다운로드 실패')
      })
    }
  }
  const onClickRow = timesheetMeta => {
    navigateToTimesheetDetail(navigate, timesheetMeta.key, timesheetMeta)
  }

  const timesheetTableColumns = getTimesheetTableColumns()
  const disabledTableActionByKey = {
    closed: _.isEmpty(selectedRowKeys)
  }
  const hiddenTableActionByKey = {
    excelUpload: true,
    templateDownload: true,
    tossCase: true
  }
  return {
    ...tableProps,
    total,
    loading,
    data: tableData,
    columns: timesheetTableColumns,
    extraHeaderItem: (
      <TimehseetTableTotalHourMin
        tooltipDisabled={!(_.includes(permissions, 'WRITE_TIMESHEET_TIMEDELTA') || _.includes(permissions, 'READ_TIMESHEET_TIMEDELTA'))}
        totalMinutes={totalMinutes}
        totalDeltaMinutes={totalDeltaMinutes}
      />
    ),
    onClickRow,
    onClickTableActions,
    disabledTableActionByKey,
    hiddenTableActionByKey
  }
}
