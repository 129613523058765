import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import { uuidv4 } from '../../util'
import _ from 'lodash'
import { Spin } from 'antd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => props.$height || 300}px;
  overflow: auto;
`
export const _VirtualList = ({ height, refetch = (offset, limit) => {}, loading, dataParser, renderItem }, ref) => {
  const [meta, setMeta] = useState({
    offset: 0,
    items: [],
    scrollId: uuidv4(),
    initialized: false,
    total: 0
  })
  const {
    offset,
    total,
    items,
    scrollId
  } = meta
  const refreshFunction = () => {
    refetch(0, 20).then(({ data }) => {
      setMeta(meta => ({
        ...meta,
        offset: 20,
        items: dataParser(data),
        initialized: true,
        total: _.get(data, ['GetProjectList', 'totalCount'], 0)
      }))
    })
  }
  useImperativeHandle(ref, () => ({
    refresh: () => refreshFunction()
  }))
  useEffect(() => {
    if (!loading && !meta.initialized) {
      refreshFunction()
    }
  }, [loading])

  const fetchMoreData = () => {
    refetch(offset, 20).then(({ data }) => {
      setMeta(meta => {
        return {
          ...meta,
          offset: meta.offset + 20,
          items: meta.items.concat(dataParser(data))
        }
      })
    })
  }

  if (!meta.initialized && loading) return <Spin />
  return (
    <Container id={scrollId} $height={height}>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        refreshFunction={refreshFunction}
        hasMore={offset < total}
        scrollableTarget={scrollId}
        loader={<Spin tip='Loading'> </Spin>}
      >
        {items.map((item, index) => (
          <div key={index}>{renderItem(item, index)}</div>
        ))}
      </InfiniteScroll>
    </Container>
  )
}
export const VirtualList = forwardRef(_VirtualList)
