import { useEffect, useRef, useState } from 'react'

import UserIcon from '../../components/common/UserIcon'
import COLORS from '../../components/common/Theme/colors'
import LeaveTag from '../../components/common/Tags/LeaveTag'
import ClosedTag from '../../components/common/Tags/ClosedTag'
import CaseCategoryTag from '../../components/common/Tags/CaseCategoryTag'

import { parseCaseList } from '../../apollo/parser'
import { useCategories } from '../apollo/useCategories'
import { useDepartmentList } from '../apollo/InProgressCasePage/useDepartmentList'
import { useCaseNumbersAndUsersList } from '../apollo/useCaseNumbersAndUsersList'
import { useCaseUserFilterSearchParams } from './params/useCaseUserFilterSearchParams'

import styled from 'styled-components'
import { Checkbox, Typography } from 'antd'
import { BorderlessTableOutlined, TagOutlined, UserOutlined, DeploymentUnitOutlined } from '@ant-design/icons'
import _ from 'lodash'

const RowContainer = styled.div`
  display: flex;
  align-items: center;
`
const Empty = styled.div`
  background-color: ${COLORS.blue_7};
  color: ${COLORS.white};
  font-size: 11px;
  border-radius: 6px;
  width:33px;
  margin-left: 5px;

`
export const useCaseUserFilter = (initParams, customOnFilterSelect) => {
  const caseVirtualListRef = useRef()
  const starredCaseVirtualListRef = useRef()
  const [caseSearchQuery, setCaseSearchQuery] = useState('')
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [caseTypeSearchQuery, setCaseTypeSearchQuery] = useState('')
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([])
  const [caseFilter, setCaseFilter] = useState(['current'])

  // 필터가 열려 렌더링 발생할때만 쿼리 호출할 수 있도록 스테이트 관리
  const [openStatusByKey, setOpenStatusByKey] = useState({})
  const {
    searchParams,
    meta,
    onChangeMeta,
    onResetFilterAndGroup,
    getUpdatedSearchParams
  } = useCaseUserFilterSearchParams(initParams)

  const closed = _.isEqual(caseFilter, ['closed']) ? true : _.isEqual(caseFilter, ['current']) ? false : null
  const {
    users,
    caseNumbers,
    starredCaseNumbers,
    selectedCaseNumbers,
    caseRefetch,
    starredCaseRefetch,
    starredCaseLoading,
    caseLoading,
    searchedUsers,
    usersListDataLoading,
    searchedUserListDataLoading
  } = useCaseNumbersAndUsersList(
    closed,
    meta.filter.caseIds,
    caseSearchQuery,
    userSearchQuery,
    !_.get(openStatusByKey, ['caseIds'], false), // 닫혀있으면 쿼리 호출 안하도록 수정
    _.isEmpty(_.get(meta, ['filter', 'userIds'], [])) ? !_.get(openStatusByKey, ['userIds'], false) : false // 작성자가 선택이 안되어있을 경우, 작성자 모달이 켜져 있는 경우 확인
  )

  const {
    categories
  } = useCategories(!_.get(openStatusByKey, ['caseTypeIds'], false), caseTypeSearchQuery)
  const {
    categories: allCategories
  } = useCategories(!_.get(openStatusByKey, ['caseTypeIds'], false))

  const onDepartmentListComplete = (departments) => {
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }
  // 진행사건 > 필터 > 작성자 > 부서
  const {
    departments
  } = useDepartmentList(null, onDepartmentListComplete, !_.get(openStatusByKey, ['userIds'], false)) // 부서의 경우 사건필터 사용자 필터 중 하나라도 열려있으면 호출
  // 무한 스크롤 캐시 리셋
  useEffect(() => {
    caseVirtualListRef.current?.refresh()
    starredCaseVirtualListRef.current?.refresh()
  }, [closed, caseSearchQuery])

  // 사건선택이 바뀌면 작성자 리셋
  const resetUserIdOnSelectCaseId = () => {
    return customOnFilterSelect
      ? customOnFilterSelect('userIds', [])
      : onChangeMeta('filter', 'userIds', [])
  }

  // 선택된 사건에 속한 직원들 목록
  const caseFilteredUsers = _.uniqBy(
    _.flatMap(
      selectedCaseNumbers,
      v => _.concat(_.get(v, ['users'], []), _.get(v, ['delegates'], []))
    ),
    'id'
  )
  // 사건번호 선택시 사건에 속해진 직원들의 부서
  const caseFilteredUsersDepartment = () => {
    const departments = _.flatMap(
      _.filter(
        caseFilteredUsers,
        user => user?.departments?.length > 0
      ),
      user => user.departments.map(id => id)
    )
    return _.uniq(departments)
  }

  const convertToOptions = ({ id, ...v }, type) => {
    const name = _.get(v, ['name'])
    const group = _.get(v, ['group', 'name'])
    return {
      name,
      label:
        type === 'caseIds'
          ? (
            <RowContainer>
              <Typography.Text style={{ width: 300 }} ellipsis={{ tooltip: <>{name}</> }}>{name}</Typography.Text>
              <CaseCategoryTag category={_.get(v, ['category2'])} />
              {v.status === 'CLOSED' ? <ClosedTag /> : <Empty />}
            </RowContainer>)
          : type === 'userIds'
            ? (
              <>
                <UserIcon group={group} name={name} filter />
                {_.get(v, ['status']) === 'DELETED' ? <LeaveTag /> : null}
              </>)
            : name,
      value: id
    }
  }
  const filters = [
    {
      key: 'caseIds',
      value: '사건번호',
      icon: <BorderlessTableOutlined style={{ color: `${COLORS.black_88}` }} />,
      options: _.map(caseNumbers, v => convertToOptions(v, 'caseIds')),
      starredOptions: _.map(starredCaseNumbers, v => convertToOptions(v, 'caseIds')),
      selectedOptions: _.map(selectedCaseNumbers, v => convertToOptions(v, 'caseIds')),
      unit: '건',
      starredCaseListHidden: false,
      virtualListRef: caseVirtualListRef,
      starredVirtualListRef: starredCaseVirtualListRef,
      refetch: (offset, limit) => {
        return caseRefetch({
          sortBy: {
            ascending: true,
            key: 'TIMESHEET_UPDATED'
          },
          offset,
          limit
        })
      },
      starredRefetch: (offset, limit) => {
        return starredCaseRefetch({
          sortBy: {
            ascending: true,
            key: 'TIMESHEET_UPDATED'
          },
          offset,
          limit
        })
      },
      dataParser: (data) => _.map(parseCaseList(_.get(data, ['GetProjectList', 'data'], [])), v => convertToOptions(v, 'caseIds')),
      loading: caseLoading,
      starredLoading: starredCaseLoading,
      isVirtualList: true,
      caseOpenClosefilterOptions: (
        <RowContainer>
          <Typography.Text strong style={{ marginRight: '10px' }}>사건 보기</Typography.Text>
          <Checkbox.Group
            onChange={e => {
              if (_.isEmpty(e)) return
              // onChangeCaseFilterClosedOpenedCheckbox()
              customOnFilterSelect ? customOnFilterSelect('caseIds', []) : onChangeMeta('filter', 'caseIds', [])
              setCaseFilter(e)
            }}
            value={caseFilter}
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
            options={[
              {
                value: 'current',
                label: '진행사건',
                key: '1'
              },
              {
                value: 'closed',
                label: '종결사건',
                key: '2'
              }
            ]}
          />
        </RowContainer>
      ),
      searchQuery: caseSearchQuery,
      onSearch: v => setCaseSearchQuery(v)
    },
    {
      key: 'userIds',
      value: '작성자',
      usersListDataLoading,
      searchedUserListDataLoading,
      icon: <UserOutlined style={{ color: `${COLORS.black_88}` }} />,
      unit: '명',
      options: _.map(
        _.filter(
          _.isEmpty(meta.filter.caseIds) ? userSearchQuery ? searchedUsers : users : caseFilteredUsers,
          user => {
            return _.isEmpty(meta.filter.caseIds) ? _.intersection(selectedDepartmentIds, _.map(_.get(user, ['departments'], []), 'id')).length > 0 : user
          }
        ),
        v => convertToOptions(v, 'userIds')
      ),
      selectedOptions: _.map(users, v => convertToOptions(v, 'userIds')),
      selectedDepartmentOptions: _.isEmpty(meta.filter.caseIds) ? selectedDepartmentIds : caseFilteredUsersDepartment(),
      // departmentOptions: _.map(departments, 'id'),
      searchQuery: userSearchQuery,
      hideSearch: !_.isEmpty(meta.filter.caseIds),
      onSearch: v => setUserSearchQuery(v),
      emptyText: '선택 가능한 사용자가 없습니다.'
    },
    {
      key: 'categories', // 프로젝트 종류
      hideSearch: true,
      value: '카테고리',
      options: [
        {
          name: '사건',
          label: '사건',
          value: 'CASE',
          color: `${COLORS.pink}`
        },
        {
          name: '자문',
          label: '자문',
          value: 'CONSULT',
          color: `${COLORS.primaryColor}`
        },
        {
          name: '기타',
          label: '기타',
          value: 'ETC',
          color: `${COLORS.orange}`
        }
      ],
      icon: <DeploymentUnitOutlined style={{ color: `${COLORS.black_88}` }} />
    },
    {
      key: 'caseStatus',
      value: '진행상태',
      options: [
        {
          value: 'OPEN',
          label: '진행',
          key: '1'
        },
        {
          value: 'CLOSED',
          label: '종결',
          key: '2'
        }
      ]
    },
    {
      key: 'caseTypeIds', // 사건종류 목록
      value: '사건분류',
      unit: '개',
      searchQuery: caseTypeSearchQuery,
      onSearch: v => setCaseTypeSearchQuery(v),
      options: _.map(categories, v => convertToOptions(v, 'caseTypeIds')),
      selectedOptions: caseTypeSearchQuery ? _.map(allCategories, v => convertToOptions(v, 'caseTypeIds')) : null,
      icon: <TagOutlined style={{ color: `${COLORS.black_88}` }} />
    },
    {
      key: 'viewMyCase',
      value: '내 사건만 보기',
      viewMyCase: _.get(meta, ['filter', 'viewMyCase'])
    }
  ].map(v => ({
    ...v,
    selectedItems: v.key === 'viewMyCase' ? [] : _.get(meta, ['filter', v.key], []),
    selectedOptions: _.isEmpty(v.selectedOptions) ? v.options : v.selectedOptions,
    onChangeFilter: (value) => {
      customOnFilterSelect ? customOnFilterSelect(v.key, value) : onChangeMeta('filter', v.key, value)
      if (v.key === 'caseIds') { // 사건선택이 바뀌면 작성자 리셋
        resetUserIdOnSelectCaseId()
      }
    },
    onChangeDepartmentFilter: (value) => { // 작성자 > 부서 목록 변경시 업데이트
      setSelectedDepartmentIds(value)
    },
    onOpenChange: open => setOpenStatusByKey(openStatusByKey => ({ ...openStatusByKey, [v.key]: open }))
  }
  ))
  const closedTag = _.includes(_.get(meta, ['filter', 'caseStatus'], []), 'OPEN')
    ? _.includes(_.get(meta, ['filter', 'caseStatus'], []), 'CLOSED')
      ? null
      : false
    : _.includes(_.get(meta, ['filter', 'caseStatus'], []), 'CLOSED')
      ? true
      : null
  return {
    meta,
    filters,
    caseNumbers,
    users,
    closedTag,
    searchParams,
    onChangeMeta,
    convertToOptions,
    onResetFilterAndGroup,
    getUpdatedSearchParams,
    openStatusByKey,
    departments
  }
}
