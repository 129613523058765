import React, { useRef, useState } from 'react'

import styled from 'styled-components'
import { MenuOutlined, PlusOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import { Button, Popconfirm, Table, Typography } from 'antd'

import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import COLORS from '../../common/Theme/colors'
import _ from 'lodash'
import { useOutletContext, useParams } from 'react-router-dom'
import { MESSAGES } from '../../../config'
import { ProjectSubTypeEditableCell } from './ProjectSubTypeEditableCell'

const MenuColumnWidth = 80

const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  border-top: 1px solid ${COLORS.primaryColor50};
`
const IconAndTextButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`
const ItemCountWrapper = styled.div`
  display: flex;
  gap: 5px;
`
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  background-color: aliceblue;
`
const BlueText = styled.div`
  color: ${COLORS.primaryColor};
  font-weight: bold;
`
const TableComponent = styled(Table)`
  & colpsan > col:nth-child(2),
  & thead > tr > th:nth-child(2),
  & tbody > tr > td:nth-child(2) {
    min-width: 100px;
    max-width: 500px;
    width: 100%;
  }
`

const ProjectSubTypeAdder = ({ initDataSource = [], onAddProjectSubType, onEditProjectSubType, onRemoveProjectSubType }) => {
  const { projectTypeId } = useParams()
  const [editingKey, setEditingKey] = useState('')
  const [addingNew, setAddingNew] = useState(false) // 새로운 항목 추가인지 확인
  const currentProjectSubTypeList = initDataSource.filter((item) => item.type.id === projectTypeId)
  const [dataSource, setDataSource] = useState(currentProjectSubTypeList)
  const isEditing = (record) => record.key === editingKey
  const textRef = useRef()
  const { messageApi } = useOutletContext()
  const columns = [
    {
      key: 'sort',
      width: MenuColumnWidth,
      editable: false
    },
    {
      title: '소분류',
      dataIndex: 'name',
      editable: true
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 100,
      render: (_, record) => {
        const editable = isEditing(record)
        return editable
          ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8
                }}
              >
                저장
              </Typography.Link>
              <Typography.Link onClick={() => cancel(record)}>
                취소
              </Typography.Link>
            </span>
            )
          : (
            <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}>
              <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                수정
              </Typography.Link>
              <Popconfirm
                disabled={editingKey !== ''}
                title='정말 삭제하시겠습니까?'
                onConfirm={() => handleDelete(record.key)}
                okText='네'
                cancelText='아니요'
                style={{ cursor: 'pointer' }}
              >
                삭제
              </Popconfirm>
            </div>)
      }
    }
  ]

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id)
        const overIndex = previous.findIndex((i) => i.key === over?.id)
        return _.map(arrayMove(previous, activeIndex, overIndex), (record, index) => {
          const order = _.get(record, ['order'])
          const name = _.get(record, ['name'])
          const key = _.get(record, ['key'])
          if (index !== order) onEditProjectSubType(key, name, index)
          return {
            ...record,
            order: index
          }
        })
      })
    }
  }

  const edit = (record) => {
    setEditingKey(record.key)
  }

  const cancel = (record) => {
    if (addingNew === false) {
      setEditingKey('')
    } else {
      const newData = dataSource.filter((item) => item.key !== record.key)
      setDataSource(newData)
      setEditingKey('')
    }
  }
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key)
    setDataSource(newData)
    onRemoveProjectSubType(key)
  }

  const save = async (key) => {
    try {
      const newData = [...dataSource]
      const initKeys = _.map(initDataSource, 'key')
      const index = newData.findIndex((item) => key === item.key)
      const name = textRef?.current?.getName()
      const addingRow = {
        name
      }
      if (name.length === 0) {
        messageApi.open(MESSAGES.projectSubTypeTitleAddMissingNameWarning)
      } else {
        const isEditingItem = _.includes(initKeys, key)
        const initDataLength = initDataSource.length + 1
        if (isEditingItem) onEditProjectSubType(key, name, index)
        else onAddProjectSubType(name, initDataLength, projectTypeId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...addingRow })
        setDataSource(newData)
        setEditingKey('')
        setAddingNew(false)
      }
    } catch (errInfo) {
    }
  }
  const Row = ({
    row,
    editing,
    dataIndex,
    title,
    inputtype,
    record,
    index,
    currentRowValues,
    handleInputChange,
    children,
    ...props
  }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging
    } = useSortable({
      id: props['data-row-key']
    })
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999
          }
        : {})
    }
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === 'sort') {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: 'none',
                    cursor: 'move'
                  }}
                  {...listeners}
                />
              )
            })
          }
          return child
        })}
      </tr>
    )
  }
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputtype: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ref: isEditing(record) ? col.dataIndex !== 'color' ? textRef : null : null
      })
    }
  })

  const handleAdd = () => {
    const newData = {
      key: dataSource.length + 1,
      name: ''
    }
    edit(newData)
    setDataSource([...dataSource, newData])
    setAddingNew(true)
  }

  return (
    <Container>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <RowContainer>
            <IconAndTextButton
              onClick={handleAdd}
            >
              <PlusOutlined style={{ color: `${COLORS.red}` }} />
              <div>소분류 추가</div>
            </IconAndTextButton>
            <ItemCountWrapper>
              총
              <BlueText>{dataSource.length}</BlueText>
              개
            </ItemCountWrapper>
          </RowContainer>
          <TableComponent
            rowKey='key'
            columns={mergedColumns}
            dataSource={dataSource}
            components={{
              body: {
                row: Row,
                cell: ProjectSubTypeEditableCell
              }
            }}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </Container>
  )
}

export default ProjectSubTypeAdder
