import React from 'react'
import styled from 'styled-components'
import COLORS from '../Theme/colors'

const CaseCategoryTagContainer = styled.div`
  background-color: ${props => props.$category === '사건' ? `${COLORS.pink_2_10}` : props.$category === '자문' ? `${COLORS.blue_1_20}` : `${COLORS.orange_2_20}`};
  color: ${props => props.$category === '사건' ? `${COLORS.pink}` : props.$category === '자문' ? `${COLORS.primaryColor}` : `${COLORS.orange}`};
  font-size: 11px;
  border-radius: 6px;
  padding: 2px 7px;
  margin-left: 5px;
  cursor: pointer;
`

const CaseCategoryTag = ({ category }) => {
  const check = category === 'CASE' ? '사건' : category === 'CONSULT' ? '자문' : '기타'
  return (
    <CaseCategoryTagContainer $category={check}>{check}</CaseCategoryTagContainer>
  )
}

export default CaseCategoryTag
