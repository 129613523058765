import _ from 'lodash'
import { navigateToCaseList } from './timesheet/navigation'
import { getUpdatedSearchParams } from '../hooks/filter/params/useCaseUserFilterSearchParams'

const getWindowHistoryMetaByKey = (key, isNonObject) => {
  if (isNonObject) {
    return _.get(window, ['history', 'state', 'usr', key], '')
  }
  return JSON.parse(_.get(window, ['history', 'state', 'usr', key], '{}'))
}

const navigateToHome = (navigate, permissions = [], userId) => {
  if (_.includes(permissions, 'READ_ALL_TIMESHEET') || _.includes(permissions, 'READ_SAME_DEPARTMENT_TIMESHEET')) {
    const sp = new URLSearchParams({})
    const searchStringWithUserId = getUpdatedSearchParams('filter', 'userIds', [userId], sp).toString()
    navigateToCaseList(navigate, 'all', undefined, 'calendar', searchStringWithUserId)
  } else {
    if (_.includes(permissions, 'CREATE_PROJECT') || _.includes(permissions, 'CREATE_CONSULTATION') || _.includes(permissions, 'CREATE_ETC')) {
      navigate('/manageCase/table')
      console.log('called2')
    } else {
      console.log('called3', permissions)
    }
  }
}

export {
  navigateToHome,
  getWindowHistoryMetaByKey
}
