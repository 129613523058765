import { UserOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'
import COLORS from './Theme/colors'

const UserTagWrapper = styled.div`
  position: relative;
  top: -12px;
  left: ${(props) => props.$filter ? '0px' : '5.3px'};
  font-size: 11px;
`
const UserTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:8px;
`

const UserIcon = ({ group, name, filter }) => {
  return (
    <UserTagContainer>
      <div style={{ height: '24px' }}>
        <UserOutlined style={{ color: `${group === '대표변호사' ? `${COLORS.blue_5}` : group === '파트너변호사' ? `${COLORS.orange_6}` : group === '부사장' ? `${COLORS.purple_1}` : group === '일반변호사' ? `${COLORS.black}` : `${COLORS.gray_4}`}`, fontSize: '20px' }} />
        <UserTagWrapper $filter={filter}>{group === '대표변호사' ? <div style={{ color: `${COLORS.blue_5}` }}>대</div> : group === '파트너변호사' ? <div style={{ color: `${COLORS.orange_6}` }}>파</div> : group === '부사장' ? <div style={{ color: `${COLORS.purple_1}` }}>장</div> : null}</UserTagWrapper>
      </div>
      <div>{name}</div>
    </UserTagContainer>
  )
}

export default UserIcon
