import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  box-shadow: 0px 2px 3px #DFDFDF86;
  margin-bottom: 4px;
  justify-content: center;
  padding: 0 15px;
  text-wrap: nowrap;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* width: 100%; */
  flex: 1;
  height: 100%;
  padding: 40px 15px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  flex-shrink: 0;
  min-height: 150px;
  max-height: ${props => props.$height}px;
`

export const HeaderContentFooter = ({
  id,
  header,
  content,
  footer,
  width = '100%',
  headerHeight = 55,
  totalHeight = '100vh',
  footerHeight = 0
}) => {
  return (
    <Container>
      <Header>
        {header}
      </Header>
      <ContentContainer {...id ? { id } : {}}>
        {content}
      </ContentContainer>
      {footer && (
        <Footer $height={footerHeight}>
          {footer}
        </Footer>
      )}
    </Container>
  )
}
