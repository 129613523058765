import _ from 'lodash'
import { useAddUpdateRemoveUsers } from '../../apollo/mutations/useAddUpdateRemoveUsers'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../config'
export const useSavingEmployeeForm = (onFetchAndNav) => {
  const navigate = useNavigate()
  const { messageApi } = useOutletContext()
  const {
    onAddUser
  } = useAddUpdateRemoveUsers(() => navigate(-1))
  const onSave = (v) => {
    onFetchAndNav(
      () => onAddUser(
        _.get(v, ['departments']),
        _.get(v, ['email']),
        _.get(v, ['group']),
        _.get(v, ['name']),
        _.get(v, ['rank'])
      ),
      (v) => {

      },
      () => {
        messageApi.open(MESSAGES.employeeSheetFormError)
      }
    )
  }

  return {
    onSave
  }
}
