import { useState } from 'react'
import { navigateToTimesheetEntry } from '../../cacheAndNavigation/timesheetDetail/navigation'
import { useNavigate } from 'react-router-dom'

export const useTimeEntries = (timesheetId) => {
  const navigate = useNavigate()
  const [showTimesheetHistory, setShowTimesheetHistory] = useState(null)

  return {
    open: showTimesheetHistory,
    openTimeEntries: () => setShowTimesheetHistory(true),
    closeTimeEntries: () => setShowTimesheetHistory(false),
    onSelectTimeEntry: (id) => {
      navigateToTimesheetEntry(navigate, timesheetId, id)
    }
  }
}
