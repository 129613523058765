import React from 'react'

import ClosedTag from '../../../../components/common/Tags/ClosedTag'
import CaseCategoryTag from '../../../../components/common/Tags/CaseCategoryTag'
import { StarIcon } from '../../../../components/common/StarIcon'
import { AddHoverButton } from '../../../../components/AddHoverButton'
import { starIconWidth, categoryWidth, updatedWidth, totalMinutesWidth, minutesDifferenceWidth, minutesAfterEditWidth } from './columnWidths'

import styled from 'styled-components'
import COLORS from '../../../../components/common/Theme/colors'
import { Button, Popover, Typography } from 'antd'
import { DownOutlined, FolderOutlined, RightOutlined } from '@ant-design/icons'

import { parseDateObj, parseMinutesToHourMin } from '../../../../util'
import _ from 'lodash'

const HoverContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  right: 0px;
  align-items: center;
`
const StyledButton = styled(Button)`
    width: 1px;
    border-radius: 30px;
    background: ${COLORS.gray_1_50};
    padding: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const IconAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const TagContainer = styled.div`
  display: flex;
  gap: 4px;
`

const caseTableCommonProps = { wordWrap: 'break-word', wordBreak: 'break-word', fontSize: 14 }
const formatDate = (data) => {
  return data ? parseDateObj(data, 'YYYY년 M월 D일') : ''
}
// 사건 테이블
const getCaseTableColumns = ({
  onAddTimesheet,
  hoveredKey,
  rowExpanded,
  isPartner,
  isDatetimerangeFilter,
  caseNumberWidth,
  onStarChange,
  onClickCaseNumber,
  expandedKeys,
  permissions
}) => [
  {
    title: null,
    dataIndex: 'starred',
    key: 'starred',
    width: starIconWidth,
    render: (_starred, record) => {
      return (
        <div style={{ paddingTop: '8px', paddingLeft: '8px' }}>
          <StarIcon style={caseTableCommonProps} checked={_starred} onCheckStar={v => onStarChange(v, record.key)} />
        </div>
      )
    }
  },
  {
    title: '사건번호',
    dataIndex: 'caseNumber',
    key: 'CASE_NAME',
    className: 'caseTable-caseNumber',
    width: caseNumberWidth,
    // sort: true,
    render: (caseNumber, record) => {
      return (
        <Typography.Text ellipsis style={{ width: '100%', padding: '0px 20px 0px 10px', display: 'flex', justifyContent: 'space-between', gap: 10, cursor: 'pointer', ...caseTableCommonProps }}>
          <IconAndTextContainer>
            {_.includes(expandedKeys, record.key) ? <DownOutlined /> : <RightOutlined />}
            <FolderOutlined style={{ color: `${COLORS.black_65}` }} />
            {caseNumber}
            <TagContainer>
              <CaseCategoryTag category={record.category2} />
              {record.status === 'CLOSED' ? <ClosedTag /> : null}
            </TagContainer>
          </IconAndTextContainer>
          <Popover
            placement='right'
            content={
              <div>
                <p><Typography.Text strong>사건번호:</Typography.Text> {record.caseNumber}</p>
                <p><Typography.Text strong>사건분류: </Typography.Text>{record.category}</p>
                <p><Typography.Text strong>생성자: </Typography.Text>{record.createdBy.name}</p>
                <p><Typography.Text strong>생성일: </Typography.Text>{formatDate(record.created)}</p>
                <p><Typography.Text strong>위임 인원: </Typography.Text>{record.delegates.length}</p>
                <p><Typography.Text strong>배당 인원: </Typography.Text>{record.users.length}</p>
              </div>
              }
          >
            <StyledButton onClick={() => onClickCaseNumber(record)}>i</StyledButton>
          </Popover>
        </Typography.Text>
      )
    }
  },
  {
    title: '분류',
    dataIndex: 'category',
    key: 'category',
    width: categoryWidth,
    render: (record) => {
      return (
        <div style={{ paddingLeft: '10px' }}>
          {record}
        </div>
      )
    }
  },
  {
    title: '수정일',
    dataIndex: 'timesheetUpdated',
    sort: true,
    key: 'TIMESHEET_UPDATED',
    width: updatedWidth,
    render: (v) => {
      return (
        <Typography.Text ellipsis style={caseTableCommonProps}>
          {v ? parseDateObj(v, 'YYYY년 MM월 DD일') : ''}
        </Typography.Text>
      )
    }
  },
  {
    title: '총 시간',
    sort: true,
    dataIndex: 'totalMinutes',
    key: 'MINUTES',
    render: (totalMinutes) => {
      return <Typography.Text ellipsis style={caseTableCommonProps}>{parseMinutesToHourMin(totalMinutes)}</Typography.Text>
    },
    width: totalMinutesWidth
  },
  ...isPartner && !isDatetimerangeFilter
    ? [
        {
          title: '조정 시간',
          // sort: true,
          key: 'minutesDifference',
          width: minutesDifferenceWidth,
          hidden: !isPartner
        }
      ]
    : [],
  ...!isDatetimerangeFilter
    ? [
        {
          title: '조정 후 총 시간',
          // sort: true,
          key: 'minutesAfterEdit',
          dataIndex: 'totalMinutesAfterEdit',
          width: minutesAfterEditWidth,
          hidden: !isPartner,
          render: (totalMinutes) => {
            return <Typography.Text ellipsis strong style={caseTableCommonProps}>{parseMinutesToHourMin(totalMinutes)}</Typography.Text>
          }
        }
      ]
    : [],
  {
    width: '10px',
    render: (record) => {
      if (hoveredKey === record.key) {
        return (
          <HoverContainer>
            {
              _.includes(permissions, 'WRITE_TIMESHEET') || _.includes(permissions, 'WRITE_OTHER_TIMESHEET')
                ? <AddHoverButton
                    onClick={() => onAddTimesheet({ ...record, caseId: record.key })}
                    text='타임시트 작성하기'
                  />
                : null
            }
          </HoverContainer>
        )
      } else return null
    }
  }
]

export {
  getCaseTableColumns
}
