import { StatGroupBox } from '../StatGroupBox'
import { dayGroups } from '../../../config'
import { FilterGroupBoxName } from '../FilterGroupBoxName'
import { parseMinutesToHourMin } from '../../../util'
import { BecomesTagOnSelect } from './BecomesTagOnSelect'
import { CustomPopover } from '../../common/CustomPopover'
import { DateRangeFilter } from '../../filters/DateRangeFilter'
import { SearchParamsFilter } from '../../filters/SearchParamsFilter'

import styled from 'styled-components'
import COLORS from '../../common/Theme/colors'
import { Segmented, Typography, Button, Spin } from 'antd'
import { UndoOutlined, DownloadOutlined, CloseOutlined, BorderlessTableOutlined, UserOutlined, TagOutlined, DeploymentUnitOutlined } from '@ant-design/icons'

import _ from 'lodash'

const groups = [
  {
    key: 'caseNumber',
    value: '사건번호',
    icon: <BorderlessTableOutlined style={{ color: `${COLORS.black_88}` }} />
  },
  {
    key: 'creator',
    value: '작성자',
    icon: <UserOutlined style={{ color: `${COLORS.black_88}` }} />
  },
  {
    key: 'caseCategory',
    value: '카테고리',
    icon: <TagOutlined style={{ color: `${COLORS.black_88}` }} />
  },
  {
    key: 'caseType',
    value: '사건분류',
    icon: <DeploymentUnitOutlined style={{ color: `${COLORS.black_88}` }} />
  }
]

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  white-space: nowrap;
  flex-flow: wrap;
`
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  white-space: nowrap;
  flex-flow: wrap;
  > * {
    display: flex;
    min-height: 30px;
    align-items: center;
  }
`
const UserTagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  color: ${COLORS.primaryColor};
  border: 0.5px solid ${COLORS.primaryColor};
  padding: 5px 10px;
  border-radius: 32px;
  white-space: nowrap;
  font-size: 13px;
`
const BoxGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 260px;
`
const BoxGroupRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 15px;
  gap: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-color: lightGrey;
  border-radius: 6px;
`
const BoxGroupRowHover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 15px;
  gap: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-color: lightGrey;
  border-radius: 6px;
  &:hover {
    border-color: ${COLORS.gray_3}
  }
`
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 15px;
  height: 52px;
  min-width: 130.45px;
`
const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`
const RowWrapper = styled.div`
  display: flex;
`
const TextContainer = styled.div`
  color: ${COLORS.black_65};
  cursor: pointer;
`
const HidableContainer = styled.div`
  @media (max-width: 1310px) {
    display: none;
  }
`

const checkedProps = { color: `${COLORS.primaryColor}`, borderColor: `${COLORS.primaryColor}` }

const BaseText = ({ text }) => {
  return <Typography.Text style={{ cursor: 'pointer', fontWeight: '300' }}>{text}</Typography.Text>
}
const UserTag = ({ label, onRemove }) => {
  return (
    <UserTagContainer
      onClick={e => e.preventDefault()}
      style={{
        ...checkedProps
      }}
      shape='round'
    >
      {label}
      <CloseOutlined style={{ cursor: 'pointer', color: `${COLORS.gray_2}`, fontSize: '11px' }} onClick={onRemove} />
    </UserTagContainer>
  )
}

export const StatGroupFilter = ({ fileDownloadLoading, spinning, percent, onDownloadExcel, meta, filters = [], onChangeMeta, onResetFilterAndGroup, totalMinutes, onChangeDepartmentFilter = () => {}, departments }) => {
  return (
    <RowContainer style={{ marginBottom: '8px' }}>
      <BoxWrapper>
        <FilterGroupBoxName title='필터' left='16px' />
        <StatGroupBox flex={1} gap={10}>
          <DateRangeFilter
            value={meta.filter.datetimeRange}
            onCalendarChange={v => onChangeMeta('filter', 'datetimeRange', v)}
          />
          <RowContainer className='screenWidthControl'>
            {filters.map(({
              value,
              icon,
              key,
              selectedItems = [],
              selectedOptions = [],
              options = [],
              starredOptions = [],
              onChangeFilter,
              unit = '개',
              onOpenChange,
              ...props
            }, index) => {
              const firstSelectedItem = _.find(selectedOptions, ({ value }) => value === _.get(selectedItems, [0]))
              const selectionDescription = `${firstSelectedItem?.name}${selectedItems.length > 1 ? ` 외 ${selectedItems.length - 1}${unit}` : ''}`
              return (
                <TagContainer key={`CustomPopover_${index}`} style={{ alignItems: 'center', marginRight: '5px', flexWrap: 'nowrap' }}>
                  <CustomPopover
                    key={index}
                    icon={icon}
                    onOpenChange={onOpenChange}
                    style={{ width: index === 0 ? 1100 : 700 }}
                    hasSelected={!_.isUndefined(firstSelectedItem)}
                    persistElement={<BaseText text={value} />}
                    triggerElement={<BaseText text={value} />}
                    content={
                      <SearchParamsFilter
                        selectedItems={selectedItems}
                        options={options}
                        starredOptions={starredOptions}
                        persistElement={<BaseText text={value} />}
                        selectedOptions={selectedOptions}
                        onChange={e => onChangeFilter(e)}
                        onChangeDepartmentFilter={e => onChangeDepartmentFilter(e)}
                        filters={filters}
                        departments={departments}
                        {...props}
                      />
                    }
                  />
                  {firstSelectedItem ? <UserTag label={selectionDescription} onRemove={() => onChangeFilter([])} /> : null}
                </TagContainer>
              )
            })}
          </RowContainer>
        </StatGroupBox>
      </BoxWrapper>

      <BoxWrapper style={{ minWidth: '280px' }}>
        <FilterGroupBoxName title='그룹' left='16px' />
        <StatGroupBox flex={1} gap={10}>
          <Segmented
            value={meta.group.time}
            onChange={e => onChangeMeta('group', 'time', e)}
            style={{ width: 'fit-content' }}
            options={dayGroups}
          />
          <TagContainer className='screenWidthControl'>
            {groups.map(({ key, value, icon, disabled }, index) => {
              return (
                <BecomesTagOnSelect
                  key={`becomesTagOnSelect_${index}`}
                  icon={icon}
                  name={value}
                  tagged={_.includes(meta.group.item, key)}
                  onSelect={() => {
                    if (disabled) return
                    onChangeMeta('group', 'item', key)
                  }}
                  onClose={() => {
                    if (disabled) return
                    onChangeMeta('group', 'item', 'all')
                  }}
                >
                  {icon}
                  <BaseText text={value} />
                </BecomesTagOnSelect>
              )
            })}
          </TagContainer>
        </StatGroupBox>
      </BoxWrapper>

      <HidableContainer>
        <BoxGroupColumn style={{ marginTop: '30px' }}>
          <RowWrapper style={{ gap: '10px', marginBottom: '10px' }}>
            <BoxGroupRowHover onClick={onResetFilterAndGroup}>
              <UndoOutlined style={{ color: `${COLORS.black_65}` }} />
              <TextContainer>필터&그룹 초기화</TextContainer>
            </BoxGroupRowHover>
            <StyledButton onClick={onDownloadExcel} disabled={fileDownloadLoading}>
              {fileDownloadLoading
                ? <Spin spinning={spinning} percent={percent} />
                : (
                  <RowContainer>
                    <DownloadOutlined style={{ color: `${COLORS.primaryColor}` }} />
                    <TextContainer style={{ color: `${COLORS.primaryColor}` }}>엑셀 다운로드</TextContainer>
                  </RowContainer>
                  )}
            </StyledButton>
          </RowWrapper>
          <BoxGroupRow style={{ justifyContent: 'space-between' }}>
            <TextContainer>총 시간</TextContainer>
            <div style={{ fontWeight: '500' }}>{parseMinutesToHourMin(totalMinutes)}</div>
          </BoxGroupRow>
        </BoxGroupColumn>
      </HidableContainer>
    </RowContainer>
  )
}
