import { getShowCaseTable } from '../../../cacheAndNavigation/timesheet/cache'
import { MAX_CONTENT_WIDTH } from '../../../config'
import { CaseTable } from './CaseTable'
import { TimesheetTable } from './TimesheetTable'

import styled from 'styled-components'

const TimesheetTableContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: ${MAX_CONTENT_WIDTH}px;
`

export const CaseAndTimesheetTable = () => {
  const showCaseTable = getShowCaseTable()
  return (
    <>
      {showCaseTable
        ? <><CaseTable /></>
        : <TimesheetTableContainer><TimesheetTable /></TimesheetTableContainer>}
    </>
  )
}
