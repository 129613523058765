import { useState } from 'react'
import { useButtonLoading } from '../../common/useButtonLoading'
import { message } from 'antd'
import { getTemplateProjectList, getTemplateUserList } from '../../../fastapi/fetchers'
import { downloadExcel } from '../../../util'
import _ from 'lodash'

export const useEmplyeeTableActions = (key, dataCategory) => {
  const { showLoader } = useButtonLoading()
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false)

  const onClickTableActions = (key, dataCategory) => {
    if (key === 'templateDownload') {
      setFileDownloadLoading(true)
      showLoader()
      if (dataCategory === 'case') {
        getTemplateProjectList().then(res => {
          downloadExcel(_.get(res, ['data']), '사건등록 템플릿')
          setFileDownloadLoading(false)
          message.destroy()
        }).catch(e => {
          setFileDownloadLoading(false)
          message.destroy()
        })
      } else if (dataCategory === 'employee') {
        setFileDownloadLoading(true)
        getTemplateUserList().then(res => {
          downloadExcel(_.get(res, ['data']), '직원등록 템플릿')
          setFileDownloadLoading(false)
          message.destroy()
        }).catch(e => {
          setFileDownloadLoading(false)
          message.destroy()
        })
      }
    }
  }
  return {
    onClickTableActions,
    fileDownloadLoading
  }
}
