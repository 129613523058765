import { useEffect, useState } from 'react'
import { uuidv4 } from '../../util'

export const useHoverEvent = () => {
  const [elemId, setElemId] = useState()
  const [hovered, setHovered] = useState()
  useEffect(() => {
    setElemId(uuidv4())
  }, [])
  useEffect(() => {
    const targetElem = document.getElementById(elemId)
    const hoverEventListener = () => {
      setHovered(true)
    }
    const hoverEndEventListener = () => {
      setHovered(false)
    }
    if (elemId && targetElem) {
      targetElem.addEventListener('mouseover', hoverEventListener)
      targetElem.addEventListener('mouseout', hoverEndEventListener)
    }

    return () => {
      if (elemId && targetElem) {
        targetElem.removeEventListener('mouseover', hoverEventListener)
        targetElem.removeEventListener('mouseout', hoverEndEventListener)
      }
    }
  }, [elemId])

  return {
    elemId,
    hovered
  }
}
