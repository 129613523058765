import { timeListData } from '../mockdata'
import { searchSuggestionTablesColumns, searchSuggestionTablesTitles } from '../config'
import styled from 'styled-components'
import COLORS from '../components/common/Theme/colors'
import { Table, Typography, Button } from 'antd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const LabelContainer = styled.div`
  > * {
    color: ${props => props.$color};
  }
`

const SearchContainer = () => {
  const data = {
    visited: timeListData,
    registered: timeListData,
    starred: timeListData
  }

  return (
    <Container>
      {searchSuggestionTablesTitles.map(({ label, icon, color, key }, index) => {
        return (
          <Table
            key={`searchTable-${index}`}
            pagination={false}
            columns={searchSuggestionTablesColumns(
              <LabelContainer $color={color}>
                {icon}
                <Typography.Text style={{ marginLeft: '10px' }}>{label}</Typography.Text>
                <Button style={{ marginLeft: '15px', color: '#A0A0A0', border: `1px solid ${COLORS.gray_4}`, borderRadius: '6px', padding: '0px 10px' }}>더보기</Button>
              </LabelContainer>
            )}
            dataSource={data[key].slice(0, 3)}
          />
        )
      })}
    </Container>
  )
}

export default SearchContainer
