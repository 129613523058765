import _ from 'lodash'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../config'
import { useUserAddDeleteDepartment } from '../../apollo/mutations/useUserAddDeleteDepartment'
import { sleep } from '../../../util'

export const useEditingUserDepartmentForm = (onFetchAndNav, userId, initDepartmentIds = []) => {
  const navigate = useNavigate()
  const { messageApi } = useOutletContext()
  const {
    onAddDepartmentsToUser,
    onRemoveDepartmentsFromUser
  } = useUserAddDeleteDepartment(() => navigate('/setting/employersAuthority', { replace: true }))
  const onEdit = (savingMeta) => {
    const { departmentIds: _departmentIds } = savingMeta
    const departmentIds = [_departmentIds]
    const departmentIdsToAdd = _.differenceWith(departmentIds, initDepartmentIds)
    const departmentIdsToRemove = _.differenceWith(initDepartmentIds, departmentIds)
    onFetchAndNav(
      () => {
        if (_.isEmpty(departmentIdsToAdd) && !_.isEmpty(departmentIdsToRemove)) {
          return onRemoveDepartmentsFromUser(userId, departmentIdsToRemove)
        } else if (!_.isEmpty(departmentIdsToAdd) && _.isEmpty(departmentIdsToRemove)) {
          return onAddDepartmentsToUser(userId, departmentIdsToAdd)
        } else if (!_.isEmpty(departmentIdsToAdd) && !_.isEmpty(departmentIdsToRemove)) {
          return onAddDepartmentsToUser(userId, departmentIdsToAdd).then(v => {
            return onRemoveDepartmentsFromUser(userId, departmentIdsToRemove)
          })
        } else return sleep(100)
      },
      (v) => {
      },
      () => {
        messageApi.open(MESSAGES.updateEmployeeError)
      }
    )
  }

  return {
    onEdit
  }
}
