import { useNavigate, useOutletContext } from 'react-router-dom'
import { useAllocateUsers } from '../../apollo/mutations/useAllocateUsers'

import _ from 'lodash'
import { MESSAGES } from '../../../config'
import { useDelegateUsers } from '../../apollo/mutations/useDelegateUsers'
import { sleep } from '../../../util'

export const useSavingUserAllocation = (
  chartState,
  caseId,
  userIdsToAllocate,
  userIdsToDisallocate,
  userIdsToDelegate,
  userIdsToAbdicate,
  onFetchAndNav
) => {
  const navigate = useNavigate()
  const { messageApi } = useOutletContext()
  const {
    allocateUsers,
    deallocateUsers
  } = useAllocateUsers()
  const {
    delegateUsers,
    abdicateUsers
  } = useDelegateUsers()
  const handleAllocateError = () => {
    messageApi.open(MESSAGES.allocateUsersError)
  }
  const onSave = () => {
    onFetchAndNav(
      () => {
        const allocateUsersFetch = async () => {
          if (_.isEmpty(userIdsToAllocate) && !_.isEmpty(userIdsToDisallocate)) {
            return deallocateUsers(caseId, userIdsToDisallocate)
          } else if (!_.isEmpty(userIdsToAllocate) && _.isEmpty(userIdsToDisallocate)) {
            return allocateUsers(caseId, userIdsToAllocate)
          } else if (!_.isEmpty(userIdsToAllocate) && !_.isEmpty(userIdsToDisallocate)) {
            return allocateUsers(caseId, userIdsToAllocate).then(v => {
              if (_.get(v, ['data', 'AllocateUsersToProject', 'ok'])) {
                return deallocateUsers(caseId, userIdsToDisallocate)
              } else {
                handleAllocateError()
                return deallocateUsers(caseId, userIdsToDisallocate)
              }
            })
          } else return sleep(100)
        }
        const delegateUsersFetch = async () => {
          if (_.isEmpty(userIdsToDelegate) && !_.isEmpty(userIdsToAbdicate)) {
            return abdicateUsers(caseId, userIdsToAbdicate)
          } else if (!_.isEmpty(userIdsToDelegate) && _.isEmpty(userIdsToAbdicate)) {
            return delegateUsers(caseId, userIdsToDelegate)
          } else if (!_.isEmpty(userIdsToDelegate) && !_.isEmpty(userIdsToAbdicate)) {
            return delegateUsers(caseId, userIdsToDelegate).then(v => {
              if (_.get(v, ['data', 'DelegateUsersToProject', 'ok'])) {
                return abdicateUsers(caseId, userIdsToAbdicate)
              } else {
                handleAllocateError()
              }
            })
          }
        }
        return allocateUsersFetch().then(() => {
          return delegateUsersFetch()
        })
      },
      (v) => {
        navigate('/manageCase/table', { replace: true })
      },
      e => {
        handleAllocateError()
      }
    )
  }

  const onCancel = () => {
    navigate(-1)
  }

  return {
    onSave,
    onCancel
  }
}
