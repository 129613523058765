import React from 'react'
import { Typography } from 'antd'

import styled from 'styled-components'

import { FlexStub } from '../../../../common/FlexStub'
import { parseMinutesToHourMin } from '../../../../../util'
import { FolderTitleContainer } from '../../../../folder/FolderTitle'
import { PushpinOutlined } from '@ant-design/icons'
import COLORS from '../../../../common/Theme/colors'
import { useOutletContext } from 'react-router-dom'
import RemovedFromCaseTag from '../../../../common/Tags/RemovedFromCaseTag'

const FolderWidth = 200

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`

const ContentContainer = styled.div`
  position: absolute;
  padding: 0px 18px 0px 15px;
`

const FolderDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TimesheeNumberAndMinutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 168px;
  gap: 2px;
`
const EditTimeContainer = styled.div`
    display: flex;
  justify-content: center;
  color: ${COLORS.black_45};
  align-items: center;
  font-size: 12px;
  margin-bottom: 3px;
`

const Folder = ({ id, name, minutes, deltaMinutes, relation, totalDeltaMinutes, onDoubleClickFolder, timesheetCount = 0 }) => {
  const { userId } = useOutletContext()
  return (
    <Container onDoubleClick={onDoubleClickFolder}>
      <img
        alt='example'
        width={FolderWidth}
        src='/folder.png'
        autoFocus={false}
      />
      <ContentContainer>
        <FolderTitleContainer>
          {id === userId ? <PushpinOutlined style={{ color: `${COLORS.white}`, background: `${COLORS.blue_6}` }} /> : null}
          <Typography.Title level={4} style={{ margin: 0, color: `${COLORS.white}`, fontSize: 15, marginTop: '3px' }}>
            {name}
          </Typography.Title>
          {relation === 'DEALLOCATED' ? <RemovedFromCaseTag faintColor={`${COLORS.white}`} /> : null}
        </FolderTitleContainer>
        <FlexStub $height={20} />
        <FolderDescriptionContainer>
          <EditTimeContainer>
            {totalDeltaMinutes >= 0 ? '+' : ''}
            {parseMinutesToHourMin(deltaMinutes)}
          </EditTimeContainer>
          <TimesheeNumberAndMinutesContainer>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {parseMinutesToHourMin(minutes)}
            </Typography.Title>
            <Typography.Text type='secondary'>{timesheetCount || 0}개</Typography.Text>
          </TimesheeNumberAndMinutesContainer>
        </FolderDescriptionContainer>
      </ContentContainer>

    </Container>
  )
}

export default Folder
