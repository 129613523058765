import React from 'react'
import styled from 'styled-components'
import { HeaderContentFooter } from '../../components/common/HeaderContentFooter'
import RankAdder from '../../components/settings/rank/RankAdder'
import _ from 'lodash'
import { useRankAdder } from '../../hooks/settings/useRankAdder'
import { HeaderContainer, HeaderTitle, Spacer } from '../../components/common/HeaderGroup'
import { useRankList } from '../../hooks/apollo/useRankList'

const Container = styled.div`
  width: 100%;
`
const ManageRankContainer = () => {
  const { ranks, loading } = useRankList()
  const {
    onAddRank,
    onEditRank,
    onRemoveRank
  } = useRankAdder()

  return (
    <HeaderContentFooter
      header={(
        <HeaderContainer>
          <HeaderTitle title='직급 관리' />
          <Spacer $flex={5} />
        </HeaderContainer>
      )}
      content={(
        <Container>
          {loading
            ? null
            : <RankAdder
                initDataSource={_.map(ranks, ({ ...v }) => ({ key: v.id, ...v }))}
                onAddRank={onAddRank}
                onEditRank={onEditRank}
                onRemoveRank={onRemoveRank}
              />}
        </Container>
      )}
    />
  )
}

export default ManageRankContainer
