import styled from 'styled-components'
import COLORS from '../components/common/Theme/colors'
import { AppstoreAddOutlined, BranchesOutlined, DeleteOutlined, DeleteRowOutlined, FileOutlined, FlagOutlined, KeyOutlined, UserAddOutlined } from '@ant-design/icons'
import { Badge, Button, List, Popover, Space, Typography } from 'antd'

import { HeaderGroup } from '../components/common/HeaderGroup'
import _ from 'lodash'
import { useRef, useState } from 'react'
import { usePushNotification } from '../hooks/pushNotification/usePushNotification'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const AlertTileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap:210px;
  padding: 25px 26px 20px;
  background-color: ${COLORS.white};
  margin: 0;
  border-radius: 10px 10px 0px 0px;
`
const iconByType = {
  PROJECT_ALLOCATED: <UserAddOutlined style={{ color: COLORS.pink }} />,
  PROJECT_DELEGATED: <BranchesOutlined style={{ color: COLORS.blue_4 }} />,
  PROJECT_CLOSED: <FlagOutlined style={{ color: COLORS.orange }} />,
  PROJECT_REOPENED: <DeleteRowOutlined style={{ color: COLORS.purple }} />,
  PROJECT_REMOVED: <DeleteOutlined style={{ color: COLORS.red }} />,
  PROJECT_RESTORED: <AppstoreAddOutlined style={{ color: COLORS.green }} />,
  PASSWORD_RESET: <KeyOutlined style={{ color: `${COLORS.orange}` }} />,
  PROJECT_CREATED: <FileOutlined style={{ color: COLORS.primaryColor }} />
}
const SidbarLabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const TagContainer = styled.div`
  display: flex;
  gap: 10px;
`
const NotificationContainer = () => {
  const [opened, setOpened] = useState(null)
  const popOverRef = useRef()

  const { pushNotifications, unreadCount, onMarkAllPushNotificationRead } = usePushNotification(
    () => setOpened(false)
  )
  return (
    <Popover
      ref={popOverRef}
      open={opened}
      onOpenChange={e => setOpened(e)}
      overlayInnerStyle={{ background: 'none', padding: 0 }}
      arrow={false}
      trigger='click'
      placement='rightTop'
      content={
        <ContentContainer>
          <NotificationContent
            pushNotifications={pushNotifications}
            unreadCount={unreadCount}
            onMarkAllPushNotificationRead={onMarkAllPushNotificationRead}
          />
        </ContentContainer>
      }
    >
      <SidbarLabelContainer>
        <div>알림</div>
        <TagContainer>
          {unreadCount ? <Badge count={unreadCount} /> : null}
        </TagContainer>
      </SidbarLabelContainer>
    </Popover>
  )
}

const NotificationContent = ({ pushNotifications = [], unreadCount = 0, onMarkAllPushNotificationRead = () => {} }) => {
  return (
    <ContentContainer>
      <AlertTileContainer>
        <HeaderGroup>
          <Typography.Title style={{ margin: 0, whiteSpace: 'nowrap' }} level={4}>알림</Typography.Title>
          {unreadCount ? <Badge color='blue' count={unreadCount} /> : null}
        </HeaderGroup>
        <HeaderGroup>
          <Typography.Text type='secondary' style={{ whiteSpace: 'nowrap' }}>
            ⓘ 30일간의 알림만 보여집니다.
          </Typography.Text>
          <Button disabled={!unreadCount} onClick={onMarkAllPushNotificationRead}>전체 읽음 표시</Button>
        </HeaderGroup>
      </AlertTileContainer>
      <List
        style={{ borderRadius: '0px 0px 10px 10px', overflow: 'auto', height: pushNotifications.length < 7 ? undefined : '35vh', background: `${COLORS.white}` }}
        dataSource={pushNotifications}
        renderItem={(item, i) => (
          <>
            <List.Item onClick={() => _.get(item, ['onClick'], () => {})()} key={`list_${i}`} style={{ background: item.reviewed ? '#efefef' : 'white', padding: '16px 35px', opacity: 1, cursor: 'pointer' }}>
              <Space>
                {_.get(iconByType, [item.type])}
                <Typography.Text>{item.text}</Typography.Text>
              </Space>
              <Typography.Text type='secondary'>{item.date}</Typography.Text>
            </List.Item>
          </>
        )}
      />
    </ContentContainer>
  )
}

export default NotificationContainer
