import _ from 'lodash'
import { useEffect } from 'react'

export const useDefaultDateRangeFilter = (meta, onResetDateTimeRange) => {
  useEffect(() => {
    if (_.isEmpty(meta.filter.datetimeRange)) {
      const curr = new Date()
      const first = curr.getDate() - curr.getDay() + 1
      const last = first + 6

      onResetDateTimeRange([new Date().setDate(first), new Date().setDate(last)])
    }
  }, [])
}
