// 타임시트 작성/수정
import _ from 'lodash'
import { useEffect, useRef } from 'react'

import { Timesheet } from '../components/timesheetForm/TimesheetForm'
import { HeaderContainer as _HeaderContainer, HeaderGroup, HeaderTitle, Spacer } from '../components/common/HeaderGroup'
import { Attatchments } from '../components/common/Attatchments'
import { HeaderContentFooter } from '../components/common/HeaderContentFooter'
import { useFormAttatchments } from '../hooks/timesheetForm/useFormAttatchments'
import { useSavingTimesheetForm } from '../hooks/timesheetForm/useSavingTimesheetForm'
import { BlockModal } from '../components/common/BlockModal'

import { useTimesheetFormNavigationCache } from '../hooks/timesheetForm/useTimesheetFormNavigationCache'
import { useOutletContext } from 'react-router-dom'
import { useTimesheetFormPermissions } from '../hooks/timesheetForm/useTimesheetFormPermissions'
import styled from 'styled-components'
import COLORS from '../components/common/Theme/colors'
import { EditOutlined } from '@ant-design/icons'
import { Button, Checkbox, Typography } from 'antd'

const TimesheetContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  min-width: 500px;
  max-width: 800px;
`
const AttatchmentContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: ${COLORS.black_1};
  > * {
    max-width: 800px;
  }
`
const HeaderContainer = styled(_HeaderContainer)`
  justify-content: flex-end;
`

const FooterHeight = 300

const TimesheetFormContainer = () => {
  const timesheetRef = useRef()

  const { userId, permissions } = useOutletContext()
  const {
    userInputEnabled,
    shouldInitializeUserInputAsCurrentUser
  } = useTimesheetFormPermissions(permissions)

  const {
    status,
    initMeta
  } = useTimesheetFormNavigationCache(userId, shouldInitializeUserInputAsCurrentUser)

  const {
    files,
    fileIds,
    onDeleteFileByKey,
    dragDropProps,
    ...attachmentsProps
  } = useFormAttatchments(_.get(initMeta, ['attachments'], undefined) || [])

  const {
    saving,
    cancelModalOpen,
    onCloseCancelModal,
    onOkCancelModal,
    onOkZeroMinConfirmModal,
    onCancelZeroMinConfirmModal,
    readOnly,
    isKeepWorkingAfterSave,
    confirmModalWhenMinutesIsZeroOpen,
    onSave,
    onCancel,
    onStartEditing,
    onChangeKeepWorkingAfterSaveMode
  } = useSavingTimesheetForm(
    () => {
      const meta = timesheetRef.current?.getMeta() || {}
      return {
        ...meta,
        attachments: fileIds
      }
    },
    () => timesheetRef.current?.resetMeta(),
    userInputEnabled
  )

  const prevStatus = useRef(status)
  useEffect(() => {
    if (!cancelModalOpen) {
      prevStatus.current = status
    }
  }, [cancelModalOpen])

  return (
    <>
      <BlockModal
        open={cancelModalOpen}
        title={`타임시트 ${prevStatus.current === 'creating' ? '작성을' : '수정을'} 취소하시겠습니까?`}
        subTitle='변경사항이 저장되지 않습니다.'
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
      <BlockModal
        open={confirmModalWhenMinutesIsZeroOpen}
        title='입력시간이 0분입니다. 계속하시겠습니까?'
        onOk={() => onOkZeroMinConfirmModal()}
        onCancel={() => onCancelZeroMinConfirmModal()}
      />
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title={`타임시트 ${prevStatus.current === 'creating' ? '작성' : '수정'}`} />
            <Spacer />
            {readOnly
              ? (
                <HeaderGroup>
                  <Button
                    type='primary'
                    style={{ display: 'flex', alignItems: 'center' }}
                    icon={<EditOutlined />}
                    onClick={() => onStartEditing()}
                  >
                    수정하기
                  </Button>
                </HeaderGroup>)
              : (
                <HeaderGroup>
                  {prevStatus.current === 'creating'
                    ? (
                      <Checkbox
                        checked={isKeepWorkingAfterSave}
                        onChange={({ target: { checked } }) => onChangeKeepWorkingAfterSaveMode(checked)}
                      />)
                    : null}
                  {prevStatus.current === 'creating' ? <Typography.Text type='secondary'>저장하고 계속 추가하기</Typography.Text> : null}
                  <Button onClick={onCancel}>
                    취소하기
                  </Button>
                  <Button type='primary' loading={saving} onClick={() => onSave()}>
                    저장하기
                  </Button>
                </HeaderGroup>)}
          </HeaderContainer>
        )}
        content={(
          <TimesheetContainer>
            <Timesheet
              ref={timesheetRef}
              readOnly={readOnly}
              status={status}
              initMeta={initMeta}
              userInputEnabled={userInputEnabled}
            />
          </TimesheetContainer>
        )}
        footer={(
          <AttatchmentContainer>
            <Attatchments
              onDeleteFileByKey={onDeleteFileByKey}
              dragDropProps={dragDropProps}
              readOnly={readOnly}
              files={files}
              {...attachmentsProps}
            />
          </AttatchmentContainer>
        )}
        footerHeight={FooterHeight}
      />
    </>
  )
}

export default TimesheetFormContainer
