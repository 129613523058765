import React, { useRef, useState } from 'react'

import styled from 'styled-components'
import { MenuOutlined, PlusOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import { Button, Table } from 'antd'

import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import COLORS from '../../common/Theme/colors'
import { TimesheetTitleTemplateEditableCell } from './TimesheetTitleTemplateEditableCell'
import _ from 'lodash'
import { EditSaveRowButtons } from '../common/EditSaveRowButtons'
import { useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../config'

const MenuColumnWidth = 120

const Container = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  border-top: 1px solid ${COLORS.primaryColor50};
`
const IconAndTextButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`
const ItemCountWrapper = styled.div`
  display: flex;
  gap: 5px;
`
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  background-color: aliceblue;
`
const BlueText = styled.div`
  color: ${COLORS.primaryColor};
  font-weight: bold;
`
const TableComponent = styled(Table)`
  & colpsan > col:nth-child(2),
  & thead > tr > th:nth-child(2),
  & tbody > tr > td:nth-child(2) {
    min-width: 100px;
    max-width: 800px;
    width: 100%;
  }
`

const TimesheetTitleTemplateAdder = ({
  initDataSource = [],
  onAddTimesheetTitleTemplate = () => {},
  onEditTimesheetTitleTemplate = () => {},
  onRemoveTimesheetTitleTemplate = () => {}
}) => {
  const [editingKey, setEditingKey] = useState('')
  const [addingNew, setAddingNew] = useState(false) // 새로운 항목 추가인지 확인
  const [dataSource, setDataSource] = useState(initDataSource)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const isEditing = (record) => record.key === editingKey
  const { messageApi } = useOutletContext()
  const colorRef = useRef()
  const textRef = useRef()

  const columns = [
    {
      key: 'sort',
      width: MenuColumnWidth,
      editable: false
    },
    {
      title: '제목',
      dataIndex: 'name',
      editable: true
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record)
        return (
          <EditSaveRowButtons
            editable={editable}
            editingKey={editingKey}
            record={record}
            onSave={() => save(record.key)}
            onDelete={() => handleDelete(record.key)}
            onEdit={() => edit(record)}
            onCancel={() => cancel(record)}
          />
        )
      }
    }
  ]

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id)
        const overIndex = previous.findIndex((i) => i.key === over?.id)
        return _.map(arrayMove(previous, activeIndex, overIndex), (record, index) => {
          const order = _.get(record, ['order'])
          const name = _.get(record, ['name'])
          const key = _.get(record, ['key'])
          if (index !== order) onEditTimesheetTitleTemplate(key, name, index)
          return {
            ...record,
            order: index
          }
        })
      })
    }
  }

  const edit = (record) => {
    setEditingKey(record.key)
    setButtonDisabled(true)
  }

  const cancel = (record) => {
    if (addingNew === false) {
      setEditingKey('')
    } else {
      const newData = dataSource.filter((item) => item.key !== record.key)
      setDataSource(newData)
      setEditingKey('')
    }
    setButtonDisabled(false)
  }

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key)
    setDataSource(newData)
    onRemoveTimesheetTitleTemplate(key)
  }

  const save = async (key) => {
    try {
      const newData = [...dataSource]
      const initKeys = _.map(initDataSource, 'key')
      const index = newData.findIndex((item) => key === item.key)
      const name = textRef?.current?.getName()
      const addingRow = {
        name
      }
      if (name.length === 0) {
        messageApi.open(MESSAGES.timesheetTitleMissingWarning)
      } else {
        const isEditingItem = _.includes(initKeys, key)
        if (isEditingItem) onEditTimesheetTitleTemplate(key, name, index)
        else onAddTimesheetTitleTemplate(name, index)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...addingRow })
        setDataSource(newData)
        setEditingKey('')
        setAddingNew(false)
        setButtonDisabled(false)
      }
    } catch (errInfo) {
    }
  }

  const Row = ({
    row,
    editing,
    dataIndex,
    title,
    inputtype,
    record,
    index,
    currentRowValues,
    handleInputChange,
    children,
    ...props
  }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging
    } = useSortable({
      id: props['data-row-key']
    })
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999
          }
        : {})
    }
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === 'sort') {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: 'none',
                    cursor: 'move'
                  }}
                  {...listeners}
                />
              )
            })
          }
          return child
        })}
      </tr>
    )
  }
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputtype: col.dataIndex === 'color' ? 'ColorPicker' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ref: isEditing(record) ? col.dataIndex === 'color' ? colorRef : textRef : null
      })
    }
  })

  const handleAdd = () => {
    const newData = {
      key: dataSource.length + 1,
      name: '',
      color: ''
    }
    edit(newData)
    setDataSource([...dataSource, newData])
    setAddingNew(true)
  }

  return (
    <Container>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          // const caseTags = categories.map(({id, name, color}) => ({id, name, color}))
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <RowContainer>
            <IconAndTextButton
              onClick={handleAdd}
              disabled={buttonDisabled}
            >
              <PlusOutlined style={{ color: `${COLORS.red}` }} />
              <div>제목 추가</div>
            </IconAndTextButton>
            <ItemCountWrapper>
              총
              <BlueText>{dataSource.length}</BlueText>
              개
            </ItemCountWrapper>
          </RowContainer>
          <TableComponent
            rowKey='key'
            columns={mergedColumns}
            dataSource={dataSource}
            components={{
              body: {
                row: Row,
                cell: TimesheetTitleTemplateEditableCell
              }
            }}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </Container>
  )
}

export default TimesheetTitleTemplateAdder
