import _ from 'lodash'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../config'
import { useAddUpdateDepartment } from '../../apollo/mutations/useAddUpdateDepartment'

export const useEditingDepartmentForm = (onFetchAndNav, departmentId, initLeaders) => {
  const navigate = useNavigate()
  const { messageApi } = useOutletContext()
  const {
    onUpdateDepartment
  } = useAddUpdateDepartment(() => navigate('/setting/department', { replace: true }))
  const onEdit = (savingMeta) => {
    const { leaders, name } = savingMeta
    let _leaders = leaders
    if (_.isEqual(initLeaders, leaders)) _leaders = null // leaders 가 null 이면 기존 값을 override 하지 않음
    onFetchAndNav(
      () => onUpdateDepartment(departmentId, leaders, name),
      (v) => {

      },
      () => {
        messageApi.open(MESSAGES.updateDepartmentError)
      }
    )
  }

  return {
    onEdit
  }
}
