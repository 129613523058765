import { useState } from 'react'
import CalendarCellItem from './CalendarCellItem'
import { parseMinutesToHourMin } from '../../../../util'
import { Spacer } from '../../HeaderGroup'

import styled from 'styled-components'
import COLORS from '../../Theme/colors'
import { Typography } from 'antd'
import _ from 'lodash'
import { ScrollContainer } from '../../../ScrollContainer'

const HourMinTextWidth = 120

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  > * {
    .ant-typography {
      font-size: 13px;
    }
  }
`

const EllipsisTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${COLORS.black_65};
  gap: 5px;
`

export const CalendarCell = ({
  dataList = [],
  hoveredCaseId,
  onHoverItem,
  totalMinutes,
  onClickCellSummary = () => {},
  onClickCalendarListItem = () => {},
  onDoubleClickCalendarCell = () => {}
}) => {
  const [overflowIds, setOverflowIds] = useState([])
  const shouldDisplayHHMMFormat = !_.isEmpty(overflowIds)
  return (
    <Container onDoubleClick={onDoubleClickCalendarCell}>
      {/* {dataList.slice(0, 4).map((props, index) => { */}
      <ScrollContainer height='92px' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
        {dataList.map((props, index) => {
          return (
            <CalendarCellItem
              {...props}
              key={`${index}-${props.key}`}
              id={props.key}
              hoveredCaseId={hoveredCaseId}
              shouldDisplayHHMMFormat={shouldDisplayHHMMFormat}
              onHoverItem={onHoverItem}
              onChangeOverflow={(isOverflow) => setOverflowIds(overflowIds => {
                if (isOverflow) {
                  return [...overflowIds, props.key]
                } else {
                  return _.filter(overflowIds, v => v !== props.key)
                }
              })}
              onClickCalendarListItem={() => onClickCalendarListItem(props)}
            />
          )
        })}
      </ScrollContainer>
      <Spacer />
      {dataList.length
        ? (
          <EllipsisTextContainer style={{}} onClick={onClickCellSummary}>
            <Typography.Text>
              총 시간:
            </Typography.Text>
            <Typography.Text ellipsis style={{ maxWidth: HourMinTextWidth, minWidth: 20, fontWeight: 'bold' }}>
              {` ${parseMinutesToHourMin(totalMinutes)}`}
            </Typography.Text>
            <Typography.Text ellipsis style={{ maxWidth: 80, minWidth: 20 }}>
              ({dataList.length}개)
            </Typography.Text>
          </EllipsisTextContainer>)
        : null}
    </Container>
  )
}
