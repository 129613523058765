import styled from 'styled-components'
import COLORS from './Theme/colors'

const HeaderGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 40px;
`
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  > * + * {
    margin-left: 1em;
  }
`
const ContentHeaderContainer = styled(HeaderContainer)``
const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
`
const Spacer = styled.div`
  display: flex;
  flex: ${props => props.$flex || 1};
`
const ItemNumber = styled.div`
  color: ${COLORS.primaryColor};
  font-weight: bold;
  font-size: 16px;
`
const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${COLORS.black_88};
`

const HeaderTitle = ({
  title
}) => (
  <HeaderTitleContainer>
    <StyledTitle>
      {title}
    </StyledTitle>
  </HeaderTitleContainer>
)

export {
  HeaderGroup,
  ContentHeaderContainer,
  HeaderContainer,
  ItemNumber,
  HeaderTitle,
  Spacer
}
