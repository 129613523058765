import React from 'react'
import { FlexStub } from '../../../../common/FlexStub'
import { StarIcon } from '../../../../common/StarIcon'
import { parseMinutesToHourMin } from '../../../../../util'

import styled from 'styled-components'
import COLORS from '../../../../common/Theme/colors'
import { Typography } from 'antd'

const FolderWidth = 320

const Container = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  top: 8px;
  padding: 0px 18px 25px 18px;
  width: ${FolderWidth}px;
  position: absolute;
  height: 100%;
  flex: 1;
`
const CaseTitleAndStarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 285px;
`
const FolderDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  .users {
    height: 70px;
    overflow-y: auto;
  }
`
const TimesheeNumberContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3px 10px;
  background-color: ${COLORS.primaryColor10};
  border-radius: 5px;
`
const EllipsisText = styled.div`
  font-size: 15px;
  gap:5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${COLORS.black_65};
  width: 100%;
`
const RowContainer = styled.div`
  display: flex;
  gap:5px;
`
const LineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const TimeContainer = styled.div`
  display: flex;
  justify-content: center;
`
const EditTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${COLORS.black_45};
  align-items: center;
  font-size: 12px;
`

const Folder = ({ readTimeDeltaPermission, starred, caseNumber, totalMinutes, totalDeltaMinutes, timesheets = [], users = [], onDoubleClickFolder, onCheckStar, userCount }) => {
  return (
    <Container onDoubleClick={onDoubleClickFolder}>
      <img
        alt='example'
        width={FolderWidth}
        src='/folder.png'
        autoFocus={false}
      />
      <ContentContainer>
        <CaseTitleAndStarContainer>
          <Typography.Title
            level={4}
            style={{ width: '210px', margin: 0, color: COLORS.white, fontSize: 17, marginTop: '1px', paddingLeft: '5px' }}
            ellipsis={{ tooltip: caseNumber }}
          >
            {caseNumber}
          </Typography.Title>
          <StarIcon checked={starred} onCheckStar={onCheckStar} />
        </CaseTitleAndStarContainer>
        <FlexStub $height={25} />
        <FolderDescriptionContainer>
          {readTimeDeltaPermission
            ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <EditTimeContainer>
                  {totalDeltaMinutes >= 0 ? '+' : '-'}
                  {parseMinutesToHourMin(Math.abs(totalDeltaMinutes))}
                </EditTimeContainer>
              </div>)
            : null}
          <TimeContainer>
            <Typography.Title level={4} style={{ margin: 0 }}>
              {parseMinutesToHourMin(totalMinutes)}
            </Typography.Title>
          </TimeContainer>
          <TimesheeNumberContainer>
            <Typography.Text type='secondary' style={{ color: `${COLORS.primaryColor}` }}>담당변호사: {userCount || 0}명 [총{timesheets.length || 0}개]</Typography.Text>
          </TimesheeNumberContainer>
          <div className='users'>
            {users.map(({ relation, minutes, name, timesheetCount }, index) => {
              return (
                <RowContainer key={`user_${index}`}>
                  <EllipsisText style={{ maxWidth: '120px', color: relation === 'ALLOCATED' ? `${COLORS.black_2}` : `${COLORS.gray_3}`, fontWeight: '500' }}>{name}</EllipsisText>
                  <EllipsisText style={{ maxWidth: '80px', color: relation === 'ALLOCATED' ? `${COLORS.black_2}` : `${COLORS.gray_3}` }}>[{timesheetCount || 0}개]</EllipsisText>
                  <EllipsisText style={{ maxWidth: '115px', color: relation === 'ALLOCATED' ? `${COLORS.black_2}` : `${COLORS.gray_3}` }}>{parseMinutesToHourMin(minutes)}</EllipsisText>
                </RowContainer>
              )
            })}
          </div>
          <LineContainer />
        </FolderDescriptionContainer>
      </ContentContainer>
    </Container>
  )
}

export default Folder
