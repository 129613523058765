import { CustomTable } from '../../common/CustomTable'

import { useCaseTable } from '../../../hooks/timesheet/useCaseTable'
import styled from 'styled-components'
import { MAX_CONTENT_WIDTH } from '../../../config'
import _ from 'lodash'

const CaseTableContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin-block: 0px;
    margin-inline: 0px 0px;
  }
  .ant-table-expanded-row > .ant-table-cell {
    padding: 0px !important;
  }
  max-width: ${props => props.$maxWidth || MAX_CONTENT_WIDTH}px;
`

export const CaseTable = () => {
  const {
    total,
    onChangeHoveredKey: onChangeCaseTableHoveredKey,
    onChangeExpandedKeys: onChangeCaseTableExpandedKeys,
    ...props
  } = useCaseTable()
  return (
    <CaseTableContainer $maxWidth={_.get(props, ['style', 'width'])}>
      <CustomTable
        {...props}
        total={total}
        unit='건'
        onChangeHoveredKey={onChangeCaseTableHoveredKey}
      />
    </CaseTableContainer>
  )
}
