import _ from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  BellOutlined,
  FileOutlined,
  LineChartOutlined,
  ShareAltOutlined,
  StarOutlined,
  SettingOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

import { navigateToTimesheetForm } from '../../cacheAndNavigation/timesheetForm/navigation'

import NotificationContainer from '../../containers/NotificationContainer'
import { AddHoverButton } from '../../components/AddHoverButton'
import { useCaseUserFilterSearchParams } from '../filter/params/useCaseUserFilterSearchParams'
import { navigateToCaseList } from '../../cacheAndNavigation/timesheet/navigation'

const SidbarLabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const useSidebar = (permissions) => {
  const { searchParams } = useCaseUserFilterSearchParams()
  const noAuthToReadTimesheet = !_.includes(permissions, 'READ_ALL_TIMESHEET') && !_.includes(permissions, 'READ_SAME_DEPARTMENT_TIMESHEET') && !_.includes(permissions, 'WRITE_TIMESHEET')
  const noAuthForSetting = !(_.includes(permissions, 'MANAGE_USERS') || _.includes(permissions, 'MANAGE_DEPARTMENTS') || _.includes(permissions, 'MANAGE_TIMESHEET_NAME') || _.includes(permissions, 'MANAGE_PROJECT_CATEGORY'))
  const noAuthToManageProject = !_.includes(permissions, 'CREATE_PROJECT') && !_.includes(permissions, 'CREATE_CONSULTATION') && !_.includes(permissions, 'CREATE_ETC') && !_.includes(permissions, 'UPDATE_PROJECT_DATA')
  // 사이드바
  const _sidebarItems = [
    {
      key: 'notification',
      icon: <BellOutlined />,
      label: (
        <NotificationContainer />
      )
    },
    {
      key: 'timesheet-starred',
      icon: <StarOutlined />,
      label: '즐겨찾기',
      hidden: noAuthToReadTimesheet
    },
    {
      key: 'timesheet-all',
      icon: <FileOutlined />,
      label: (
        <SidbarLabelContainer>
          <div>진행사건</div>
          {
            _.includes(permissions, 'WRITE_TIMESHEET') || _.includes(permissions, 'WRITE_OTHER_TIMESHEET')
              ? <AddHoverButton
                  onClick={(e) => {
                    e.stopPropagation()
                    navigateToTimesheetForm(navigate, 'creating', {})
                  }}
                  text='타임시트 작성하기'
                />
              : null
          }
        </SidbarLabelContainer>
      ),
      hidden: noAuthToReadTimesheet
    },
    {
      key: 'stat',
      icon: <LineChartOutlined />,
      label: '통계',
      hidden: noAuthToReadTimesheet
    },
    {
      key: '/manageCase/table',
      icon: <ShareAltOutlined />,
      label: (
        <SidbarLabelContainer>
          <div>사건관리</div>
          {
            _.includes(permissions, 'CREATE_PROJECT') || _.includes(permissions, 'CREATE_CONSULTATION') || _.includes(permissions, 'CREATE_ETC')
              ? <AddHoverButton
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate('/manageCase/createNav')
                  }}
                  text='사건 생성하기'
                />
              : null
          }
        </SidbarLabelContainer>
      ),
      hidden: noAuthToManageProject
    },
    {
      key: 'trash',
      icon: <DeleteOutlined />,
      label: '휴지통',
      hidden: noAuthToManageProject
    },
    {
      key: 'setting-timesheetTitle',
      icon: <SettingOutlined />,
      label: (
        <SidbarLabelContainer>
          <div>설정</div>
        </SidbarLabelContainer>
      ),
      hidden: noAuthForSetting
    }
  ]
  const sidebarItems = _.map(_.filter(_sidebarItems, v => {
    return !v.hidden
  }), item => {
    return {
      ...item
    }
  })

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const history = location.pathname.split('/').slice(1)

  const onSelectSidebarMenu = (e) => {
    const searchString = searchParams.toString()
    if (e.key === 'search' || e.key === 'notification') {
      //
    } else if (e.key === 'timesheet-starred' || e.key === 'timesheet-closed' || e.key === 'timesheet-all') {
      navigateToCaseList(navigate, e.key.split('-')[1], undefined, undefined, searchString, true)
    } else if (e.key === 'setting-timesheetTitle') {
      navigate(`/setting/${e.key.split('-')[1]}?${searchString}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    } else if (e.key === 'setting-groupAuthority') {
      navigate(`/setting/${e.key.split('-')[1]}?${searchString}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    } else {
      navigate(`${e.key}?${searchString}`, {
        state: _.get(window, ['history', 'state', 'usr'], {})
      })
    }
  }

  let selectedKey = null
  if (history[0] === 'timesheet') {
    selectedKey = `timesheet-${params.caseStatus}`
  } else if (history[0] === 'manageCase' || history[0] === 'manageCase') {
    selectedKey = '/manageCase/table'
  } else if (history[0] === 'employers') {
    selectedKey = 'setting-groupAuthority'
  } else if (history[0] === 'setting') {
    selectedKey = 'setting-timesheetTitle'
  } else {
    selectedKey = history[0]
  }

  return {
    selectedKey,
    sidebarItems,
    onSelectSidebarMenu
  }
}
