import { useState } from 'react'
import { parseDateObj } from '../../util'
import { PieChartTooltip } from '../../components/statistics/StatGraph/PieChartTooltip'
import { korNameByCategory } from '../../config'

import _ from 'lodash'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import fontColorContrast from 'font-color-contrast'
import COLORS from '../../components/common/Theme/colors'

dayjs.extend(customParseFormat)

const generateRandomColor = () => {
  const h = 240
  const s = Math.floor(Math.random() * 100)
  let l = Math.floor(Math.random() * 100)
  if (l < 50) {
    l += 50
  }
  const color = 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
  return color
}
const generateRandomColors = (num = 0) => {
  return _.range(0, num).map(v => {
    return generateRandomColor()
  })
}

export const useGraphMeta = (meta, timeGroups, timesheets, caseNumbers = [], users = [], totalCount) => {
  const [barType, setBarType] = useState('totalMinutes') // timesheetCount/totalMinutes
  // for duplicate value
  const casesByCaseNumber = _.groupBy(
    caseNumbers,
    'value'
  )
  const usersByName = _.groupBy(
    users,
    'value'
  )
  const colorScheme = ['#102269', '#3c60a1', '#5866a1', '#5f7fff', '#8ba2ff', '#b4c3ff', '#dfe6ff', '#c7e1ff', '#89c0ff', '#5faaff', '#c7e1ff', '#cccccc', '#b0b0b0']
  // bar graph
  const getUniqBarChartLabel = (v) => {
    const label = _.get(korNameByCategory, _.get(v, ['secondaryKey', 'category'])) || _.get(v, ['secondaryKey', 'name'])
    if (_.get(meta.group.item === 'creator' ? usersByName : casesByCaseNumber, [label], []).length > 1) {
      return label + `(${_.get(v, ['secondaryKey', meta.group.item === 'creator' ? 'email' : 'id'])})`
    }
    return label
  }

  const chartKeys = meta.group.item === 'all'
    ? [barType]
    : _.uniq(_.map(timeGroups, v => getUniqBarChartLabel(v)))
  const _barData = meta.group.item === 'all'
    ? timeGroups
    : _.chain(timeGroups)
      .groupBy('primaryKey')
      .map((v, key) => {
        return {
          primaryKey: key,
          ..._.reduce(v, (prev, curr) => {
            const id = getUniqBarChartLabel(curr)
            if (id) {
              return { ...prev, [id]: _.get(prev, [id], 0) + _.get(curr, [barType], 0) }
            } else return prev
          }, {})
        }
      })
      .value()
  const barData = _.map(_barData, ({ primaryKey: key, ...v }) => {
    let dateKey = null
    if (key) {
      if (meta.group.time === 'daily') {
        dateKey = key
      } else if (meta.group.time === 'weekly') {
        dateKey = `${parseDateObj(key)} ~ ${parseDateObj(new Date(key).setDate(new Date(key).getDate() + 6))}`
      } else if (meta.group.time === 'monthly') {
        dateKey = parseDateObj(key, 'YYYY-MM')
      } else dateKey = key
    }
    return {
      primaryKey: dateKey,
      ...v
    }
  })
  const label = {
    timesheetCount: '작성 수',
    totalMinutes: '총 시간'
  }

  // 틱, 그리드, 포매터 설정 (for Human-Friendliness)
  const formatMinutesToHours = (totalMinutes = 0, hourUnit = '시간', minUnit = '분') => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return `${hours === 0 ? '' : `${hours}${hourUnit} `}${minutes === 0 ? hours === 0 ? `0${minUnit}` : '' : `${minutes}${minUnit} `}`
  }

  let tickValues
  let gridYValues
  let formatter
  if (barType === 'timesheetCount') {
    const maxDailyTimesheets = _.chain(barData).map(
      item => _.get(item, ['timesheetCount'], 0)
    ).max().value()
    const skip = Math.ceil(maxDailyTimesheets / 10)
    tickValues = _.range(0, maxDailyTimesheets + 1, skip)
    gridYValues = tickValues
    formatter = value => `${Math.floor(value)}개`
  } else if (barType === 'totalMinutes') {
    const maxTotalMinutes = _.chain(barData).map(
      item => _.get(item, ['totalMinutes'], 0)
    ).max().value()

    // y축 총 시간의 시간 간격 표시
    const hours = Math.ceil(maxTotalMinutes / 60)
    const skipValue = Math.ceil(hours / 5)
    const remain = skipValue % 5
    const skip = 60 * (skipValue - remain)

    tickValues = _.range(0, maxTotalMinutes + 1, skip)
    gridYValues = tickValues
    formatter = value => formatMinutesToHours(value)
  }
  // 사건번호 목록 길이 출력
  const barDataLength = _.map(barData, item => _.toPairs(item).length)
  const longestLength = _.max(barDataLength)
  const barGraphProps = {
    margin: {
      top: 0,
      right: meta.group.item === 'all' ? 10 : 220,
      bottom: meta.group.time === 'weekly' ? 125 : 80,
      left: 60
    },
    padding: barData.length > 1 ? 0.2 : 0.6,
    minWidth: (60 * barData.length) + 60 + (meta.group.item === 'all' ? 10 : 240) + 50,
    height: meta.group.item === 'all' ? 320 : meta.group.item !== 'caseNumber' ? 400 : barData.length * longestLength * 2, // 바 높이 조정

    // minValue: -100,
    indexBy: 'primaryKey',
    ...meta.group.item === 'all'
      ? { colors: d => colorScheme[d.index % colorScheme.length] }
      : { colors: { scheme: 'blues' } },
    data: barData,
    colorBy: 'id',
    keys: chartKeys,
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    valueFormat: value => barType === 'totalMinutes' ? formatMinutesToHours(Number(value)) : `${Number(value)}개`,
    tooltip: ({
      id,
      value,
      color
    }) => (
      <div
        style={{
          padding: 12,
          color: meta.group.item === 'all' ? `${COLORS.white}` : fontColorContrast(color),
          background: meta.group.item === 'all' ? '#A7A9AC' : color
        }}
      >
        {meta.group.item === 'all' ? formatter(value) : `${id}: ${formatter(value)}`}
      </div>
    ),
    legends:
      meta.group.item === 'all'
        ? []
        : [
            {
              dataFrom: 'keys',
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 270,
              translateY: 0,
              itemWidth: 250,
              itemHeight: 20,
              itemsSpacing: 2,
              symbolSize: 20,
              itemDirection: 'left-to-right'
            }
          ],
    axisLeft: {
      format: value => formatter(value),
      gridYValues,
      tickValues,
      tickSize: 5,
      tickPadding: 1,
      tickRotation: 0,
      legendPosition: 'middle',
      truncateTickAt: 0
    },
    axisBottom: {
      tickRotation: -50,
      tickPadding: 10
    },
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: v => fontColorContrast(v.color)
  }

  // pie graph
  const getPieUniqLabel = (v, type) => {
    const label = _.get(v, type === 'creator' ? ['user', 'name'] : ['project', 'name'])
    if (_.get(type === 'creator' ? usersByName : casesByCaseNumber, [label], []).length > 1) {
      return label + `(${_.get(v, type === 'creator' ? ['user', 'email'] : ['project', 'id'])})`
    }
    return label
  }
  const creatorId = []
  const caseNumberId = []

  const getPieData = (type) => {
    const groupTimesheets = (key) => {
      return _.reduce(timesheets, (prev, curr) => {
        const groupId = getPieUniqLabel(curr, key)
        if (_.get(prev, groupId)) {
          return {
            ...prev,
            [groupId]: {
              count: prev[groupId].count + 1,
              minutes: prev[groupId].minutes + _.get(curr, ['minutes'], 0)
            }
          }
        }
        return {
          ...prev,
          [groupId]: {
            count: 1,
            minutes: _.get(curr, ['minutes'], 0)
          }
        }
      }, {})
    }
    return _.sortBy(
      _.map(groupTimesheets(type), (v, key) => {
        if (type === 'creator') {
          creatorId.push(key)
        } else if (type === 'caseNumber') {
          caseNumberId.push(key)
        }
        return { id: key, value: v.count / totalCount, ...v }
      }),
      v => -v?.value
    )
  }

  const onChangeBarType = (v) => setBarType(v)
  const creatorData = getPieData('creator')
  const sortedcreatorData = _.orderBy(creatorData, ['minutes'], ['desc'])

  const caseData = getPieData('caseNumber')
  const sortedCaseData = _.orderBy(caseData, ['minutes'], ['inc'])

  const creatorBarGraphProps = {
    margin: {
      top: 50,
      right: meta.group.item === 'all' ? 10 : 220,
      bottom: meta.group.time === 'weekly' ? 125 : 80,
      left: 60
    },
    padding: sortedcreatorData.length > 1 ? 0.2 : 0.6,
    minWidth: (80 * sortedcreatorData.length) + 60 + (meta.group.item === 'all' ? 10 : 240) + 50,
    indexBy: 'id',
    ...meta.group.item === 'all'
      ? { colors: d => colorScheme[d.index % colorScheme.length] }
      : { colors: { scheme: 'blues' } },
    data: sortedcreatorData,
    colorBy: 'id',
    keys: ['minutes'],
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    valueFormat: minutes => formatMinutesToHours(Number(minutes)), // ' >-.1%'
    tooltip: ({
      id,
      value,
      color
    }) => (
      <div
        style={{
          padding: 12,
          color: meta.group.item === 'all' ? `${COLORS.white}` : fontColorContrast(color),
          background: meta.group.item === 'all' ? '#A7A9AC' : color
        }}
      >
        {formatter(value)}
      </div>
    ),
    legends:
      meta.group.item === 'all'
        ? []
        : [
            {
              dataFrom: 'keys',
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 270,
              translateY: 0,
              itemWidth: 250,
              itemHeight: 20,
              itemsSpacing: 2,
              symbolSize: 20,
              itemDirection: 'left-to-right'
            }
          ],
    axisLeft: {
      format: value => formatter(value),
      gridYValues,
      tickValues,
      tickSize: 5,
      tickPadding: 1,
      tickRotation: 0,
      legendPosition: 'middle',
      truncateTickAt: 0
    },
    axisBottom: {
      tickRotation: -50,
      tickPadding: 10
    },
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: v => fontColorContrast(v.color)
  }
  const caseBarGraphProps = {
    data: sortedCaseData,
    layout: 'horizontal',
    margin: {
      top: -5,
      right: meta.group.item === 'all' ? 10 : 220,
      // bottom: meta.group.time === 'weekly' ? 125 : 320,
      left: 350 // TODO: 사건 이름중 제일 긴 값에 대한 설정
    },
    padding: caseData.length > 1 ? 0.1 : 0.8, // 바 페딩
    height: caseData.length * 24, // 바 높이 조정
    minWidth: (1 * caseData.length) + (meta.group.item === 'all' ? 10 : 240),
    indexBy: 'id', // horizontal: y축
    ...meta.group.item === 'all'
      ? { colors: d => colorScheme[d.index % colorScheme.length] }
      : { colors: { scheme: 'blues' } },
    colorBy: 'id',
    keys: ['minutes'], // horizontal: x축
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    valueFormat: minutes => formatMinutesToHours(Number(minutes)),
    tooltip: ({
      id,
      value,
      color
    }) => (
      <div
        style={{
          padding: 12,
          color: meta.group.item === 'all' ? `${COLORS.white}` : fontColorContrast(color),
          background: meta.group.item === 'all' ? '#A7A9AC' : color
        }}
      >
        {formatter(value)}
      </div>
    ),
    legends:
      meta.group.item === 'all'
        ? []
        : [
            {
              dataFrom: 'keys',
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 270,
              translateY: 0,
              itemWidth: 250,
              itemHeight: 20,
              itemsSpacing: 2,
              symbolSize: 20,
              itemDirection: 'left-to-right'
            }
          ],
    axisLeft: {
      tickRotation: 0,
      tickPadding: 10 // 사건 이름
    },
    axisBottom: {
      format: value => formatter(value),
      gridYValues,
      tickValues,
      tickSize: 5,
      tickPadding: 1,
      tickRotation: 0,
      legendPosition: 'middle',
      truncateTickAt: 0
    },
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    // labelPosition: 'start',
    // labelOffset: 14,
    labelTextColor: v => fontColorContrast(v.color)
  }

  return {
    barType,
    onChangeBarType,
    nTimesheetsPerDate: {
      graphType: 'bar',
      graphProps: barGraphProps,
      graphTitle: `날짜별 타임시트 ${label[barType]}`,
      dataType: 'nTimesheetsPerDate'
    },
    ratioOfTimesheetsPerCreators: {
      graphType: 'bar',
      graphProps: creatorBarGraphProps,
      graphTitle: '작성자 별 타임시트 기록 총 시간',
      dataType: 'ratioOfTimesheetsPerCreators'
    },
    ratioOfTimesheetsPerCaseNumbers: {
      graphType: 'bar',
      graphProps: caseBarGraphProps,
      graphTitle: '사건번호 별 타임시트 기록 총 시간',
      dataType: 'ratioOfTimesheetsPerCaseNumbers'
    }
  }
}
