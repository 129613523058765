import React from 'react'

import { TimesheetHeader } from '../components/timesheet/TimesheetHeader'
import { TimesheetCalendar } from '../components/timesheet/TimesheetCalendar'
import { CaseAndTimesheetTable } from '../components/timesheet/CaseAndTimesheetTable'
import { useTimesheetHeader } from '../hooks/timesheet/useTimesheetHeader'
import { TimesheetFinder } from '../components/timesheet/TimesheetFinder'
import { HeaderContentFooter } from '../components/common/HeaderContentFooter'
import { MAX_CONTENT_WIDTH } from '../config'

import styled from 'styled-components'

const CalendarContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  min-width: 800px;
  max-width: 1800px;
  max-height: 2000px;

`
const TableContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  display: flex;
  justify-content: center;
`
const FolderContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
`

const TimesheetContainer = () => {
  const {
    viewMode,
    hiddenKeys,
    showCreateTimesheetButton,
    showViewMyCaseButton,
    onSelectFilter,
    onChangeViewMode
  } = useTimesheetHeader()
  return (
    <HeaderContentFooter
      id='TimesheetContainer'
      totalHeight='100vh'
      header={(
        <TimesheetHeader
          viewMode={viewMode}
          hiddenKeys={hiddenKeys}
          showViewMyCaseButton={showViewMyCaseButton}
          showCreateTimesheetButton={showCreateTimesheetButton}
          onSelectFilter={onSelectFilter}
          onChangeViewMode={onChangeViewMode}
        />
      )}
      content={(
        <>
          {viewMode === 'table'
            ? (
              <TableContainer><CaseAndTimesheetTable /></TableContainer>
              )
            : viewMode === 'calendar'
              ? (
                <CalendarContainer><TimesheetCalendar /></CalendarContainer>
                )
              : (
                <FolderContainer><TimesheetFinder /></FolderContainer>)}
        </>
      )}
    />
  )
}

export default TimesheetContainer
