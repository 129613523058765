import { getWindowHistoryMetaByKey } from '../common'

const getTimesheetFormStatus = () => {
  // 타임시트 작성/수정 indicator
  return getWindowHistoryMetaByKey('timesheetFormStatus', true)
}

export {
  getTimesheetFormStatus
}
