import { RowContainer } from './RowContainer'
import { CustomDragger } from './CustomDragger'

import styled from 'styled-components'
import COLORS from './Theme/colors'
import { Empty, Typography } from 'antd'
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons'
import { Spacer } from './HeaderGroup'
import _ from 'lodash'

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const AttatchFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
`
const FileNameWrapper = styled.div`
  color: ${COLORS.primaryColor};
  cursor: pointer;
  &:hover {
        text-decoration: underline;
    }
`

export const Attatchments = ({ files = [], dragDropProps = {}, readOnly, onDeleteFileByKey, onDownloadAll = () => {}, fileUploadLoading }) => {
  if (_.isEmpty(files)) {
    if (readOnly) {
      return <Empty />
    }
    return (
      <Container>
        <CustomDragger fileUploadLoading={fileUploadLoading} disabled={readOnly} {...dragDropProps} />
      </Container>
    )
  }
  return (
    <Container onDrop={(e) => console.log(e)}>
      {readOnly ? null : <CustomDragger fileUploadLoading={fileUploadLoading} disabled={readOnly} {...dragDropProps} />}
      {_.map(files, props => {
        return (
          <RowContainer key={props.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <AttatchFileWrapper>
              <RowContainer style={{ padding: 4 }}>
                <FileTextOutlined style={{ color: `${COLORS.gray_3}`, marginRight: '5px' }} />
                {readOnly ? <FileNameWrapper onClick={() => onDownloadAll(props)}>{props.originalFilename}</FileNameWrapper> : <Typography.Text>{props.originalFilename}</Typography.Text>}
                <Spacer />
                {readOnly ? null : <DeleteOutlined onClick={() => !readOnly && onDeleteFileByKey(props.id)} style={{ color: `${COLORS.red}`, cursor: 'pointer', marginLeft: '10px' }} />}
              </RowContainer>
            </AttatchFileWrapper>
          </RowContainer>
        )
      })}
    </Container>
  )
}
