import _ from 'lodash'

const navigateToEmployeeDetail = (navigate, employeeMeta = {}, replace) => {
  navigate(`/employeeDetail/${employeeMeta.id}`, {
    state: {
      employeeMeta: JSON.stringify(_.pick(employeeMeta, ['caseNumber', 'caseTag', 'content', 'caseId', 'status', 'attachments']))
    },
    replace
  })
}

export {
  navigateToEmployeeDetail
}
