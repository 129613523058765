import { getWindowHistoryMetaByKey } from '../common'

const getTimesheetMeta = () => {
  // 타임시트 세부정보/타임시트 수정
  return getWindowHistoryMetaByKey('timesheetMeta')
}

export {
  getTimesheetMeta
}
