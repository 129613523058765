import { useQuery } from '@apollo/client'
import { getUserList } from '../../../apollo/settingQueries'
import { useCustomTable } from '../../common/useCustomTable'
import { useEffect, useRef } from 'react'
import _ from 'lodash'
import { unAppointedDepartmentId } from '../../../config'
import { useManageEmployeeTable } from '../../manageCase/table/useManageEmployeeTable'

export const useUserListTable = (departmentIds = [], searchQuery) => {
  const {
    employeeTableColumns
  } = useManageEmployeeTable()

  const parseData = (data, isPreAllocation) => _.map(data,
    v => ({
      ...v,
      key: v.id,
      type: _.get(v, ['group', 'name']),
      ranks: _.map(v?.ranks || [], 'name')?.join(', '),
      ranks2: v?.ranks,
      departments: _.map(v?.departments || [], 'name')?.join(', '),
      departments2: v?.departments
    }))

  const preAllocationTableProps = useCustomTable()
  const {
    page,
    limit,
    onChangePage
  } = preAllocationTableProps
  const filter = {
    notAppointed: _.includes(departmentIds, unAppointedDepartmentId),
    departmentIds,
    includeSelf: true,
    ...searchQuery ? { searchQuery } : {}
  }
  const { data, loading: preAllocationLoading, refetch } = useQuery(getUserList, {
    variables: {
      sortBy: {
        ascending: true
      },
      filter: {
        ...filter,
        includeDeleted: true // 퇴사 직원 목록에서 보이게 설정
      },
      offset: (page - 1) * limit,
      limit
    },
    fetchPolicy: 'no-cache'
  })
  const filteredUsers = _.get(data, ['GetUserList', 'data'], [])
  // page handling
  const prevFilter = useRef(filter)
  useEffect(() => {
    if (!_.isEqual(prevFilter.current, filter)) {
      onChangePage(1)
      prevFilter.current = filter
    }
  }, [filter])

  const preAllocationData = parseData(filteredUsers, true)
  return {
    preAllocationTableProps: {
      ...preAllocationTableProps,
      data: preAllocationData,
      tableActionHidden: true,
      columns: employeeTableColumns,
      style: {
        flex: 1,
        cursor: 'pointer'
      },
      loading: preAllocationLoading,
      total: _.get(data, ['GetUserList', 'totalCount'], 0),
      unit: '명',
      refetch
    },
    loading: preAllocationLoading
  }
}
