import { parseMinutesToHourMin } from '../../../util'

// 사건 테이블
const getTimesheetTableColumns = () => [
  {
    title: '제목',
    dataIndex: 'title',
    key: 'TIMESHEET_NAME',
    sort: true
  },
  {
    title: '작성자',
    dataIndex: 'creator',
    key: 'CREATOR'
  },
  {
    title: '업무실행일',
    dataIndex: 'date',
    key: 'DATE',
    sort: true
  },
  {
    title: '소요시간',
    dataIndex: 'minutes',
    key: 'MINUTES',
    sort: true,
    render: v => <>{parseMinutesToHourMin(v)}</>
  },
  {
    title: '사건번호',
    dataIndex: 'caseNumber',
    key: 'caseNumber'
  },
  {
    title: '작성일',
    dataIndex: 'created',
    isDate: true
  }
]

export {
  getTimesheetTableColumns
}
