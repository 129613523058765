// 타임시트 작성/수정
import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import TimeEntries from '../components/timesheet/TimeEntries'
import { Attatchments } from '../components/common/Attatchments'
import { HeaderContentFooter } from '../components/common/HeaderContentFooter'
import { HeaderContainer, HeaderGroup, HeaderTitle } from '../components/common/HeaderGroup'

import { useTimeEntries } from '../hooks/timesheet/useTimeEntries'
import { useFormAttatchments } from '../hooks/timesheetForm/useFormAttatchments'

import { useQuery } from '@apollo/client'
import { parseTimesheet } from '../apollo/parser'
import { getTimesheetInfoById } from '../apollo/queries'
import { parseDateObj } from '../util'
import { MAX_CONTENT_WIDTH } from '../config'
import { getTimesheetFormStatus } from '../cacheAndNavigation/timesheetForm/cache'
import { navigateToTimesheetDetail } from '../cacheAndNavigation/timesheetDetail/navigation'

import styled from 'styled-components'
import COLORS from '../components/common/Theme/colors'
import { LeftOutlined } from '@ant-design/icons'
import { Skeleton } from 'antd'
import _ from 'lodash'
import { ReadOnlyTimesheet } from '../components/timesheetForm/TimesheetForm/ReadOnlyTimesheet'

const Container = styled.div`
  display: flex;
`
const TimesheetContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  > * {
    max-width: ${MAX_CONTENT_WIDTH}px;
  }
`
const AttatchmentContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: ${COLORS.black_1};
  > * {
    max-width: ${MAX_CONTENT_WIDTH}px;
  }
`

const FooterHeight = 150

const TimesheetEntryContainer = () => {
  const navigate = useNavigate()
  const timesheetRef = useRef()
  const status = getTimesheetFormStatus()

  const displayCache = false
  const { timesheetId, timesheetEntryId } = useParams()
  const { data, loading } = useQuery(getTimesheetInfoById, {
    variables: {
      getTimesheetId: timesheetId
    },
    fetchPolicy: 'network-only',
    skip: displayCache
  })
  const timesheetDetail = _.get(data, ['GetTimesheet'])
  const timesheetMeta = parseTimesheet(timesheetDetail)

  const timeentries = _.reverse(_.sortBy(_.get(timesheetMeta, ['history'], []), v => v.created))
  const initMeta = parseTimesheet(_.find(timeentries, v => v.id === timesheetEntryId))

  const {
    open: timesheetEntryOpen,
    onSelectTimeEntry,
    closeTimeEntries
  } = useTimeEntries(timesheetId)

  const {
    files,
    onDeleteFileByKey,
    dragDropProps
  } = useFormAttatchments(_.get(initMeta, ['attachments'], undefined) || [], loading)
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <HeaderContentFooter
          totalHeight='100vh'
          header={(
            <HeaderContainer>
              <HeaderTitle title={<HeaderGroup style={{ cursor: 'pointer' }} onClick={() => navigateToTimesheetDetail(navigate, timesheetId)}><LeftOutlined />{parseDateObj(_.get(initMeta, ['created']))}</HeaderGroup>} />
            </HeaderContainer>
          )}
          content={(
            <TimesheetContainer>
              {loading
                ? <Skeleton />
                : <ReadOnlyTimesheet
                    ref={timesheetRef}
                    readOnly
                    status={status}
                    initMeta={initMeta}
                  />}
            </TimesheetContainer>
          )}
          footerHeight={FooterHeight}
        />
        <AttatchmentContainer>
          <Attatchments
            onDeleteFileByKey={onDeleteFileByKey}
            dragDropProps={dragDropProps}
            readOnly
            onDownloadAll={() => {}}
            onDeleteAll={() => {}}
            files={files}
          />
        </AttatchmentContainer>
      </div>
      <TimeEntries
        open={timesheetEntryOpen}
        data={timeentries}
        selectedEntryId={timesheetEntryId}
        onClose={closeTimeEntries}
        onSelectEntry={onSelectTimeEntry}
      />
    </Container>
  )
}

export default TimesheetEntryContainer
