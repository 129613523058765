import styled from 'styled-components'
import { Menu } from 'antd'
import Sider from 'antd/es/layout/Sider'
import COLORS from '../common/Theme/colors'
import { useOutletContext } from 'react-router-dom'

const StyledMenu = styled(Menu)`
  .ant-menu-item-selected {
    background-color: #F0F6FF;
    font-weight: bold;
    color: ${COLORS.primaryColor};
  }
`

export const SettingSidebar = ({ selectedKey, onSelectSettingSidebarMenu, sidebarItems }) => {
  const { permissions } = useOutletContext()
  const sidebarItemsDisabled = sidebarItems.map(item => ({
    key: item.key,
    label: item.label,
    disabled: item.disable && !permissions.includes(item.disable)
  }))
  return (
    <Sider
      width={180}
      style={{
        backgroundColor: `${COLORS.white_2}`,
        height: '100vh'
      }}
    >
      <StyledMenu
        mode='inline'
        style={{
          borderRight: 0,
          background: `${COLORS.white_3}`
        }}
        onSelect={onSelectSettingSidebarMenu}
        items={sidebarItemsDisabled}
      />
    </Sider>
  )
}
