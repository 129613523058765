import { useState } from 'react'
import { sleep } from '../../../util'
import styled from 'styled-components'
import { Divider, Input, Select } from 'antd'

const InputContainer = styled.div`
  width: 100%;
`
const SearchSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-select-selector input {
    font-weight: bold;
    font-size: 30px;
    top: 20px;
  }
  .ant-select-selection-item {
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
  }
  .ant-select-dropdown {
    z-index: 1;
  }
  .ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 20px;
    inset-inline-start: 11px;
    inset-inline-end: 11px;
    bottom: 0;
}
.ant-input-outlined:hover {
  border:none;
  box-shadow: none;
}
.ant-select-selector {
  border: none;
  box-shadow: none;
}
`
const StyeldInput = styled(Input)`
  font-size: 30px;
  border: none;
`

export const TitleSelector = ({ items = [], value, onChangeInput, readOnly }) => {
  const [inputValue, setInputValue] = useState(value || '')
  const [showInput, setShowInput] = useState(true)

  const searchSelectProps = {
    showSearch: true,
    filterOption: (input, option) => {
      return (option?.value ?? '').includes(input)
    },
    filterSort: (optionA, optionB) => {
      if (optionA.value === 'no-template') return -1
      else if (optionB.value === 'no-template') return 1
      return (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())
    }
  }

  if (readOnly) {
    return (
      <InputContainer>
        <StyeldInput
          contentEditable={!readOnly}
          suppressContentEditableWarning
          readOnly={readOnly}
          size='large'
          autoFocus
          value={value}
        />
        <Divider style={{ margin: 0 }} />
      </InputContainer>
    )
  }
  if (showInput) {
    return (
      <InputContainer>
        <StyeldInput
          placeholder='제목을 선택해 주세요.'
          value={value}
          onClick={() => {
            if (!value) setShowInput(false)
          }}
          onChange={(e) => {
            setInputValue(e.target.value)
            onChangeInput(e.target.value)
            if (!e.target.value) setShowInput(false)
          }}
        />
        <Divider style={{ margin: 0 }} />
      </InputContainer>
    )
  }
  return (
    <SearchSelectContainer>
      <Select
        autoFocus={!readOnly}
        placeholder='제목을 선택해 주세요.'
        size='large'
        open={!showInput}
        readOnly={readOnly}
        value={value}
        {...readOnly ? { open: false } : {}}
        options={items.map(v => ({ label: v, value: v }))}
        width='100%'
        style={{ height: 75 }}
        contentEditable={!readOnly}
        suppressContentEditableWarning
        {...searchSelectProps}
        onSearch={e => setInputValue(e)}
        onDropdownVisibleChange={(open) => {
          if (!open) {
            onChangeInput(inputValue)
          }
          setShowInput(!open)
        }}
        onSelect={v => {
          sleep(10).then(() => {
            onChangeInput(v)
            setInputValue(v)
          })
        }}
      />
      <Divider style={{ margin: 0 }} />
    </SearchSelectContainer>
  )
}
