import { useState } from 'react'
import { RowContainer } from '../common/RowContainer'
import { Form, Button, Select } from 'antd'

const EmployeeRankForm = ({ ranks, onSave, onCancel, initialValues = {} }) => {
  const [disabled, setDisabled] = useState(true)
  const handleChange = (value) => {
    if (value !== undefined) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }
  return (
    <Form
      onFinish={v => onSave(v)}
      style={{ width: '800px' }}
      initialValues={initialValues}
    >
      <Form.Item
        label='직급'
        name='rankIds'
      >
        <Select
          placeholder='직급을 선택해주세요.'
          onChange={handleChange}
          options={ranks}
          allowClear
        />
      </Form.Item>
      <Form.Item>
        <RowContainer style={{ gap: '10px', justifyContent: 'right' }}>
          <Button onClick={onCancel}>
            취소하기
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabled}
          >
            등록하기
          </Button>
        </RowContainer>
      </Form.Item>
    </Form>
  )
}

export default EmployeeRankForm
