import _ from 'lodash'

import { getCaseList } from '../../../apollo/deletedCaseListQueries'
import { useQuery } from '@apollo/client'

const parseCaseList = (caseList) => {
  return _.map(caseList, v => {
    return {
      ...v,
      key: _.get(v, ['id']),
      category: _.get(v, ['type', 'name'], null),
      category2: _.get(v, ['category'], null),
      caseNumber: _.get(v, ['name']),
      updated: _.get(v, ['updated'], null),
      caseType: _.get(v, ['type']),
      totalMinutes: _.get(v, ['totalMinutes'], 0),
      totalDeltaMinutes: _.get(v, ['totalDeltaMinutes'], 0),
      totalMinutesAfterEdit: _.get(v, ['totalMinutes'], 0) + _.get(v, ['totalDeltaMinutes'], 0)
    }
  })
}

export const useDeletedCaseList = ({
  categories = [],
  page,
  limit,
  sortKey,
  sortAscending = false,
  closed,
  requiresAllocation,
  permissions,
  deleted,
  caseSearchQuery,
  caseTableSelectedRowKeys
}) => {
  const variables = {
    sortBy: {
      ascending: sortAscending,
      ...sortKey ? { key: sortKey } : {}
    },
    filter: {
      closed,
      deleted,
      ...caseSearchQuery ? { searchQuery: caseSearchQuery } : {},
      ..._.isEmpty(categories) ? { } : { categories },
      ...requiresAllocation // 배당/위임 or 위임 필터가 켜졌을 경우
        ? _.includes(permissions, 'ALLOCATE_OR_DELEGATE') // 권한: 배당/위임에 따른 목록 반환
          ? { allocated: false, delegated: false }
          : _.includes(permissions, 'ALLOW_ALLOCATION')
            ? { allocated: false } // 권한: 위임에 따른 목록 반환
            : { }
        : { }
    },
    ...page && (limit || limit === 0)
      ? {
          offset: (page - 1) * limit,
          limit
        }
      : limit === null
        ? {
            limit
          }
        : {}
  }

  const {
    data,
    loading,
    refetch
  } = useQuery(getCaseList, {
    variables,
    fetchPolicy: 'no-cache'
  })
  const gqlCaseListData = _.get(data, ['GetProjectList'], {})
  const cases = parseCaseList(_.get(gqlCaseListData, ['data'], []))
  const totalCount = _.get(gqlCaseListData, ['totalCount'], 0)
  return {
    cases,
    totalCount,
    loading,
    refetch,
    variables
  }
}
