import { getWindowHistoryMetaByKey } from '../common'

const getCaseContentViewMode = () => {
  return getWindowHistoryMetaByKey('viewMode', true) || 'calendar'
}
const getShowCaseTable = () => {
  return getWindowHistoryMetaByKey('showCaseTable', true)
}
const getCaseListStatus = () => {
  return getWindowHistoryMetaByKey('caseListStatus', true)
}

export {
  getShowCaseTable,
  getCaseListStatus,
  getCaseContentViewMode
}
