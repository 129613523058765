import _ from 'lodash'

const navigateToTimesheetDetail = (navigate, timesheetId, timesheetMeta = {}, replace = false) => {
  // timesheetMeta
  // {
  //     key: 'timsheetID123',
  //     attachments: [],
  //     "title": "제목2",
  //     "updated": "2024-01-17T02:13:16.364Z",
  //     "caseId": "사건번호 1",
  //     "userId": "홍길동",
  //     "minutes": 662
  // }
  navigate(`/timesheetDetail/${timesheetId}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      timesheetFormStatus: null,
      timesheetMeta: JSON.stringify({ ...timesheetMeta, key: timesheetId })
    },
    replace
  })
}

const navigateToTimesheetEntry = (navigate, timesheetId, timesheetEntryId) => {
  // timesheetMeta
  // {
  //     key: 'timsheetID123',
  //     attachments: [],
  //     "title": "제목2",
  //     "updated": "2024-01-17T02:13:16.364Z",
  //     "caseId": "사건번호 1",
  //     "userId": "홍길동",
  //     "minutes": 662
  // }
  navigate(`/timesheetEntry/${timesheetId}/${timesheetEntryId}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      timesheetFormStatus: null
    }
  })
}

export {
  navigateToTimesheetDetail,
  navigateToTimesheetEntry
}
