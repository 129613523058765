import { useNavigate } from 'react-router-dom'
import { navigateToCaseListTable } from '../../cacheAndNavigation/timesheet/navigation'

import styled from 'styled-components'
import COLORS from '../common/Theme/colors'
import { Button } from 'antd'
import _ from 'lodash'

const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`
const UserInfoRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  font-size: 15px;
  
`
const InfoContainer = styled.div`
  border-bottom: 1px solid ${COLORS.primaryColor30};
  padding-left: 5px;
  width: 100%;
`
const TitleContainer = styled.div`
  width: 310px;
  background: ${COLORS.primaryColor10};
  padding: 5px 10px;
  border-radius: 7px;
  white-space: nowrap;
`

export const UserDetail = ({ user, caseId }) => {
  const navigate = useNavigate()
  const commonItems = [
    {
      title: '이름',
      dataIndex: 'name'
    },
    {
      title: '이메일',
      dataIndex: 'email'
    },
    {
      title: '권한그룹',
      dataIndex: 'type'
    },
    {
      title: '부서',
      dataIndex: 'departments'
    }
  ]

  const specificItems = [
    {
      title: '이 사건에서 타임시트 작성 수',
      dataIndex: 'caseTimesheetCount'
    }
  ]

  return (
    <Container>
      {/* (공통 사용자 정보) */}
      {_.map(commonItems, ({ dataIndex, title }) => {
        return (
          <UserInfoRowContainer key={dataIndex}>
            <TitleContainer>
              {title}
            </TitleContainer>
            <InfoContainer>
              {_.get(user, [dataIndex])}
            </InfoContainer>
          </UserInfoRowContainer>
        )
      })}
      {/* (사건에서의 사용자 정보) */}
      {_.map(specificItems, ({ dataIndex, title }) => {
        return (
          <UserInfoRowContainer key={dataIndex}>
            <TitleContainer>
              {title}
            </TitleContainer>
            <InfoContainer>
              {_.get(user, [dataIndex])}
            </InfoContainer>
          </UserInfoRowContainer>
        )
      })}
      <Button
        onClick={() => {
          navigateToCaseListTable(navigate, 'all', caseId, user.id, false)
        }}
      >
        타임시트 보러가기
      </Button>
    </Container>
  )
}
