import styled from 'styled-components'
import { Tag } from 'antd'
import COLORS from '../../common/Theme/colors'

const StyedTag = styled(Tag)`
  color: ${COLORS.primaryColor};
  border: 0.5px solid ${COLORS.primaryColor};
  border-radius: 30px;
  font-weight: 500;
  gap: 5px;
  padding: 5px 10px;
  background: ${COLORS.white};
`
export const BecomesTagOnSelect = ({ tagged, onSelect, onClose, name, icon, children }) => {
  return (
    <>
      {tagged
        ? (
          <>
            {icon}
            <StyedTag closable onClose={onClose}>{name}</StyedTag>
          </>
          )
        : (
          <div onClick={onSelect} style={{ display: 'flex', gap: '5px' }}>
            {children}
          </div>)}
    </>
  )
}