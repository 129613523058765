import { useEffect, useState } from 'react'
import { useBlocker } from 'react-router-dom'

export const useBlockModal = (disabled = false) => {
  const [fetching, setFetching] = useState(false)
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => !disabled && !fetching && mounted && currentLocation.pathname !== nextLocation.pathname
  )
  const blockModalOpen = blocker.state === 'blocked'

  const onOkCancelModal = () => {
    blocker.proceed && blocker.proceed()
  }
  const onCloseCancelModal = () => {
    blocker.reset && blocker.reset()
  }
  // disable blocker while fetch and nav
  const onFetchAndNav = async (
    fetch,
    handleNav = () => {},
    handleError = () => {}
  ) => {
    setFetching(true)
    try {
      await fetch().then(
        (v) => {
          setFetching(fetching => {
            handleNav(v)
            return false
          })
        }
      )
    } catch (e) {
      handleError(e)
      setFetching(false)
    }
  }
  return {
    fetching,
    blockModalOpen,
    onOkCancelModal,
    onCloseCancelModal,
    onFetchAndNav
  }
}
