import { parseDateObj } from '../../util'
import { RowContainer } from '../common/RowContainer'

import styled from 'styled-components'
import COLORS from '../common/Theme/colors'
import { Tag, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import _ from 'lodash'

const StyledDrawer = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: aliceblue;
  .ant-drawer-body {
    padding:0;
  }
`
const TimesheetEntryItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: ${(props) => props.$index % 2 ? `${COLORS.white}` : 'aliceblue'};
  padding: 30px 25px;
  border: 1.8px solid ${(props) => props.$selected ? `${COLORS.primaryColor50}` : `${COLORS.white}`};
  cursor: pointer;
`
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`

const TimeEntries = ({ open, onClose, data = [], selectedEntryId, onSelectEntry = () => {} }) => {
  return (
    <>
      <StyledDrawer title='수정 연혁' onClose={onClose} open={open}>
        {_.map(data, (v, index) => {
          const selected = selectedEntryId === _.get(v, ['id'], '???')
          return (
            <TimesheetEntryItem key={index} $selected={selected} onClick={() => onSelectEntry(_.get(v, ['id']), index)} $index={index}>
              <RowContainer style={{ gap: '20px' }}>
                <RightOutlined />
                <ColumnContainer>
                  <RowContainer style={{ justifyContent: 'space-between' }}>
                    <div>{_.get(v, ['created'], false) ? parseDateObj(_.get(v, ['created'], undefined)) : '???'}</div>
                    {index === 0 ? <Tag color='geekblue'>최신버전</Tag> : null}
                  </RowContainer>
                  <RowContainer style={{ gap: '5px' }}>수정한 사람: <Typography.Text strong>{_.get(v, ['createdBy', 'name'], '???')}</Typography.Text></RowContainer>
                </ColumnContainer>
              </RowContainer>
            </TimesheetEntryItem>
          )
        })}
      </StyledDrawer>
    </>
  )
}

export default TimeEntries
