// 로그인
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginForm from '../components/login/LoginForm'

import styled from 'styled-components'
import COLORS from '../components/common/Theme/colors'
import { Typography } from 'antd'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  padding: 20px;
  gap: 20px;
`
const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  justify-content: center;
  background: ${COLORS.white};
  padding-top: 50px;
  padding-bottom: 50px;
`
const LoginScreen = ({ loggedIn, onLogin, onChangePassword, loading, authenticationStatus }) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [])

  return (
    <Container>
      {loggedIn
        ? (
          <>
            <Typography.Title level={3}>비밀번호 변경</Typography.Title>
            <DescriptionContainer>
              임시 비밀번호 입니다. 회원님의 개인정보를 안전하게 보호하기 위해, 비밀번호를 변경해 주시기 바랍니다.
            </DescriptionContainer>
          </>)
        : <img src='/logo.png' alt='fireSpot' style={{ marginBottom: 30, width: 280 }} />}
      <LoginForm
        loggedIn={loggedIn}
        onLogin={onLogin}
        onChangePassword={onChangePassword}
        loading={loading}
        authenticationStatus={authenticationStatus}
      />
    </Container>
  )
}
export default LoginScreen
