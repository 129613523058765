import { gql } from '@apollo/client'

// 진행사건 목록 뷰 쿼리
export const getCaseList = gql`
   query GetProjectList($sortBy: SortBy!, $filter: ProjectListFilter, $offset: Int, $limit: Int) {
    GetProjectList(sortBy: $sortBy, offset: $offset, filter: $filter, limit: $limit) {
      totalCount
      totalMinutes
      ... on GetProjectWithTimeDeltaListResponse {
        totalDeltaMinutes
        data {
          id
          name
          starred
          totalMinutes
          totalDeltaMinutes
          category
          updated
          status
          timesheetUpdated
          created
          createdBy {
            id
            name
          }
          delegates {
            id
            name
          }
          type {
            id
            name
          }
          users {
            id
            status
            relation
            group {
              name
            }
            timesheets {
              # date
              # title
              # minutes
              # id
              # project {
              #   name
              # }
              # user {
              #   name
              # }
              history {
                created
              }
            }
            name
            totalMinutes
            totalDeltaMinutes
          }
        }
      }
      ... on GetProjectListResponse {
        data {
          id
          name
          starred
          totalMinutes
          category
          updated
          status
          timesheetUpdated
          created
          createdBy {
            id
            name
          }
          delegates {
            id
            name
          }
          type {
            id
            name
          }
          users {
            id
            status
            relation
            group {
              name
            }
            timesheets {
              # date
              # title
              # minutes
              # id
              # project {
              #   name
              # }
              # user {
              #   name
              # }
              history {
                created
              }
            }
            name
            totalMinutes
          }
        }
      }
    }
  }
`

export const getTimesheetList = gql`
  query GetTimesheetList($sortBy: SortBy!, $filter: TimesheetListFilter, $offset: Int, $limit: Int) {
    GetTimesheetList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      timesheets {
        id
        minutes
        title
        date
        created
        user {
          id
          name
        }
        project {
          id
          name
        }
        createdBy {
          id
          name
        }
      }
      totalMinutes
      totalCount
    }
  }
`
