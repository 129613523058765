import { BarChartOutlined, DownloadOutlined, RetweetOutlined, TableOutlined, UploadOutlined } from '@ant-design/icons'
import COLORS from '../Theme/colors'

export const tableActionItems = [
  {
    key: 'stat',
    icon: <BarChartOutlined style={{ color: `${COLORS.green}` }} />,
    label: '통계보기'
  },
  {
    key: 'excelUpload',
    icon: <UploadOutlined style={{ color: `${COLORS.purple}` }} />,
    label: '엑셀 업로드'
  },
  {
    key: 'excelDownload',
    icon: <DownloadOutlined style={{ color: `${COLORS.primaryColor}` }} />,
    label: '엑셀 다운로드'
  },
  {
    key: 'templateDownload',
    icon: <TableOutlined style={{ color: `${COLORS.pink_1}` }} />,
    label: '템플릿 다운로드'
  },
  {
    key: 'tossCase',
    icon: <RetweetOutlined style={{ color: `${COLORS.orange}` }} />,
    label: '담당자 일괄 배정'
  }
]
