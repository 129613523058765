import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { parseDateObj } from '../../../util'
export const getUpdatedSearchParams = (type, key, value, sp) => {
  if (_.isArray(value)) {
    sp.delete(`${type}_${key}`)
    value.forEach(v => {
      if (type === 'filter' && key === 'datetimeRange') {
        sp.append(`${type}_${key}`, parseDateObj(v, 'YYYY-MM-DD HH:MM'))
      } else {
        sp.append(`${type}_${key}`, v)
      }
    })
  } else {
    sp.set(`${type}_${key}`, value)
  }
  return sp
}
const defSearchParams = {
  group_time: 'all', // monthly, daily, weekly, all
  group_item: 'all', // creator, caseNumber, category, all
  filter_datetimeRange: [],
  filter_userIds: [],
  filter_caseIds: [],
  filter_caseTypeIds: [], // 사건분류 목록 (이혼, 형사)
  filter_categories: [], // 사건 카테고리 (자문, 기타)
  filter_caseStatus: [], // CLOSE, OPEN
  folder_view: 'case'
}
export const useCaseUserFilterSearchParams = (initParams = defSearchParams) => {
  const [searchParams, setSearchParams] = useSearchParams(initParams)
  const onChangeMeta = (type, key, value) => {
    const newSearchParams = getUpdatedSearchParams(type, key, value, searchParams)
    setSearchParams(newSearchParams)
  }
  const onRemoveItemFromFilter = (key, value) => {
    onChangeMeta('filter', value, _.filter(meta.filter[key], v => v !== value))
  }
  const onResetFilterAndGroup = () => {
    setSearchParams(defSearchParams)
  }

  // parsing searchParams to meta
  const meta = {
    group: {
      time: 'all', // monthly, daily, weekly, all
      item: 'all' // creator, caseNumber, category, all
    },
    filter: {
      datetimeRange: [],
      userIds: [],
      caseIds: [],
      caseTypeIds: [],
      categories: [],
      caseStatus: [],
      viewMyCase: null
    },
    folder: {
      view: 'case' // case, user, timesheet
    }
  }
  const a = ['group', 'filter', 'folder']
  const b = ['time', 'item']
  const c = ['userIds', 'datetimeRange', 'caseIds', 'caseTypeIds', 'categories', 'caseStatus']
  const d = ['view']
  const e = ['viewMyCase']
  a.forEach(v1 => {
    if (v1 === 'group') {
      b.forEach(v2 => {
        meta[v1][v2] = searchParams.get(`${v1}_${v2}`)
      })
    } else if (v1 === 'folder') {
      d.forEach(v2 => {
        meta[v1][v2] = searchParams.get(`${v1}_${v2}`)
      })
    } else {
      c.forEach(v2 => {
        meta[v1][v2] = [...searchParams.getAll(`${v1}_${v2}`)]
      })
      e.forEach(v2 => {
        meta[v1][v2] = JSON.parse(searchParams.get(`${v1}_${v2}`))
      })
    }
  })
  return {
    meta,
    searchParams,
    setSearchParams,
    getUpdatedSearchParams,
    onChangeMeta,
    onResetFilterAndGroup,
    onRemoveItemFromFilter
  }
}
