import { InputNumber } from 'antd'

import { getHoursMinsFromMinutes, parseMinutesToHourMin } from '../../util'
import { CustomReadOnlyInput } from './CustomReadOnlyInput'

export const MinutesSetter = ({ readOnly, disabled, minutes: totalMinutes = 0, onChange = () => {} }) => {
  const { hours, minutes } = getHoursMinsFromMinutes(totalMinutes)
  if (readOnly) {
    return (
      <CustomReadOnlyInput value={parseMinutesToHourMin(totalMinutes)} />
    )
  }
  return (
    <>
      <InputNumber
        disabled={disabled}
        min={0}
        value={hours}
        addonAfter='시간'
        onChange={(h) => onChange(h * 60 + minutes)}
      />
      <InputNumber
        disabled={disabled}
        min={0}
        max={59}
        value={minutes}
        addonAfter='분'
        onChange={(m) => onChange(hours * 60 + m)}
      />
    </>
  )
}
