import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'

export const useSettingSidebar = () => {
  const sidebarItems = [
    {
      key: 'setting-caseTitle',
      label: '사건분류 관리',
      disable: 'MANAGE_PROJECT_CATEGORY'
    },
    {
      key: 'setting-timesheetTitle',
      label: '타임시트 제목 관리',
      disable: 'MANAGE_TIMESHEET_NAME'
    },
    {
      key: 'setting-groupAuthority',
      label: '권한그룹 관리',
      disable: ''
    },
    {
      key: 'setting-employersAuthority',
      label: '직원 관리',
      disable: 'MANAGE_USERS'
    },
    {
      key: 'setting-department',
      label: '부서 관리',
      disable: 'MANAGE_DEPARTMENTS'
    },
    {
      key: 'setting-rank',
      label: '직급 관리',
      disable: ''
    }
  ]
  const navigate = useNavigate()
  const location = useLocation()
  const history = location.pathname.split('/').slice(1)

  const onSelectSettingSidebarMenu = (e) => {
    if (e.key === 'setting-timesheetTitle') {
      navigate(`/setting/${e.key.split('-')[1]}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    } else if (e.key === 'setting-caseTitle') {
      navigate(`/setting/${e.key.split('-')[1]}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    } else if (e.key === 'setting-rank') {
      navigate(`/setting/${e.key.split('-')[1]}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    } else if (e.key === 'setting-groupAuthority') {
      navigate(`/setting/${e.key.split('-')[1]}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    } else if (e.key === 'setting-department') {
      navigate(`/setting/${e.key.split('-')[1]}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    } else {
      navigate(`/setting/${e.key.split('-')[1]}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    }
  }
  let selectedKey = null
  if (history[1] === 'timesheetTitle') {
    selectedKey = 'timesheetTitle'
  } else if (history[1] === 'caseTitle') {
    selectedKey = 'caseTitle'
  } else if (history[1] === 'rank') {
    selectedKey = 'rank'
  } else if (history[1] === 'groupAuthority') {
    selectedKey = 'groupAuthority'
  } else if (history[1] === 'department') {
    selectedKey = 'department'
  } else {
    selectedKey = 'employersAuthority'
  }
  return {
    selectedKey,
    sidebarItems,
    onSelectSettingSidebarMenu
  }
}
