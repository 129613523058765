import { Button } from 'antd'
import { HeaderGroup } from '../common/HeaderGroup'

export const FormContainerFooter = ({ fetching, onCancel = () => {}, onSave = () => {}, isEditing, isRegistering }) => {
  return (
    <HeaderGroup>
      <Button onClick={onCancel}>취소하기</Button>
      <Button type='primary' loading={fetching} onClick={onSave}>{isEditing ? '저장하기' : isRegistering ? '등록하기' : ''}</Button>
    </HeaderGroup>
  )
}
