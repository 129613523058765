import { useNavigate, useOutletContext } from 'react-router-dom'
import { useProjectTypeAddEdit } from '../useProjectTypeAddEdit'
import { MESSAGES } from '../../../config'
import { removeProjectType } from '../../../apollo/mutations'
import { useCategories } from '../../apollo/useCategories'
import { useMutation } from '@apollo/client'
import _ from 'lodash'

export const useSaveDeleteProjectTypeForm = (onFetchAndNav, onError = () => {}, refetch = () => {}) => {
  const navigate = useNavigate()
  const { messageApi } = useOutletContext()
  const { categories } = useCategories()
  const projectTypeLength = _.map(categories, ({ ...v }) => ({ key: v.id, ...v })).length + 1
  const {
    onAddProjectType
  } = useProjectTypeAddEdit(() => navigate('/setting/caseTitle', { replace: true }))
  const onSave = (v) => {
    onFetchAndNav(
      () => onAddProjectType(
        v.color.toHexString(),
        _.get(v, ['name']),
        projectTypeLength
      ),
      (v) => {

      },
      () => {
        messageApi.open(MESSAGES.projectTypeAddError)
      }
    )
  }
  const [handleRemoveProjectType] = useMutation(removeProjectType)
  const onRemoveProjectType = (removeProjectTypeId) => {
    handleRemoveProjectType({
      variables: {
        removeProjectTypeId
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveProjectType', 'ok'])) refetch(removeProjectTypeId)
      else {
        messageApi.open(MESSAGES.projectTypeDeleteError)
        onError()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.projectTypeDeleteError)
      onError()
    })
  }
  return {
    onSave,
    onRemoveProjectType
  }
}
