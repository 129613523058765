import { Modal, Result, message } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

import AlignedForm from '../common/AlignedForm'
import { changePassword } from '../../auth/authFetchers'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const TriggerContainer = styled.div`
`

export const PasswordResetModalContent = ({ setLoginMeta }) => {
  return (
    <Container>
      <Result
        status='warning'
        title='비밀번호 재설정'
        extra={[
          <AlignedForm
            key='FORM'
            items={[
              {
                key: 'currentPassword',
                type: 'input',
                label: '현재 비밀번호',
                onChange: v => setLoginMeta(loginMeta => ({ ...loginMeta, currentPassword: v }))
              },
              {
                key: 'newPassword',
                type: 'input',
                label: '새 비밀번호',
                onChange: v => setLoginMeta(loginMeta => ({ ...loginMeta, newPassword: v }))
              },
              {
                key: 'confirmNewPassword',
                type: 'input',
                label: '새 비밀번호 확인',
                onChange: v => setLoginMeta(loginMeta => ({ ...loginMeta, confirmNewPassword: v }))
              }
            ]}
          />
        ]}
      />
    </Container>
  )
}

export const PasswordResetModal = ({ onOk }) => {
  const [loginMeta, setLoginMeta] = useState({
    currentPassword: null,
    newPassword: null,
    confirmNewPassword: null
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    if (loginMeta.newPassword !== loginMeta.confirmNewPassword) {
      message.destroy()
      message.error('비밀번호 불일치.')
    } else if (!loginMeta.newPassword || !loginMeta.confirmNewPassword) {
      message.destroy()
      message.error('비밀번호 값을 설정하세요.')
    } else {
      changePassword(loginMeta.confirmNewPassword).then(() => {
        setIsModalOpen(false)
      }).catch(e => {
        message.error('비밀번호 변경 실패')
      })
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='확인'
        cancelText='취소하기'
      >
        <PasswordResetModalContent setLoginMeta={setLoginMeta} />
      </Modal>
      <TriggerContainer onClick={showModal}>비밀번호 재설정</TriggerContainer>
    </>
  )
}

