import { useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import UserIcon from '../../components/common/UserIcon'
import LeaveTag from '../../components/common/Tags/LeaveTag'
import AlignedForm from '../../components/common/AlignedForm'
import { UserDetail } from '../../components/manageCase/UserDetail'
import { RowContainer } from '../../components/common/RowContainer'
import { Attatchments } from '../../components/common/Attatchments'
import { ConfirmModal } from '../../components/common/ConfirmModal'
import { useConfirmModal } from '../../hooks/manageCase/table/useConfirmModal'
import { HeaderContentFooter } from '../../components/common/HeaderContentFooter'
import { HeaderContainer, HeaderGroup, HeaderTitle, Spacer } from '../../components/common/HeaderGroup'
import { navigateToCaseForm } from '../../cacheAndNavigation/caseForm/navigation'
import { MAX_CONTENT_WIDTH, korNameByCategory } from '../../config'

import { useReadOnlyCaseForm } from '../../hooks/manageCase/case/useReadOnlyCaseForm'
import { useOpenCloseDeleteCases } from '../../hooks/apollo/mutations/useOpenCloseDeleteCases'
import { useFormAttatchments } from '../../hooks/timesheetForm/useFormAttatchments'

import styled from 'styled-components'
import { Button, Popover } from 'antd'
import COLORS from '../../components/common/Theme/colors'
import { DeleteOutlined, EditOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons'

import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { parseUser } from '../../apollo/parser'
import { getCaseById, getCurrentUser } from '../../apollo/queries'
import { MaxHeightScrollContainer } from '../../components/ScrollContainer'
import { downloadFile } from '../../util'

const UsersListContainer = styled.div`
  display: flex;
  width: 160px;
  flex-direction: column;
  gap: 25px;
  padding: 0 20px;
  border-left-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  height: 100%;
  margin-right: 10px;
`

const StyledPopover = styled(Popover)`
  display: flex;
  border-radius: 6px;
  width: 128px;
  box-shadow: none;
  border-width: 0px;
  cursor: pointer;
  .popover {
    padding: 0px;
    width: 128px;

  }
  .ant-popover{
    padding: 0px;
  }
  .ant-popover-inner {
    background-color: aliceblue;
  }

`
const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  > * {
    max-width: ${MAX_CONTENT_WIDTH}px;
  }
`
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
`
const StyledButton = styled.div`
  display: flex;
  background-color: ${COLORS.orange};
  padding: 8px 20px;
  border-radius: 6px;
  color: ${COLORS.white};
  font-size: 14px;
  border: 1px solid ${COLORS.orange};
  cursor: pointer;
  &:hover {
    border-color: 1px solid ${COLORS.orange};
    background-color: ${COLORS.white};
    color: ${COLORS.orange};
  }
  word-wrap: break-word;
  word-break: break-word;
`
const StyledNameButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 10px;
  cursor: pointer;
`
const AllocateTag = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 6px;
  background-color: ${COLORS.white_2};
  padding: 4px 45px;
`
const AttatchmentContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: ${COLORS.black_1};
  > * {
    max-width: 800px;
  }
`
const FooterHeight = 300

const UserList = ({ allocatedUsers = [], delegatedUsers = [], removedDeallocated = [], navigate, caseId, allowedToDelegate, initMeta }) => {
  const [isHovered, setIsHovered] = useState(false)
  const ButtonComponent = (
    <StyledButton
      onMouseEnter={() => setIsHovered(!isHovered)}
      onMouseLeave={() => setIsHovered(!isHovered)}
      onClick={() => initMeta.status !== 'DELETED' && allowedToDelegate && navigate(`/manageCase/userAllocation/${caseId}`)}
    >
      {/* (기능): 사건세부정보 > 직원추가 버튼 > 사건에 위임되어도 표시되도록 수정 */}
      {/* 기타 경우 배당 뜨지 않게 */}
      {isHovered && allowedToDelegate && initMeta.status !== 'DELETED'
        ? (
          <>
            <PlusOutlined style={{ paddingRight: '10px' }} />
            <span style={{ whiteSpace: 'nowrap' }}>담당자 추가</span>
          </>
          )
        : (
          <>
            <UserOutlined style={{ paddingRight: '10px' }} />
            <span style={{ whiteSpace: 'nowrap' }}>담당자 {removedDeallocated.length}명</span>
          </>
          )}
    </StyledButton>
  )

  if (_.size(delegatedUsers) === 0 && _.size(removedDeallocated) === 0) {
    return (
      <UsersListContainer>{ButtonComponent}</UsersListContainer>
    )
  }

  return (
    <UsersListContainer>
      <StyledPopover
        placement='bottom'
        arrow={false}
        content={
          <MaxHeightScrollContainer $maxHeight='600px'>
            {_.isEmpty(delegatedUsers) ? null : <AllocateTag>위임</AllocateTag>}
            {
              delegatedUsers.map((item, index) => {
                return (
                  <Popover placement='right' key={item.id} content={<UserDetail user={parseUser(item, caseId)} caseId={caseId} />}>
                    <StyledNameButton key={item.id}>
                      <UserIcon group={item?.group?.name} name={item?.name} />
                      {item.status === 'DELETED' ? <LeaveTag /> : null}
                    </StyledNameButton>
                  </Popover>
                )
              })
            }
            {_.isEmpty(removedDeallocated) ? null : <AllocateTag>배당</AllocateTag>}
            {
              removedDeallocated.map((item, index) => {
                return (
                  <Popover placement='right' key={item.id} content={<UserDetail user={parseUser(item, caseId)} caseId={caseId} />}>
                    <StyledNameButton key={item.id}>
                      <UserIcon group={item?.group?.name} name={item?.name} />
                      {item.status === 'DELETED' ? <LeaveTag /> : null}
                    </StyledNameButton>
                  </Popover>
                )
              })
            }
          </MaxHeightScrollContainer>
        }
      >
        {ButtonComponent}
      </StyledPopover>
    </UsersListContainer>
  )
}

export const CaseDetailContainer = () => {
  const navigate = useNavigate()
  const { caseId } = useParams()
  const { data: currentUserData } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only'
  })
  const currentUser = _.get(currentUserData, ['CurrentUser'])
  const { data, loading } = useQuery(getCaseById, {
    variables: {
      getProjectId: caseId
    },
    fetchPolicy: 'no-cache'
  })
  const { permissions } = useOutletContext()
  const project = _.get(data, ['GetProject'], {})
  const allowedToDelegate =
    _.includes(_.map(_.get(project, ['delegates'], []), 'id'), currentUser?.id) || // 위임 받은 사람
    _.includes(permissions, 'ALLOCATE_OR_DELEGATE') // 위임 배당 권한
  const detailPage = true
  const {
    onCloseCases,
    onDeleteCases,
    onReopenCases,
    onRestoreCases
  } = useOpenCloseDeleteCases(null, detailPage)

  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal,
    onConfirmModal
  } = useConfirmModal(
    onCloseCases,
    onDeleteCases,
    onReopenCases,
    onRestoreCases
  )
  const initMeta = {
    caseNumber: _.get(project, ['name']),
    caseTag: _.get(project, ['type', 'name']),
    content: _.get(project, ['content']),
    caseId: _.get(project, ['id']),
    status: _.get(project, ['status']),
    created: _.get(project, ['created']),
    createdBy: _.get(project, ['createdBy', 'name']),
    updated: _.get(project, ['updated']),
    updatedBy: _.get(project, ['updatedBy', 'name']),
    attachments: _.get(project, ['attachments']),
    users: _.get(project, ['users']),
    category: _.get(project, ['category']),
    userCount: _.get(project, ['userCount']),
    timesheetUpdated: _.get(project, ['timesheetUpdated'])
  }
  const {
    files,
    onDeleteFileByKey,
    dragDropProps
  } = useFormAttatchments(_.get(initMeta, ['attachments'], []) || [], loading)
  const onClickRecoveryCase = () => {
    onOpenModal([caseId], 'restore')
  }
  const {
    formItems,
    users
  } = useReadOnlyCaseForm(
    true,
    initMeta,
    initMeta.category,
    null,
    files,
    dragDropProps,
    loading,
    null,
    _.get(project, ['created']),
    _.get(project, ['totalMinutes']),
    _.get(project, ['timesheets'], [])?.length
  )
  const removedDeallocated = _.filter(users, { relation: 'ALLOCATED' })
  if (loading) return null
  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onConfirmModal}
        onCancel={onCloseModal}
      />
      <HeaderContentFooter
        header={
          <HeaderContainer>
            <RowContainer style={{ justifyContent: 'center', gap: '20px' }}>
              <HeaderTitle title={initMeta.status !== 'DELETED' ? `${korNameByCategory[initMeta.category]} 세부정보` : `삭제된 ${korNameByCategory[initMeta.category]} 세부정보`} />
              <UserList
                allocatedUsers={users}
                removedDeallocated={removedDeallocated}
                userCount={_.get(project, ['userCount'])}
                delegatedUsers={_.get(project, ['delegates'], [])}
                navigate={navigate}
                initMeta={initMeta}
                caseId={caseId}
                allowedToDelegate={allowedToDelegate}
              />
            </RowContainer>
            <Spacer />
            {initMeta.status === 'DELETED'
              ? (currentUser?.id === initMeta?.updatedBy?.id || currentUser?.group.name === '관리자' || currentUser?.group.name === '대표변호사'
                  ? (
                    <Button
                      onClick={(e) => { onClickRecoveryCase() }}
                      danger
                    >
                      복구
                    </Button>
                    )
                  : null)
              : null}
            {_.includes(permissions, 'UPDATE_PROJECT_DATA') && initMeta.status !== 'DELETED'
              ? (
                <HeaderGroup>
                  {/* 권한 추가> 타임시트 삭제, 사건 삭제 (프런트 버튼 표시 룰 수정) */}
                  {_.includes(permissions, 'UPDATE_PROJECT_DATA')
                    ? (
                      <Button type='primary' danger onClick={() => onOpenModal([caseId], 'delete')}>
                        <DeleteOutlined />
                        삭제하기
                      </Button>
                      )
                    : null}
                  <Button
                    type='primary'
                    icon={<EditOutlined />}
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => navigateToCaseForm(navigate, initMeta.category, 'editing', { ...initMeta, caseTag: _.get(project, ['type', 'id']) })}
                  >
                    수정하기
                  </Button>
                </HeaderGroup>
                )
              : null}
          </HeaderContainer>
        }
        content={
          <ContentContainer>
            <FormContainer>
              <AlignedForm
                style={{ width: '100%' }}
                items={formItems}
                initMeta={initMeta}
              />
            </FormContainer>
          </ContentContainer>
        }
        footer={(
          <AttatchmentContainer>
            <Attatchments
              onDeleteFileByKey={onDeleteFileByKey}
              dragDropProps={dragDropProps}
              readOnly
              onDownloadAll={(props) => {
                downloadFile(props)
                // message.destroy()
              }}
              files={files}
            />
          </AttatchmentContainer>
        )}
        footerHeight={FooterHeight}
      />
    </>
  )
}
