import styled from 'styled-components'
import { MinutesSetter } from '../../common/MinutesSetter'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Radio } from 'antd'
import { useState } from 'react'
import { Spacer } from '../../common/HeaderGroup'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const SaveCancelContainer = styled.div`
  display: flex;
  gap: 5px;
`

export const TimeDeltaAdder = ({ onAddTimeDelta, onCancel }) => {
  const [sign, setSign] = useState('plus') // plus, minus
  const [minutes, setMinutes] = useState(0)
  return (
    <Container>
      <Radio.Group
        className='signs'
        value={sign}
        options={[
          {
            value: 'plus',
            label: <PlusOutlined />
          },
          {
            value: 'minus',
            label: <MinusOutlined />
          }
        ]}
        onChange={e => setSign(e.target.value)}
        optionType='button'
        buttonStyle='solid'
        size='small'
      />
      <Spacer $flex={0.1} />
      <MinutesSetter minutes={minutes} onChange={v => setMinutes(v)} />
      <Spacer />
      <SaveCancelContainer>
        <Button onClick={onCancel}>취소</Button>
        <Button disabled={minutes === 0} onClick={() => onAddTimeDelta(sign === 'plus' ? minutes : -minutes)}>저장</Button>
      </SaveCancelContainer>
    </Container>
  )
}
