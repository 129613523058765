import _ from 'lodash'
import { timesheetTitles } from './config'
// timelist query
const getDD = (v) => {
  return v >= 10 ? v : `0${v}`
}

const generateTimeListData = (caseId, caseNumber, userId, userName) => {
  return (
    _.range(1, 10).map(v => {
      return {
        userId,
        key: v,
        title: timesheetTitles[Math.round(Math.random() * 3)],
        caseNumber,
        caseId,
        creator: userName,
        created: `2024-01-${getDD(v)} 12:12:00`,
        updated: `2024-01-${getDD(v)} 23:12:00`,
        minutes: Math.round(Math.random() * 1000)
      }
    })
  )
}

const caseListData = _.range(1, 11).flatMap(v => {
  const nUsers = Math.ceil(Math.random() * 10)
  let totalMinutes = 0
  let totalMinutesAfterEdit = 0

  return [
    {
      "key": `caseId_${2 * v - 1}`,
      "starred": true,
      "caseNumber": `2021가합${6 * v - 1} 손해배상`,
      "updated": `2024-01-0${v} 23:12:00`,
      users: _.range(nUsers).map(i => {
        if (i === 0) {
          totalMinutes = 0
          totalMinutesAfterEdit = 0
        }
        const minutes = Math.round(Math.random() * 1000)
        const minutesAfterEdit = minutes + Math.round(Math.random() * 50) - Math.round(Math.random() * 50)
        totalMinutes += minutes
        totalMinutesAfterEdit += minutesAfterEdit
        return {
          "key": `userId_${2 * v - 1}_${i}`,
          "updated": "2024-01-24 23:12:00",
          // "name": `사용자_${2 * v - 1}_${i}`,
          "name": `사용자`,
          "minutes": minutes,
          minutesAfterEdit,
          timesheets: generateTimeListData(`caseId_${2 * v - 1}`, `사건번호 ${2 * v - 1}`, `userId_${2 * v - 1}_${i}`, `사용자_${2 * v - 1}_${i}`)
        }
      }),
      totalMinutes,
      totalMinutesAfterEdit: totalMinutesAfterEdit
    },
    {
      "key": `caseId_${2 * v}`,
      "starred": false,
      "caseNumber": `사건번호 ${2 * v}`,
      "updated": `2024-01-0${v} 23:12:00`,
      users: _.range(nUsers).map(i => {
        if (i === 0) {
          totalMinutes = 0
          totalMinutesAfterEdit = 0
        }
        const minutes = Math.round(Math.random() * 1000)
        const minutesAfterEdit = minutes
        totalMinutes += minutes
        totalMinutesAfterEdit += minutesAfterEdit
        return {
          "key": `userId_${2 * v}_${i}`,
          "updated": "2024-01-24 23:12:00",
          "name": `사용자_${2 * v}_${i}`,
          "minutes": minutes,
          minutesAfterEdit,
          timesheets: generateTimeListData(`caseId_${2 * v}`, `사건번호 ${2 * v}`, `userId_${2 * v}_${i}`, `사용자_${2 * v}_${i}`)
        }
      }),
      totalMinutes,
      totalMinutesAfterEdit: totalMinutesAfterEdit
    }
  ]
})

const caseNumbers = caseListData.map(({ key, caseNumber }) => ({ key, value: caseNumber }))
const users = _.flatMap(caseListData, 'users').map(({ key, name }) => {
  return {
    key,
    value: name
  }
})
const colors = [
    'purple',
    'skyBlue',
    'green',
    'lightGreen'
]
const timeListData = _.range(1, 30).map(v => {
    const rand = Math.round(Math.random() * 3)
  return {
    key: `timesheet_1_${v}`,
    color: colors[rand],
    title: timesheetTitles[Math.round(Math.random() * 3)],
    caseNumber: caseNumbers[rand].value,
    caseId: caseNumbers[rand].key,
    creator: '김길동',
    created: `2024-01-${getDD(v)} 12:12:00`,
    updated: `2024-01-${getDD(v)} 23:12:00`,
    minutes: Math.round(Math.random() * 1000)
  }
}).concat(
  _.range(1, 30).map(v => {
    const rand = Math.round(Math.random() * 3)

    return {
      key: `timesheet_2_${v}`,
      color: colors[rand],
      title: timesheetTitles[Math.round(Math.random() * 3)],
      caseNumber: caseNumbers[rand].value,
      caseId: caseNumbers[rand].key,
      creator: '김길동',
      created: `2024-01-${getDD(v)} 12:12:00`,
      updated: `2024-01-${getDD(v)} 23:12:00`,
      minutes: Math.round(Math.random() * 1000)
    }
  })
)

// const caseNumbers = caseListData.map(({ key, caseNumber }) => ({ key, value: caseNumber }))
// const users = _.flatMap(caseListData, 'users').map(({ key, name }) => {
//   return {
//     key,
//     value: name
//   }
// })

// 통계
const createGraphData = (timeGroup) => {
  return _.range(timeGroup === 'weekly' ? 4 : 10).flatMap(v => {
    return _.range(1, 10).map(i => {
      return {
        nTimesheets: Math.round(Math.random() * 10),
        date: timeGroup === 'weekly' ? [`2024-01-${(v * 7) + 1}`, `2024-01-${(v * 7) + 8}`] : [`2024-01-${v + 1}`],
        creator: `사용자 ${i}`,
        caseNumber: `사건번호 ${i}`
      }
    })
  })
}

// 직원
const employeeData = [
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim1",
      "password": "0000",
      "email": "email1@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 744
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim2",
      "password": "0000",
      "email": "email2@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 497
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim3",
      "password": "0000",
      "email": "email3@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 739
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim4",
      "password": "0000",
      "email": "email4@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 191
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim5",
      "password": "0000",
      "email": "email5@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 638
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim6",
      "password": "0000",
      "email": "email6@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 379
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim7",
      "password": "0000",
      "email": "email7@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 833
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim8",
      "password": "0000",
      "email": "email8@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 932
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim9",
      "password": "0000",
      "email": "email9@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 232
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim10",
      "password": "0000",
      "email": "email10@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 85
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim11",
      "password": "0000",
      "email": "email11@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 367
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim12",
      "password": "0000",
      "email": "email12@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 953
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim13",
      "password": "0000",
      "email": "email13@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 609
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim14",
      "password": "0000",
      "email": "email14@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 587
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim15",
      "password": "0000",
      "email": "email15@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 213
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim16",
      "password": "0000",
      "email": "email16@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 56
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim17",
      "password": "0000",
      "email": "email17@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 423
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim18",
      "password": "0000",
      "email": "email18@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 399
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim19",
      "password": "0000",
      "email": "email19@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 669
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim20",
      "password": "0000",
      "email": "email20@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 391
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim21",
      "password": "0000",
      "email": "email21@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 106
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim22",
      "password": "0000",
      "email": "email22@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 482
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim23",
      "password": "0000",
      "email": "email23@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 852
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim24",
      "password": "0000",
      "email": "email24@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 179
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim25",
      "password": "0000",
      "email": "email25@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 698
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim26",
      "password": "0000",
      "email": "email26@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 211
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim27",
      "password": "0000",
      "email": "email27@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 225
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim28",
      "password": "0000",
      "email": "email28@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 874
  },
  {
      "key": "0",
      "creator": "김길동",
      "id": "kim29",
      "password": "0000",
      "email": "email29@mail.mail",
      "rank": "파트너",
      "department": "부서1",
      "authority": "배당",
      "minutes": 599
  }
]

const pushNotifications = _.range(10).map(v => {
  const date = new Date()
  date.setDate(date.getDate() - v)
  return (
    {
      text: `사건 ${v}에 추가되었습니다.`,
      date: date,
      reviewed: v > 3,
      type: ''
    }
  )
})

export {
    generateTimeListData,
    caseNumbers,
    users,
    caseListData,
    timeListData,
    createGraphData,
    employeeData,
    pushNotifications
}
