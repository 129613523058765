import React from 'react'
import styled from 'styled-components'
import COLORS from '../Theme/colors'

const ClosedTagContainer = styled.div`
  background-color:#6298b9;
  color: ${COLORS.white};
  font-size: 11px;
  border-radius: 6px;
  padding: 2px 7px;
  margin-left: 5px;
  cursor: pointer;
`

const ClosedTag = () => {
  return (
    <ClosedTagContainer>종결</ClosedTagContainer>
  )
}

export default ClosedTag
