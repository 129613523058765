import { DatePicker } from 'antd'
import _ from 'lodash'
import { convertToDayjs, parseDateObj } from '../../util'
import locale from 'antd/es/date-picker/locale/ko_KR'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import 'dayjs/locale/ko'

dayjs.extend(customParseFormat)
dayjs.locale('ko')

const dateEasySelectItems = [
  '전체',
  '오늘',
  '어제',
  '이번 주',
  '저번 주',
  '이번 달',
  '저번 달'
]

export const DateRangeFilter = ({ value = [], onCalendarChange = () => {}, ...props }) => {
  const presetByName = (name) => {
    if (name === '전체') {
      return null
    } else if (name === '오늘') {
      return _.map([dayjs(new Date().setDate(new Date().getDate())).startOf('day'), dayjs(new Date().setDate(new Date().getDate())).endOf('day')])
    } else if (name === '어제') {
      return _.map([dayjs(new Date().setDate(new Date().getDate() - 1)).startOf('day'), dayjs(new Date().setDate(new Date().getDate() - 1)).endOf('day')])
    } else if (name === '이번 주') {
      const curr = new Date()
      const first = curr.getDate() - curr.getDay() + 1
      const last = first + 6
      return _.map([dayjs(new Date().setDate(first)).startOf('day'), dayjs(new Date().setDate(last)).endOf('day')])
    } else if (name === '저번 주') {
      const curr = new Date(new Date().setDate(new Date().getDate() - 7))
      const first = curr.getDate() - curr.getDay() + 1
      const last = first + 6
      return _.map([dayjs(new Date(curr).setDate(first)).startOf('day'), dayjs(new Date(curr).setDate(last)).endOf('day')])
    } else if (name === '이번 달') {
      const lastDayOfThisMonth = new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
      return _.map([dayjs(new Date().setDate(1)).startOf('day'), dayjs(lastDayOfThisMonth).endOf('day')])
    } else if (name === '저번 달') {
      const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1))
      const thisMonth = new Date(new Date().setMonth(new Date().getMonth()))
      return _.map([dayjs(lastMonth.setDate(1)).startOf('day'), dayjs(thisMonth.setDate(0)).endOf('day')])
    }
  }
  const handleCalendarChange = (v) => onCalendarChange(_.map(v, _v => _v.format('YYYY-MM-DD HH:MM')))

  return (
    <DatePicker.RangePicker
      locale={locale}
      cellRender={v => {
        return <div className='ant-picker-cell-inner'>{parseDateObj(v, 'D')}</div>
      }}
      needConfirm={false}
      presets={dateEasySelectItems.map(label => ({ label, value: presetByName(label) }))}
      value={_.map(value, convertToDayjs)}
      style={{ maxWidth: 650, minWidth: 250 }}
      onChange={handleCalendarChange}
      {...props}
    />
  )
}
