import _ from 'lodash'

import { useNavigate } from 'react-router-dom'

import { Descriptions, Button, message } from 'antd'
import styled from 'styled-components'

import { getUserMeta } from '../../cacheAndNavigation/setting/cache'
import { resetPassword } from '../../auth/authFetchers'
import { useState } from 'react'
import { RowContainer } from '../../components/common/RowContainer'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const PasswordResetContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const handleResetPassword = async (userId) => {
    let ret
    try {
      ret = await resetPassword(userId)
    } catch (e) {
      console.error(e)
      message.error('알수 없는 오류: 재시도해보시기 바랍니다')
    }
    const statusCode = _.get(ret, ['status'])
    const retData = _.get(ret, ['data'], '')
    const retDataMessage = _.get(ret, ['data', 'message'], '')

    if ((statusCode === 200) && (retData === 'OK')) {
      setIsModalOpen(false)
      message.success('사용자 비밀번호가 초기화되었습니다')
    } else if ((statusCode === 200) && (retDataMessage === 'User not found')) {
      message.error('사용자 정보가 올바르지 않습니다')
    } else if (statusCode === 401) {
      message.error('허용되지 않는 접근입니다')
    } else if (statusCode === 500) {
      message.error('알수 없는 오류: 재시도해보시기 바랍니다')
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    navigate(-1)
  }

  const userMeta = getUserMeta()
  const userId = _.get(userMeta, ['id'], '')
  const userName = _.get(userMeta, ['name'], '')
  const userEmail = _.get(userMeta, ['email'], '')
  const userGroup = _.get(userMeta, ['group', 'name'], '')
  const userDep = _.get(userMeta, ['departments'], '')
  return (
    <Container>
      <Descriptions
        column={3}
        bordered
      >
        <Descriptions.Item span={2} label='이름'>{userName}</Descriptions.Item>
        <Descriptions.Item span={2} label='Email'>{userEmail}</Descriptions.Item>
        <Descriptions.Item span={2} label='구분'>{userGroup}</Descriptions.Item>
        <Descriptions.Item span={2} label='부서'>{userDep}</Descriptions.Item>
      </Descriptions>
      <RowContainer style={{ gap: '10px', marginTop: '20px' }}>
        <Button
          onClick={handleCancel}
        >
          뒤로가기
        </Button>
        <Button
          type='primary'
          danger
          onClick={() => handleResetPassword(userId)}
        >
          비밀번호 초기화
        </Button>
      </RowContainer>
    </Container>
  )
}

export default PasswordResetContainer
