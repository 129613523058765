import axios from 'axios'
import _ from 'lodash'
import { fastapiUrl } from '../config'

const serializationInstance = (token) => axios.create({
  baseURL: fastapiUrl,
  headers: {
    common: { atoken: token },
    'Content-type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
  }
})
const deserializationInstance = (token) => axios.create({
  baseURL: fastapiUrl,
  headers: {
    common: { atoken: token },
    'Content-Type': 'multipart/form-data'
  }
})
const deserializationInstance2 = (token) => axios.create({
  baseURL: fastapiUrl,
  headers: {
    common: { atoken: token }
  }
})
const generalInstance = (token) => axios.create({
  baseURL: fastapiUrl,
  headers: {
    common: { atoken: token }
  }
})
// 진행사건 엑셀 다운로드
const serializeCaseList = async (inputParams = {}) => {
  const params = new URLSearchParams()
  params.append('params', JSON.stringify(_.omit(inputParams, ['offset', 'limit'])))

  return await serializationInstance(sessionStorage.getItem('token')).get('/serialize_case_list', {
    params,
    responseType: 'arraybuffer'
  })
}
const serializeTimesheetList = async (inputParams = {}) => {
  const params = new URLSearchParams()
  params.append('params', JSON.stringify(_.omit(inputParams, ['offset', 'limit'])))

  return await serializationInstance(sessionStorage.getItem('token')).get('/serialize_timesheet_list', {
    params,
    responseType: 'arraybuffer'
  })
}
const serializeUserList = async (inputParams = {}) => {
  const params = new URLSearchParams()
  params.append('params', JSON.stringify(_.omit(inputParams, ['offset', 'limit'])))

  return await serializationInstance(sessionStorage.getItem('token')).get('/serialize_user_list', {
    params,
    responseType: 'arraybuffer'
  })
}

const serializeTimeGroupList = async (inputParams = {}) => {
  const params = new URLSearchParams()
  params.append('params', JSON.stringify(_.omit(inputParams, ['offset', 'limit'])))

  return await serializationInstance(sessionStorage.getItem('token')).get('/serialize_timegroup_list', {
    params,
    responseType: 'arraybuffer'
  })
}

const getTemplateProjectList = async (inputParams = {}) => {
  const params = new URLSearchParams()
  return await serializationInstance(sessionStorage.getItem('token')).get('/get_template_project_list', {
    params,
    responseType: 'arraybuffer'
  })
}
const getTemplateUserList = async (inputParams = {}) => {
  const params = new URLSearchParams()
  return await serializationInstance(sessionStorage.getItem('token')).get('/get_template_user_list', {
    params,
    responseType: 'arraybuffer'
  })
}

const deserializeCaseList = async (formData) => {
  return await deserializationInstance(sessionStorage.getItem('token')).put('/deserialize_project_list', formData)
}

const deserializeUserList = async (formData) => {
  return await deserializationInstance(sessionStorage.getItem('token')).put('/deserialize_user_list', formData)
}

const addProjectsViaDeserialization = async (formData) => {
  return await deserializationInstance2(sessionStorage.getItem('token')).put('/add_projects_via_deserialization', formData)
}

const addUsersViaDeserialization = async (formData) => {
  return await deserializationInstance2(sessionStorage.getItem('token')).put('/add_users_via_deserialization', formData)
}

const deserializeAttachments = async (formData) => {
  return await deserializationInstance(sessionStorage.getItem('token')).put('/attachments', formData)
}

const getAttatchmentById = async (id) => {
  return await deserializationInstance(sessionStorage.getItem('token')).get(`/attachments/${id}`)
}

const reportBug = async (title, content) => {
  const data = { title, content }
  const token = sessionStorage.getItem('token')

  const instance = generalInstance(token)

  return await instance.post('/report_bug', data)
}
// 업로드한 파일 다운로드
const downloadUploadedFile = async (inputParams = {}) => {
  const params = new URLSearchParams()
  return await serializationInstance(sessionStorage.getItem('token')).get('/attachments', {
    params,
    responseType: 'arraybuffer'
  })
}

export {
  serializeCaseList,
  serializeTimesheetList,
  serializeUserList,
  getTemplateProjectList,
  getTemplateUserList,
  deserializeCaseList,
  deserializeUserList,
  getAttatchmentById,
  deserializeAttachments,
  addProjectsViaDeserialization,
  addUsersViaDeserialization,
  reportBug,
  serializeTimeGroupList,
  downloadUploadedFile
}
