import { viewModeOptions } from '../../config'
import { Segmented } from 'antd'
import _ from 'lodash'

export const ViewMode = ({ viewMode, onChangeViewMode, hiddenKeys = [] }) => {
  return (
    <Segmented
      value={viewMode}
      options={_.filter(viewModeOptions, ({ value }) => !_.includes(hiddenKeys, value))}
      onChange={e => onChangeViewMode(e)}
    />
  )
}
