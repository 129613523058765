import _ from 'lodash'
import { Table, Typography } from 'antd'
import styled from 'styled-components'
import { departmentColumns, unAppointedDepartmentId } from '../../config'

const Container = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  margin-right: 10px;
  padding: 0 10px 10px 10px;
`
const FilterSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 350px;
`

export const FilterSelect = ({ departments, selectedDepartmentIds = [], onChangeSelection, scroll = {} }) => {
  const onSelectChange = (newSelectedRowKeys) => {
    const newKeys = _.difference(newSelectedRowKeys, selectedDepartmentIds)
    if (_.includes(newKeys, unAppointedDepartmentId)) {
      // `부서미배치` 가 선택되면 다른 부서는 모두 선택해제
      onChangeSelection([unAppointedDepartmentId])
    } else if (!_.includes(newKeys, unAppointedDepartmentId)) {
      // 다른 부서가 선택되면 `부서미배치`를 선택해제
      const _newSelectedKeys = _.difference(newSelectedRowKeys, [unAppointedDepartmentId])
      onChangeSelection(_newSelectedKeys)
    }
  }
  const rowSelection = {
    selectedRowKeys: selectedDepartmentIds,
    onChange: onSelectChange
  }
  return (
    <FilterSelectContainer>
      <Table
        rowSelection={rowSelection}
        dataSource={departments}
        columns={departmentColumns}
        pagination={false}
        scroll={scroll}
      />
    </FilterSelectContainer>
  )
}
export const DepartmentFilter = ({ // 필터 목록 출력
  scroll,
  departments: _deps,
  employees,
  onChangeEmployeesSelection,
  selectedDepartmentIds,
  selectedEmployees,
  onChangeSelection
}) => {
  const departments = _.concat(
    [{ name: '미배치', key: unAppointedDepartmentId }],
    _deps
  )
  return (
    <Container>
      <Typography.Title level={4} style={{ padding: '0 10px' }}>필터</Typography.Title>
      <FilterSelect
        scroll={scroll}
        departments={departments}
        employees={employees}
        selectedDepartmentIds={selectedDepartmentIds}
        selectedEmployees={selectedEmployees}
        onChangeSelection={onChangeSelection}
        onChangeEmployeesSelection={onChangeEmployeesSelection}
      />
    </Container>
  )
}
