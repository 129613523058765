import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import _ from 'lodash'
import { Spin } from 'antd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const FolderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 75px;
`
const LIMIT = 20
const _VirtualList = ({
  scrollId,
  refetch = (offset, limit) => {},
  loading,
  dataParser,
  renderItem,
  onInfiniteScrollUpdateMeta = () => {},
  cases,
  totalCount
}, ref) => {
  const [meta, setMeta] = useState({
    offset: !cases ? 0 : LIMIT,
    items: cases || [],
    initialized: !cases,
    total: totalCount
  })
  const {
    offset,
    total,
    items
  } = meta
  useImperativeHandle(ref, () => ({
    removeItemById: (id) => setMeta(meta => ({
      ...meta,
      offset: meta.offset - 1,
      total: meta.total - 1,
      items: _.filter(meta.items, v => v.id !== id)
    }))
  }))

  const prevItems = useRef(meta.items)
  useEffect(() => {
    if (!_.isEqual(prevItems.current, meta.items)) {
      onInfiniteScrollUpdateMeta(meta.items)
      prevItems.current = meta.items
    }
  }, [meta.items])

  // useEffect(() => {
  //   if (!loading && !meta.initialized) {
  //     refetch(0, LIMIT).then(({ data }) => {
  //       setMeta(meta => ({
  //         ...meta,
  //         offset: meta.offset + LIMIT,
  //         items: meta.items.concat(dataParser(data)),
  //         initialized: true,
  //         total: _.get(data, ['GetProjectList', 'totalCount'], 0)
  //       }))
  //     })
  //   }
  // }, [loading])

  const fetchMoreData = () => {
    refetch(offset, LIMIT).then(({ data }) => {
      setMeta(meta => {
        return {
          ...meta,
          offset: meta.offset + LIMIT,
          items: meta.items.concat(dataParser(data))
        }
      })
    })
  }

  if (!meta.initialized && loading) return <Spin />
  return (
    <Container>
      <InfiniteScroll
        style={{ overflow: 'hidden' }}
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={offset < total}
        scrollableTarget={scrollId}
        loader={<Spin tip='Loading'> </Spin>}
      >
        <FolderContainer>
          {items.map(renderItem)}
        </FolderContainer>
      </InfiniteScroll>
    </Container>
  )
}
export const VirtualList = forwardRef(_VirtualList)
