import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import { useProjectNameTemplateList } from '../../hooks/apollo/useProjectNameTemplateList'
import { HeaderContentFooter } from '../../components/common/HeaderContentFooter'
import TimesheetTitleTemplateAdder from '../../components/settings/timesheetTitleTemplate/TimesheetTitleTemplateAdder'
import { useTimesheetTitleTemplateAdder } from '../../hooks/settings/useTimesheetTitleTemplateAdder'
import { HeaderContainer, HeaderTitle, Spacer } from '../../components/common/HeaderGroup'

const Container = styled.div`
  width: 100%;
`
const ManageTimesheetTitleTemplateContainer = () => {
  const { projectNamecategories, loading } = useProjectNameTemplateList()
  const {
    onAddTimesheetTitleTemplate,
    onEditTimesheetTitleTemplate,
    onRemoveTimesheetTitleTemplate
  } = useTimesheetTitleTemplateAdder()

  if (loading) {
    return <Container />
  }

  return (
    <HeaderContentFooter
      header={(
        <HeaderContainer>
          <HeaderTitle title='타임시트 제목 관리' />
          <Spacer $flex={5} />
        </HeaderContainer>
      )}
      content={(
        <Container>
          <TimesheetTitleTemplateAdder
            initDataSource={_.map(projectNamecategories, ({ ...v }) => ({ key: v.id, ...v }))}
            onAddTimesheetTitleTemplate={onAddTimesheetTitleTemplate}
            onEditTimesheetTitleTemplate={onEditTimesheetTitleTemplate}
            onRemoveTimesheetTitleTemplate={onRemoveTimesheetTitleTemplate}
          />
        </Container>
      )}
    />
  )
}

export default ManageTimesheetTitleTemplateContainer
