import { Typography } from 'antd'
import styled from 'styled-components'
import COLORS from './Theme/colors'

const Container = styled.div`
  display: flex;
  gap: 0px;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: ${COLORS.black_88};
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position: relative;
  width: 100%;
  min-width: 0;
  background-color: ${COLORS.white};
  background-image: none;
  border-style: solid;  
  border-color: ${COLORS.gray_4};
  border-left-width: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
`

export const CustomReadOnlyInput = ({ value }) => {
  return (
    <Container>
      <Typography.Text>{value}</Typography.Text>
    </Container>
  )
}
