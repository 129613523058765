import { Typography } from 'antd'
import styled from 'styled-components'
import { useHoverEvent } from '../../../hooks/common/useHoverEvent'
import { ArrowRightOutlined } from '@ant-design/icons'
import COLORS from '../../common/Theme/colors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  width: 250px;
  height: 180px;
  background: ${props => props.$color};
  justify-content: space-around;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 2px ${props => props.$shadowColor};
  transition: transform .2s; /* Animation */
  cursor: pointer;
  &:hover{
    transform: scale(1.03);
  }
`
const StyledButton = styled.div`
  display: flex;
  white-space: nowrap;
  background-color: ${COLORS.white};
  height: 20px;
  padding: 20px 9px 20px 20px;
  border-radius: 30px;
  align-items: center;
  gap: 25px;
  border: none;
  box-shadow: 2px 2px 15px 1px ${props => props.$shadowColor};
`
const ArrowContainer = styled.div`
  display: flex;
  background-color: ${props => props.$color};
  padding: 8px;
  border-radius: 30px;
`
const ButtonTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonShowingCreateLinkOnHover = ({ title, subtitle, color, shadowColor, onClickCreateLink }) => {
  const { elemId } = useHoverEvent()
  return (
    <Container id={elemId} $color={color} $shadowColor={shadowColor} onClick={onClickCreateLink}>
      <ButtonTitle>
        <Typography.Title level={4}>{title}</Typography.Title>
        <div>{subtitle}</div>
      </ButtonTitle>
      <StyledButton className='button' $shadowColor={shadowColor}>
        <div>생성하기</div>
        <ArrowContainer $color={color}>
          <ArrowRightOutlined style={{ color: `${COLORS.white}` }} />
        </ArrowContainer>
      </StyledButton>
    </Container>
  )
}
