import { FilterOutlined, GatewayOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import COLORS from '../common/Theme/colors'

const BoxNameContainer = styled.div`
  position: relative;
  left: ${props => props.$left || undefined};
  top: 13px;
  background-color: ${COLORS.white};
  width: 54px;
  color: ${COLORS.gray_3};
  padding: 2px 4px;
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  height: 30px;
`
const BoxText = styled.div`
  font-size: 15px;
`

export const FilterGroupBoxName = ({ title, children, left }) => {
  return (
    <BoxNameContainer $left={left}>
      {title === '필터' ? <FilterOutlined /> : <GatewayOutlined />}
      <BoxText>{title}</BoxText>
    </BoxNameContainer>
  )
}
