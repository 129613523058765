import { useMutation } from '@apollo/client'
import { addRanksToUser, removeRanksFromUser } from '../../../apollo/mutations'
import { useOutletContext } from 'react-router-dom'
import _ from 'lodash'
import { MESSAGES } from '../../../config'

export const useUserAddDeleteRank = (onFinish = () => {}, onError = () => {}) => {
  const { messageApi } = useOutletContext()
  const [addRanksToUserFn] = useMutation(addRanksToUser)
  const [removeRanksFromUserFn] = useMutation(removeRanksFromUser)

  const onAddRanksToUser = async (
    userId,
    rankIds
  ) => {
    return addRanksToUserFn({
      variables: {
        userId,
        rankIds
      }
    }).then(v => {
      if (_.get(v, ['data', 'AddRanksToUser', 'ok'])) onFinish()
      else {
        messageApi.open(MESSAGES.rankCreationError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.rankCreationError)
      onError()
    })
  }
  const onRemoveRanksFromUser = async (
    userId,
    rankIds = []
  ) => {
    return removeRanksFromUserFn({
      variables: {
        userId,
        rankIds
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveRanksFromUser', 'ok'], false)) onFinish()
      else {
        messageApi.open(MESSAGES.rankDeleteError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.rankDeleteError)
      onError()
    })
  }
  return {
    onAddRanksToUser,
    onRemoveRanksFromUser
  }
}
