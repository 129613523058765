import { useEffect, useState } from 'react'

import { useCustomTable } from '../../common/useCustomTable'
import { useManageEmployeeTable } from '../table/useManageEmployeeTable'
import { unAppointedDepartmentId } from '../../../config'

import { useQuery } from '@apollo/client'
import { getUserList } from '../../../apollo/caseListQueries'
import _ from 'lodash'

export const useUserAllocator = (usersToAllocate, initAllocationLoading, departmentIds = [], groupIds = [], searchQuery, state) => {
  const {
    employeeTableColumns
  } = useManageEmployeeTable()
  const parseData = (data, isPreAllocation) => _.map(data,
    v => ({
      ...v,
      key: v.id,
      type: _.get(v, ['group', 'name']),
      disabled: isPreAllocation && _.includes(meta.allocatedUserIds, v.id),
      departments: _.map(v?.departments || [], 'name')?.join(', ')
    }))

  const [meta, setMeta] = useState({
    allocatedUserIds: [],
    initAllocatedUserIds: []
  })
  useEffect(() => {
    if (!initAllocationLoading) {
      setMeta(meta => ({
        ...meta,
        initAllocatedUserIds: _.map(usersToAllocate, 'id'),
        allocatedUserIds: _.map(usersToAllocate, 'id')
      }))
    }
  }, [initAllocationLoading])
  // 선택된 사용자
  const { data, loading: preAllocationLoading } = useQuery(getUserList, {
    variables: {
      sortBy: {
        ascending: true
      },
      filter: {
        notAppointed: _.includes(departmentIds, unAppointedDepartmentId),
        departmentIds,
        includeSelf: true,
        ...searchQuery ? { searchQuery } : {}
      },
      limit: null
    },
    fetchPolicy: 'no-cache'
  })
  // 모든 사용자
  const { data: _data, loading: postAllocationLoading } = useQuery(getUserList, {
    variables: {
      sortBy: {
        ascending: true
      },
      filter: {
        includeSelf: true
      },
      limit: null
    },
    fetchPolicy: 'no-cache'
  })
  const filteredUsers = _.get(data, ['GetUserList', 'data'], [])
  const allUsers = _.get(_data, ['GetUserList', 'data'], [])

  const preAllocationTableProps = useCustomTable()
  const {
    selectedRowKeys: preAllocationSelectedKeys
  } = preAllocationTableProps

  const preAllocationData = parseData(filteredUsers, true)
  const postAllocationTableProps = useCustomTable()
  const {
    selectedRowKeys: postAllocationSelectedKeys
  } = postAllocationTableProps
  const postAllocationData = _.filter(parseData(allUsers), v => {
    return _.includes(meta.allocatedUserIds, v.id)
  })

  const preEmployeeColumns = [
    {
      title: '이름',
      // sort: true,
      dataIndex: 'name',
      key: 'USER_NAME',
      width: 70
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      width: 180
    },
    {
      title: '부서',
      dataIndex: 'departments',
      key: 'departments',
      width: 80
    }
  ]

  const postColumns = []

  const onAllocateUsers = () => {
    setMeta(meta => ({
      ...meta,
      allocatedUserIds: _.uniq(meta.allocatedUserIds.concat(preAllocationSelectedKeys))
    }))
  }
  const onDisAllocateUser = (userId) => {
    setMeta(meta => ({
      ...meta,
      allocatedUserIds: _.filter(meta.allocatedUserIds, v => v !== userId)
    }))
  }
  const onDisAllocateUsers = () => {
    setMeta(meta => ({
      ...meta,
      allocatedUserIds: _.filter(meta.allocatedUserIds, v => !_.includes(postAllocationSelectedKeys, v))
    }))
  }
  const userIdsToAllocate = _.differenceWith(meta.allocatedUserIds, meta.initAllocatedUserIds)
  const userIdsToDisallocate = _.differenceWith(meta.initAllocatedUserIds, meta.allocatedUserIds)
  return {
    userIdsToAllocate,
    userIdsToDisallocate,
    preAllocationTableProps: {
      ...preAllocationTableProps,
      data: preAllocationData,
      pageLimitHidden: true,
      tableActionHidden: true,
      columns: state ? preEmployeeColumns : employeeTableColumns,
      style: {
        flex: 1
      },
      scroll: {
        y: 400
      },
      loading: preAllocationLoading,
      total: preAllocationData.length,
      unit: '명',
      pagination: false
    },
    postAllocationTableProps: {
      ...postAllocationTableProps,
      data: postAllocationData,
      columns: postColumns,
      style: {
        flex: 1
      },
      unit: '명',
      total: postAllocationData.length,
      pageLimitHidden: true,
      tableActionHidden: true,
      pagination: false,
      loading: postAllocationLoading || initAllocationLoading
    },
    postAllocationUsers: postAllocationData,
    onAllocateUsers,
    onDisAllocateUser,
    onDisAllocateUsers
  }
}
