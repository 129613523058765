import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../config'
import { CustomSearch } from '../../common/CustomSearch'
import { useTossCaseUserData } from './useTossCaseUserData'
import { EmployeeListForTossCase } from './EmployeeListForTossCase'
import { useDepartmentList } from '../../../hooks/apollo/useDepartmentList'
import { useAllocateUsersToCases } from '../../../hooks/apollo/mutations/useAllocateUsersToCases'
import styled from 'styled-components'
import COLORS from '../../common/Theme/colors'
import { Button, Modal, Popover } from 'antd'
import _ from 'lodash'

const StyledText = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 20px;
`
const SectionWrapper = styled.div`
  padding: 20px;
  border: 1px solid ${COLORS.gray_1};
  margin-top: 10px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`

export const TossCaseModal = ({
  isTossCaseModalOpen,
  handleCaseTossModalCancel,
  handleCaseTossModalOk,
  selectedCaseIdRowKeys
}) => {
  const { messageApi } = useOutletContext()
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]) // 선택된 부서 id
  const [delegateDisabled, setDelegateDisabled] = useState(true) // 위임 버튼
  const [allocateDisabled, setAllocateDisabled] = useState(true) // 배당 버튼
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 선택된 배열
  const [selectedEmployee, setSelectdEmployee] = useState([])
  const selectedEmployeeIds = _.map(selectedEmployee, emloyee => _.get(emloyee, ['id'])) // 선택된 사건 목록

  useEffect(() => {
    const allAreLeaders = _.every(selectedEmployee, row => row.id === row.leaderId)

    if (selectedRowKeys.length > 0) {
      if (allAreLeaders) {
        setDelegateDisabled(false)
        setAllocateDisabled(true)
      } else {
        setDelegateDisabled(true)
        setAllocateDisabled(false)
      }
    } else {
      setDelegateDisabled(true)
      setAllocateDisabled(true)
    }
  })
  const {
    preAllocationTableProps
  } = useTossCaseUserData(
    selectedDepartmentIds,
    searchQuery,
    !isTossCaseModalOpen // 모달이 열릴때 쿼리 날아가게 함
  )

  const onDepartmentListComplete = (departments) => {
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }
  const {
    departments
  } = useDepartmentList(null, onDepartmentListComplete, !isTossCaseModalOpen)

  const {
    allocateUsersToMultipleCases,
    delegateUsersToMultipleProjects
  } = useAllocateUsersToCases()
  const onSelectChange = (newSelectedRowKeys, selectedRows) => { // 직원 선택
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectdEmployee(selectedRows)
  }
  const handleOk = async (key) => {
    if (key === '위임') {
      return delegateUsersToMultipleProjects(selectedCaseIdRowKeys, selectedEmployeeIds).then(v => {
        if (!_.get(v, ['data', 'DelegateUsersToProjects', 'ok'])) {
          messageApi.open(MESSAGES.allocateUsersError)
        } else {
          handleCaseTossModalOk()
        }
      })
    } else if (key === '배당') {
      return allocateUsersToMultipleCases(selectedCaseIdRowKeys, selectedEmployeeIds).then(v => {
        if (!_.get(v, ['data', 'AllocateUsersToProjects', 'ok'])) {
          messageApi.open(MESSAGES.delegateUsersError)
        } else {
          handleCaseTossModalOk()
        }
      })
    }
  }
  return (
    <Modal
      title='사건 일괄 배정'
      open={isTossCaseModalOpen}
      onOk={handleCaseTossModalOk}
      onCancel={handleCaseTossModalCancel}
      width={1340}
      footer={null}
    >
      <SectionWrapper>
        <StyledText>
          위임/배당 할 직원 선택:
        </StyledText>
        <CustomSearch onSearch={e => setSearchQuery(e)} placeholder='이름을 입력해주세요.' />
        <EmployeeListForTossCase
          filterProps={{
            scroll: { y: 380 }, // 부서 목록 높이
            selectedDepartmentIds,
            departments: _.map(departments, department => ({ name: _.get(department, ['name']), key: _.get(department, ['id']) })),
            onChangeSelection: (rowKeys) => setSelectedDepartmentIds(rowKeys)
          }}
          preAllocationTableProps={preAllocationTableProps}
          onSelectChange={onSelectChange}
          selectedRowKeys={selectedRowKeys}
        />
      </SectionWrapper>
      <ButtonWrapper>
        {delegateDisabled
          ? (
            <Popover
              content='선택된 직원들이 부서장일 경우에만 위임이 가능합니다.'
            >
              <Button disabled={delegateDisabled} onClick={() => handleOk('위임')}>위임하기</Button>
            </Popover>)
          : (
            <Button disabled={delegateDisabled} onClick={() => handleOk('위임')}>위임하기</Button>)}
        <Button disabled={allocateDisabled} onClick={() => handleOk('배당')}>배당하기</Button>
      </ButtonWrapper>
    </Modal>
  )
}
