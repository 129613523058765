import _ from 'lodash'
import { useQuery, useMutation } from '@apollo/client'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { getCurrentUser } from '../../../apollo/queries'
import { createCase, updateCase, delegateUsersToProject } from '../../../apollo/mutations'
import { MESSAGES } from '../../../config'
import { navigateToCaseDetail } from '../../../cacheAndNavigation/caseDetail/navigation'

const categoryByTypeOfContainer = {
  case: 'CASE',
  consultation: 'CONSULT',
  etc: 'ETC'
}
export const useAddingEtc = (attachments, caseNumber, content, onFetchAndNav, typeOfContainer, caseFormStatus, caseId, caseStatus) => {
  const navigate = useNavigate()
  const { data: currentUserData } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only'
  })
  const currentUser = _.get(currentUserData, ['CurrentUser'])
  const { messageApi } = useOutletContext()
  const [delegateUsersToProjectMutFn] = useMutation(delegateUsersToProject)
  const [createCasesMutFn] = useMutation(
    createCase,
    {
      onCompleted (data) {
        const projId = _.get(data, ['AddProject', 'data', 'id'], null)
        if (_.isNull(projId)) {
          console.error('Failed to Allocate Project to creator: ID issue')
          return
        }

        if (_.includes(currentUser.permissions, 'ALLOCATE_OR_DELEGATE')) {
          delegateUsersToProjectMutFn({
            variables: {
              projectId: projId,
              userIds: [currentUser.id]
            }
          })
        }
      }
    }
  )
  const [updateCaseMutFn] = useMutation(updateCase)

  const onSave = () => {
    if (caseNumber && content) {
      onFetchAndNav(
        () => {
          if (caseFormStatus === 'creating') {
            return createCasesMutFn({
              variables: {
                project:
                  {
                    category: categoryByTypeOfContainer[typeOfContainer],
                    name: caseNumber,
                    content,
                    status: 'OPEN'
                  },
                attachments
              }
            })
          } else if (caseFormStatus === 'editing') {
            return updateCaseMutFn({
              variables: {
                updateProjectId: caseId,
                input: {
                  name: caseNumber,
                  content,
                  status: caseStatus
                },
                attachments
              }
            })
          } else {
            console.log('formstatus unknown')
          }
        },
        (v) => {
          if (!_.get(v, ['data', caseFormStatus === 'creating' ? 'AddProject' : 'UpdateProject', 'ok'], false)) {
            messageApi.open(MESSAGES.caseCreationError)
            return
          }
          if (_.get(v, ['data', 'AddProject', 'data', 'id'], false)) navigateToCaseDetail(navigate, { caseId: _.get(v, ['data', 'AddProject', 'data', 'id'], false) }, true)
          else if (caseId) navigateToCaseDetail(navigate, { caseId }, true)
          else messageApi.open(MESSAGES[`${typeOfContainer}FormWarning`])
        },
        (e) => console.log(e)
      )
    } else {
      messageApi.open(MESSAGES[`${typeOfContainer}FormWarning`])
    }
  }

  return {
    onSave,
    onCancel: () => navigate(-1, { replace: true })
  }
}
