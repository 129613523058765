import { Button, Tag } from 'antd'
import styled from 'styled-components'
import COLORS from './Theme/colors'

const StyledTag = styled(Tag)`
  display: flex;
  padding: 5px 11px;
  border: 1px solid ${COLORS.primaryColor};
  gap: 5px;
  color: ${COLORS.primaryColor};
  font-size: 13px;
`
export const BecomesTagOnSelect = ({ tagged, onSelect, onClose, name, children }) => {
  return (
    <>
      {tagged
        ? <StyledTag closable onClose={onClose}>{name}</StyledTag>
        : (
          <Button onClick={onSelect}>
            {children}
          </Button>
          )}
    </>
  )
}
