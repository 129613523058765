import * as React from 'react'
import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'

import HomeContainer from './containers/HomeContainer'
import MainContainer from './containers/MainContainer'
import SearchContainer from './containers/SearchContainer'
import StatisticContainer from './containers/StatisticContainer'
import TimesheetContainer from './containers/TimesheetContainer'
import SettingContainer from './containers/setting/SettingContainer'
import TimesheetFormContainer from './containers/TimesheetFormContainer'
import TimesheetEntryContainer from './containers/TimesheetEntryContainer'
import AddEmployeeContainer from './containers/setting/AddEmployeeContainer'
import TimesheetDetailContainer from './containers/TimesheetDetailContainer'
import LoggingOutIndicatingContainer from './containers/LoggingOutIndicatingContainer'
import AddDepartmentContainer from './containers/setting/department/AddDepartmentContainer'
import SettingEmployeeRankContainer from './containers/setting/SettingEmployeeRankContainer'
import EditDepartmentContainer from './containers/setting/department/EditDepartmentContainer'
import AddProjectTypeContainer from './containers/setting/projectType/AddProjectTypeContainer'
import EditProjectTypeContainer from './containers/setting/projectType/EditProjectTypeContainer'
import SettingEmployeeDelegateContainer from './containers/setting/SettingEmployeeDelegateContainer'
import SettingEmployeeDepartmentContainer from './containers/setting/SettingEmployeeDepartmentContainer'
import SettingEmployeeResetPasswordContainer from './containers/setting/SettingEmployeeResetPasswordContainer'

import './index.css'

import { TrashContainer } from './containers/TrashContainer'
import { CreateEtcContainer } from './containers/manageCase/CreateEtcContainer'
import { CaseDetailContainer } from './containers/manageCase/CaseDetailContainer'
import { CreateCaseContainer } from './containers/manageCase/CreateCaseContainer'
import { EmployeeDetailContainer } from './containers/employee/EmployeeDetailContainer'
import { UserAllocationContainer } from './containers/manageCase/UserAllocationContainer'
import { ManageCaseTableContainer } from './containers/manageCase/ManageCaseTableContainer'
import { ManageCaseCreateNavContainer } from './containers/manageCase/ManageCaseCreateNavContainer'

import { ConfigProvider } from 'antd'
import COLORS from './components/common/Theme/colors'

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainContainer />,
    children: [
      {
        path: '/',
        element: <HomeContainer />
      },
      {
        path: '/search',
        element: <SearchContainer />
      },
      {
        path: '/timesheet/:caseStatus',
        element: <TimesheetContainer showCaseTable />
      },
      {
        path: '/timesheetDetail/:timesheetId',
        element: <TimesheetDetailContainer />
      },
      {
        path: '/timesheetEntry/:timesheetId/:timesheetEntryId',
        element: <TimesheetEntryContainer />
      },
      {
        path: '/timesheetForm',
        element: <TimesheetFormContainer />
      },
      {
        path: '/stat',
        element: <StatisticContainer />
      },
      {
        path: '/setting/addEmployee',
        element: <AddEmployeeContainer />
      },
      {
        path: '/setting/addDepartment',
        element: <AddDepartmentContainer />
      },
      {
        path: '/setting/addProjectType',
        element: <AddProjectTypeContainer />
      },
      {
        path: '/setting/editDepartment/:departmentId',
        element: <EditDepartmentContainer />
      },
      {
        path: '/setting/:settingCategory',
        element: <SettingContainer />
      },
      {
        path: '/setting/:settingCategory/employeeDelegate/:userId',
        element: <SettingEmployeeDelegateContainer />
      },
      {
        path: '/setting/:settingCategory/resetPassword/:userId',
        element: <SettingEmployeeResetPasswordContainer />
      },
      {
        path: '/setting/:settingCategory/assignDepartment/:userId',
        element: <SettingEmployeeDepartmentContainer />
      },
      {
        path: '/setting/:settingCategory/assignRank/:userId',
        element: <SettingEmployeeRankContainer />
      },
      {
        path: '/setting/editProjectType/:projectTypeId',
        element: <EditProjectTypeContainer />
      },
      {
        path: '/manageCase/table',
        element: <ManageCaseTableContainer />
      },
      {
        path: '/manageCase/createNav',
        element: <ManageCaseCreateNavContainer />
      },
      {
        path: '/manageCase/createCase',
        element: <CreateCaseContainer />
      },
      {
        path: '/caseDetail/:caseId',
        element: <CaseDetailContainer />
      },
      {
        path: '/employeeDetail/:employeeId',
        element: <EmployeeDetailContainer />
      },
      {
        path: '/manageCase/createConsultation',
        element: <CreateCaseContainer type='consultation' />
      },
      {
        path: '/manageCase/createEtc',
        element: <CreateEtcContainer />
      },
      {
        path: '/manageCase/userAllocation/:caseId',
        element: <UserAllocationContainer />
      },
      {
        path: '/trash',
        element: <TrashContainer />
      },
      {
        path: '/logout',
        element: <LoggingOutIndicatingContainer />
      }
    ]
  }
])
const config = {
  token: {
    colorPrimary: `${COLORS.primaryColor}`
  }
}

createRoot(document.getElementById('root')).render(
  <>
    <ConfigProvider theme={config}>
      <RouterProvider router={router} />
    </ConfigProvider>
  </>
)
