import _ from 'lodash'
import { useQuery } from '@apollo/client'

import { getCategories } from '../../apollo/queries'

export const useCategories = (skip, searchQuery) => {
  const { data: _categories, loading } = useQuery(getCategories, {
    fetchPolicy: 'network-only',
    variables: {
      ...searchQuery
        ? {
            filter: {
              searchQuery
            }
          }
        : {}
    },
    skip
  })
  const categories = _.map(_.get(_categories, ['GetProjectTypeList'], []))
  return {
    categories,
    loading
  }
}
